sup {
  color: red !important;
}

@media (min-width: 767px) {
  .position-absolute-right {
    right: 0;
    position: absolute;
  }
}

.rounded-main {
  border-radius: 13px;
}

blockquote {
  font-weight: bold;
  color: #acd52a;
  font-size: 23px;
  line-height: 26px;
  margin-bottom: 10px;
  letter-spacing: 0.01em;

  p {
    &:first-child {
      font-weight: bold;
      color: #acd52a;
      font-size: 23px;
      line-height: 26px;
      margin-bottom: 10px;
      letter-spacing: 0.01em;
    }

    &:last-of-type {
      color: #00587b;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.h-auto {
  height: auto;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.text-facebook {
  color: #3b5998 !important;
}
.text-twitter {
  color: #00aced !important;
}
.text-linkedin {
  color: #007bb6 !important;
}
.text-googleplus {
  color: #dd4b39 !important;
}
.text-instagram  {
  color: #517fa4 !important;
}
.text-pinterest  {
  color: #cb2027 !important;
}


.navbar-header {
  width: 100%;
}

.btn-xs {
  font-size: 15px;
  padding: 3px 10px;
}

.btn-group-xs > .btn {
  @extend .btn-xs;
}

.btn-group-styled {
  height: 26px;

  .btn {
    &:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    color: #333333;
    font-size: 11px;
    background: #ffffff;
    border: 2px solid #ffffff;
    box-shadow: none !important;
    padding: 3px 8px !important;

    &.active {
      color: #ffffff;
      background: #434343;
    }
  }
}

.input-group-addon {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 400;
  line-height: 23px;
  padding: 6px 12px;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid #d9d9d9;
  border-right: 0;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.block {
  display: block;
}

.absolute {
  position: absolute;
}

.inline {
  display: inline;
}

.no-radius {
  border-radius: 0;
}

.dl-spaced {
  dd {
    margin-bottom: 20px;
  }
}

.dl-horizontal-inline dt {
  float: left;
  width: 160px;
  clear: left;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.testimonial {
  color: $white;

  .testimonial-content {
    width: 86%;
    float: right;
    margin-top: -58px;
    font-style: italic;
    font-weight: normal;
    border-radius: 4px;
    padding: 31px 20px 15px 41px;
    background: rgb(255, 155, 74);

    p {
      margin-bottom: 0;
    }

    .testimonial-user-name {
      font-style: normal;
      font-weight: bold;
    }
  }

  @media (max-width: 766px) {
    .testimonial-content {
      margin-top: -42px;
      margin-bottom: -3px;
    }
  }

  .testimonial-user-image {
    width: 81px;
    height: 81px;
    z-index: 100;
    position: relative;
    border-radius: 50%;

    @media (max-width: 766px) {
      width: 60px;
      height: 60px;
      min-height: 60px;
    }
  }

  &.testimonial-width {
    .testimonial-content {
      width: 94%;

      .stars-col {
        font-style: normal;
        padding-bottom: 12px;
        border-left: 1px solid $white;

        .stars-review {
          font-size: 24px;
        }
      }

      @media (max-width: 766px) {
        margin-top: -46px;

        .stars-col {
          border: none;
          margin-top: 20px;
        }
      }
    }
  }
}

.stars {
  > .fa-star {
    color: #FFFFFF;
    background: #DCDCDC;
    border-radius: 50%;
    //padding: 5px;
    font-size: 11px;
    line-height: 1;
    margin: 0;
    //padding: 4px 4px;
    //padding-top: 3px;
    //padding-right: 3px;

    padding: 3px 3px 4px 3px;
  }

  @for $i from 1 through 5 {
    &[data-score="#{$i}"] {
      > .fa-star:nth-child(-n+#{$i}) {
        color: #ffffff;
        background: #b2d233;
      }
    }
  }
}

.modal-popup {
  right: 0;
  bottom: 0;
  width: 400px;
  display: block;
  z-index: 100000;
  position: fixed;
  margin-bottom: 0;
  margin-right: 20px;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;

  &:not(.in) {
    opacity: 0;
  }

  .modal-title {
    font-size: 28px;
    font-weight: 700 !important;
    line-height: 34px;
  }

  .modal-content {
    outline: 0;
    background-color: #fff;
    border: 1px solid rgba(228, 228, 228, 0.6);
    border-radius: .3rem .3rem 0 0;
    //box-shadow: 0 4px 20px 1px rgba(93, 93, 93, 0.16);
    box-shadow: 0 0px 33px rgba(0,0,0,.1);

    box-shadow: 0 0 8px 1px rgba(76, 184, 73, 0.3);
    border-bottom: 0;
  }

  .modal-footer {
    text-align: center;
    display: inline-block;

    .btn {
      font-weight: 600;
    }
  }

  .close {
    z-index: 1;
    color: green;
    color: #ffffff;
    background: #4cb849;
    border-radius: 50%;
    line-height: 41px;
    font-weight: 500;
    opacity: 1;
    font-size: 38px;
    width: 36px;
    position: absolute;
    right: 15px;
    padding: 0;
    top: -16px;
    margin: 0;

    > span {
      display: block;
      margin-top: -5px;
    }
  }

  @media (min-width: 2000px) {
    width: 500px;
  }

  @media (max-width: 450px) {
    width: calc(100% - 30px) !important;
    right: 0;
    left: 0;
    margin: 0 auto;

    .modal-title {
      font-size: 22px;
    }

    .close {
      width: 32px;
      font-size: 32px;
      line-height: 37px;
    }

    .modal-footer {
      padding: 10px;

      .btn {
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
      }
    }
  }
}




//
//.breadcrumb {
//    padding: 0;
//    margin: 30px 0;
//    background: transparent;
//
//    > li {
//        & + li {
//            &:before {
//                padding: 0;
//                color: $grey;
//                content: "-";
//            }
//        }
//
//        font-size: 16px;
//        font-weight: 600;
//        font-style: italic;
//    }
//}

.input-group-no-border {
  .input-group {
    border: 0;

    .form-control, .input-group-addon {
      border: 0;
    }
  }
}

.input-group-no-radius {
  .input-group {
    border-radius: 0;

    .form-control, .input-group-addon {
      border-radius: 0;
    }
  }
}

.input-group-addon {
  border-radius: 2px;

  > i.fa {
    color: $input-group-addon-icon-color;
  }
}

.input-group-transparent {
  .input-group {
    &.input-daterange,
    &.input-timerange {
      .form-group:first-of-type .form-control {
        border-right: none;
        box-shadow: none !important;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .form-group:last-of-type .form-control {
        border-left: none;
        box-shadow: none !important;
        border-top-left-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 4px;
      }
    }

    &:not(.input-daterange):not(.input-timerange) {
      .form-control {
        border-right: none;
        box-shadow: none !important;
      }
    }

    .input-group-addon {
      background-color: $white;
    }
  }
}

.bg-image {
  background: transparent center / cover no-repeat;
}

.bg-image-hide-img {
  overflow: hidden;
  background: transparent center / cover no-repeat;

  img {
    opacity: 0;
  }
}

.box-shadow {
  box-shadow: $boxshadow;
}

.box-shadow-light {
  box-shadow: $boxshadow-light;
}

.box-shadow-dark {
  box-shadow: $boxshadow-dark;
}

.nav:not(.nav-menu) {
  li {
    a, a:hover, a:focus {
      border: none;
      color: $white;
      font-weight: 700;
      background-color: $main;
      border-radius: $border-radius;
    }

    &.active a, &.active a:hover, &.active a:focus {
      color: $black;
      background-color: $main;
      box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    }
  }
}

/** Forms **/
.form-group {
  .form-control + .help-block {
      display: block;
      padding-top: 3px;
  }
}
.form-control {
  box-shadow: none;
  border-radius: $border-radius;
  border: 1px solid $input-border;

  &.no-radius {
    border-radius: 0;
  }

  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $input-color-placeholder;
    font-weight: normal;
  }

  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $input-color-placeholder;
    opacity: 1;
    font-weight: normal;
  }

  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $input-color-placeholder;
    opacity: 1;
    font-weight: normal;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $input-color-placeholder;
    font-weight: normal;
  }
}

input[type="radio"], input[type="checkbox"] {
  margin: 6px 0 0;
}

.select-styled {
  &:before {
    right: 0;
    background: $white;
    content: "\f078";
    color: #c2c2c2;
    position: absolute;
    display: inline-block;
    text-rendering: auto;
    padding: 0 10px;
    -webkit-transform: translate(0, 50%);
    -moz-transform: translate(0, 50%);
    -ms-transform: translate(0, 50%);
    -o-transform: translate(0, 50%);
    transform: translate(0, 50%);
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
  }

  select::-ms-expand {
    display: none;
  }
}

.input-group-addon {
  color: #8a8a8a;

  &:first-child {
    border-radius: 0;
  }
}

.label-small {
  label {
    font-size: 15px;
    font-weight: normal;
  }
}

label {
  color: $black;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.lh-1 {
  line-height: 1;
}

.tags-min-height .selectize-input {
  min-height: 100px;
}

/** Forms **/
.btns-form {
  margin-top: 5px;
  padding-top: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

[data-radio-show] {
  &:not(.show-all) {
    .form-group > .radio {
      display: none;

      &:nth-child(-n+4) {
        display: block;
      }
    }
  }
  &.show-all {
    .show-more {
      display: none;
    }
  }

  .show-more {
    padding: 0 !important;
    color: $greylight-border;
    margin-left: 24px;
    text-decoration: none;
  }
}

.text-light {
  font-weight: 400;
}

.text-normal {
  font-weight: normal;
}

.text-thicker {
  font-weight: 500;
}

.text-thick,
.bold {
  font-weight: bold !important;
}

.list-line {
  list-style-type: none;

  li:before {
    content: "-";
    margin-left: -46px;
    margin-right: 3px;
  }
}

.carousel-control {
  .icon-next, .icon-prev {
    font-family: "FontAwesome";
  }

  .fa-chevron-left:before {
    content: "\f053" !important
  }

  .fa-chevron-right:before {
    content: "\f054" !important
  }
}

//.list-line {
//    padding-left: 0;
//    list-style: none;
//
//    li {
//        padding-top: 10px;
//
//        &:before {
//            color: $green;
//            content: "\f00c";
//            padding-right: 10px;
//            display: inline-block;
//            transform: translate(0, 0);
//            -moz-osx-font-smoothing: grayscale;
//            -webkit-font-smoothing: antialiased;
//            font: normal normal normal 18px/1 FontAwesome;
//        }
//    }
//}

//.list-check {
//    padding-left: 0;
//    list-style: none;
//
//    li {
//        padding-top: 10px;
//
//        &:before {
//            color: $green;
//            content: "\f00c";
//            padding-right: 10px;
//            display: inline-block;
//            transform: translate(0, 0);
//            -moz-osx-font-smoothing: grayscale;
//            -webkit-font-smoothing: antialiased;
//            font: normal normal normal 18px/1 FontAwesome;
//        }
//    }
//}

//.list-decimal {
//    counter-reset: item;
//
//    li {
//        display: block;
//
//        &:not(:last-child) {
//            margin-bottom: 30px;
//        }
//
//        &:before {
//            color: $purple;
//            font-size: 25px;
//            font-weight: 700;
//            margin-left: -40px;
//            position: absolute;
//            content: counter(item);
//            counter-increment: item;
//        }
//    }
//}

.item-default {
  padding: 10px;
  border-radius: $border-radius;

  h5 {
    color: $main;
    margin-top: 5px;
    font-weight: bold;
  }

  + .item-default {
    margin-top: 25px;
  }
}

.nav {
  &.nav-tabs {
    li {
      &:first-of-type {
        a {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }

      &:last-of-type {
        a {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      a {
        color: $black;
        border: none;
        border-radius: 0;
        background: $white;
      }

      &.active {
        a, a:focus {
          color: $white;
          border: none !important;
        }
      }
    }
  }
}

.navbar {
  margin-bottom: 0;

  &.navbar-menu {
    border: 0;
    border-radius: 0;
    background-color: $white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .03);

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;

    li.dropdown.open, li.dropdown:hover {
      a {
        //color: $white !important;
        //border-radius: 5px 5px 0 0;
        //background-color: $main-bg !important;

        @media (max-width: 767px) {
          border-radius: 0;
          padding-top: 13px;
          padding-bottom: 13px;
        }
      }
    }

    //.dropdown-menu {
    //    left: -29%;
    //    color: $white !important;
    //    padding: 15px 0;
    //    min-width: 210px;
    //    border-radius: 0;
    //    background-color: $main-bg;
    //
    //    @media (max-width: 767px) {
    //        padding: 0;
    //        margin-bottom: 10px;
    //    }
    //
    //    &:before {
    //        display: block;
    //        position: absolute;
    //        top: -20.5px;
    //        right: 10px;
    //        border-left: 5px solid rgba(0, 0, 0, 0);
    //        border-right: 5px solid rgba(0, 0, 0, 0);
    //        border-bottom: 5px solid #2f2f2f;
    //        color: #fff;
    //    }
    //
    //    li {
    //        margin-bottom: 5px;
    //
    //        > a {
    //            color: $white !important;
    //            background-color: $main-bg !important;
    //        }
    //    }
    //}

    /**
    TODO: Checken!
     */
    .navbar-brand {
      padding: 0;

      > h2 {
        margin: 0;
        font-size: 24px;

        @media (max-width: 767px) {
          margin: 0
        }
      }

      img {
        max-width: 228px;
        margin-right: 10px;

        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }

      @media (max-width: 767px) {
        margin-top: 0;

        > h2 {
          font-size: 16px;
        }

        img {
          width: 156px;
          margin-top: 1px !important;
        }
      }
    }

    .navbar-nav {
      margin-top: 15px;

      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;

      @media (min-width: 768px) {
        float: right;
      }

      > li {
        line-height: 2.4;

        &.active {
          > a:not(.btn) {
            color: $main;
          }
        }
        > a {
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;

          &:not(.btn) {
            color: $black;
          }

          font-size: 16px;
          line-height: 3;
          font-weight: 600;
          padding: 0 30px;

          @media (max-width: 767px) {
            line-height: 1;
            padding: 11px 8px 5px;
          }

          &:hover:not(.btn), &:focus {
            color: $main;
            background-color: transparent;
          }
        }

        .dropdown-toggle {
          &:after {
            border: none;
            margin-left: 8px;
            vertical-align: baseline;

            @extend .zmdi;

            color: $main;
            content: '\f2f9';
            font-weight: bold;
          }
        }

        > .dropdown-menu {
          border: 0;
          padding: 20px;
          min-width: 200px;
          border-radius: 7px;

          li {
            &:before {
              @extend .zmdi;

              color: $main;
              content: '\f2fb';
              font-weight: bold;
            }

            a {
              color: #333333;
              margin-left: 4px;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      .navbar-collapse {
        background: #ffffff;
      }
    }

    &.smaller {
      .navbar-brand {
        margin-top: 12px;

        img {
          max-width: 200px;
        }
      }

      .navbar-nav {
        margin-top: 10px;
        margin-bottom: 10px;

        > li {
          line-height: 2;
        }
      }
    }
  }
}

.navbar-toggle {
  border: 1px solid #888888;

  .icon-bar {
    background-color: #888888;
  }
}

/**
 * Buttons
 */
.btn:not(.btn-sm,.btn-xs,.btn-lg) {
  padding: 6px 16px;
}

[data-toggle="collapse"] {
  &:not(.collapsed) {
    .more {
      display: none;
    }
  }

  &.collapsed {
    .less {
      display: none;
    }
  }
}

.btn:hover {
  text-decoration: none;
}

.btn-link, .btn-link-clean {
  &:active, &:focus {
    outline: none !important;
  }
}

.btn-white {
  color: $main;
  border-color: $white;
  background-color: $white;

  &:hover, &:active, &:focus, &:active:focus {
    color: $white;
    border-color: $main-bg;
    background-color: $main-bg;
  }
}

.btn-main {
  color: $white;
  border-color: $main-bg;
  background-color: $main-bg;

  &:hover, &:active, &:focus, &:active:focus {
    color: $main-bg;
    border-color: $main-bg;
    background-color: $grey-bg;
  }
}

.btn-main-3 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  color: #00587b;
  border-color: $white;
  background-color: $white;


  border: none;
  border-radius: 2px;
  display: inline-block;
  line-height: 36px;
  outline: 0;
  padding: 13px 55px;
  font-weight: bold;
  font-size: 20px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  &:hover, &:active, &:focus, &:active:focus {
    color: $main-bg;
    border-color: $main-bg;
    background-color: $grey-bg;

    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
}

.btn-purple, .btn-feedback {
  color: $white;
  border-color: $purple-border;
  background-color: $purple-bg;

  &:hover, &:active, &:focus, &:active:focus {
    color: $purple;
    border-color: $purple-border;
    background-color: transparent;
  }
}

.btn-success {
  border-color: $green;
  background-color: $green;

  &:hover, &:active, &:focus, &:active:focus {
    border-color: $green;
    background-color: $green;
  }
}

.btn-styled {
  background-color: transparent;
  -webkit-transition: 0.5s; /* Safari */
  transition: 0.5s;
  border-width: 2px;

  &.btn-main {
    color: $main;
    border-color: $main-bg;

    &:hover, &:active, &:focus, &:active:focus {
      color: $white;
      background-color: $main-bg;
    }
  }

  &.btn-info {
    color: $btn-info-bg;
    border-color: $btn-info-border;

    &:hover, &:active, &:focus, &:active:focus {
      color: $white;
      background-color: $btn-info-bg;
    }
  }

  &.btn-default {
    color: $white;
    border-color: $white;

    &:hover, &:active, &:focus, &:active:focus {
      color: $black;
      background-color: $white;
    }
  }

  &.btn-danger {
    color: $btn-danger-bg;
    border-color: $btn-danger-border;

    &:hover, &:active, &:focus, &:active:focus {
      color: $white;
      background-color: $btn-danger-bg;
    }
  }

  &.btn-success {
    color: $green;
    border-color: $green;

    &:hover, &:active, &:focus, &:active:focus {
      color: $white;
      background-color: $green-bg;
    }
  }

  &.btn-white {
    color: $white;
    border-color: $white;

    &:hover, &:active, &:focus, &:active:focus {
      color: $green;
      background-color: $white;
    }
  }
}

.title-bar {
  padding-top: 8px;
  padding-bottom: 16px;
  background-color: $grey-bg;

  h1 {
    color: $white;
    font-size: 26px;
    font-weight: 800;
    font-style: italic;
  }

  .btn-styled {
    font-size: 12px;

    @media (min-width: 768px) {
      font-size: 16px;
      margin-top: 9px;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-bottom: -14px;
      border: 3px solid;
    }
  }
}

.panel {
  border: 0;
  border-radius: $border-radius;

  &.panel-card {
    padding: 0;
    border-radius: 0;
    box-shadow: none;

    &.panel-padderd {
      padding: 10px;
    }

    .panel-title {
      color: $main;
    }

    .panel-body {
      > :first-child {
        margin-top: 0;
      }

      .panel-help {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $greylight-bg;
      }
    }

    &.panel-author {
      .panel-body {
        padding: 5px;
        padding-bottom: 15px;

        .col-img {
          padding: 0 20px 0 20px;
        }

        h6 {
          font-size: 21px;
          margin-top: 17px;
          margin-bottom: 5px;
        }

        .author-text {
          margin: 0;
          color: $grey;
          margin-top: 14px;
        }

        .arrow-small-purple {
          margin-left: -32px;
          position: absolute;
          transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          -webkit-transform: rotate(-180deg);
        }
      }
    }
  }

  &.panel-clean {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid $greylight-border;

    .panel-heading {
      padding: 20px 15px 0;

      .panel-title {
        color: $black;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .panel-body {
      font-size: 15px;
      padding-bottom: 12px;

      p {
        margin: 0;
      }
    }
  }
}

//.card {
//    padding: 10px;
//    background-color: white;
//
//    &.card-cadeaubon {
//        background: $white url('../images/cadeaubon_light.png') center/cover no-repeat;
//        background-position-y: 40%;
//    }
//
//    &.page-card {
//
//        .arrow-small-purple {
//            margin-top: 20px;
//            margin-left: 10px;
//        }
//    }
//
//    &:not(:first-child) {
//        margin-top: 15px;
//    }
//
//    .card-title {
//        color: $green;
//        font-size: 17px;
//        margin-top: 5px;
//        padding-left: 10px;
//        padding-right: 10px;
//        margin-bottom: 20px;
//    }
//
//    .card-body {
//        padding-left: 10px;
//        padding-right: 10px;
//
//        p {
//            line-height: 1.5;
//        }
//    }
//}

//.qoute-styled {
//    font-weight: 500;
//
//    &:before, &:after {
//        content: '"';
//        font-size: 29px;
//        line-height: 0;
//        margin-left: -20px;
//        margin-right: 20px;
//        font-style: italic;
//        font-family: sans-serif;
//        color: $grey-blockqoute;
//    }
//
//    &:after {
//        margin-left: 32px;
//        margin-right: -65px;
//        margin-bottom: -7px;
//        display: inline-block;
//        vertical-align: bottom;
//    }
//
//    font-size: 11.5px;
//    border-left: none;
//    text-align: center;
//}

//.form-short {
//    .row {
//        > [class*="col-"] {
//            @media (min-width: 768px) {
//                &:not(:first-child) {
//                    padding-left: 5px;
//                }
//
//                &:not(:last-child) {
//                    padding-right: 5px;
//                }
//            }
//        }
//    }
//
//    .form-group {
//        margin-bottom: 10px;
//    }
//
//    [type="submit"] {
//        margin-top: 10px;
//    }
//}

.nav {
  &.nav-pills {
    &.nav-justified {
      li {
        > a {
          color: $white;
          margin: 0 5px;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 0.9px;
          background-color: $main-bg;
          border-radius: $border-radius;

          &:hover {
            background-color: $main-bg-hover;
            box-shadow: 0 0 3px rgba(0, 0, 0, .2);
          }
        }

        &.active {
          > a {
            color: $black;
            background-color: $yellow-bg;
            box-shadow: 0 0 3px rgba(0, 0, 0, .2);
          }
        }

        &:first-of-type > a {
          margin-left: 0;
        }

        &:last-of-type > a {
          margin-right: 0;
        }

        @media (max-width: $screen-xs-max) {
          + li {
            margin-left: 0;
          }
        }
      }
    }
  }
}

//.carousel-product {
//    .carousel-inner {
//        margin-bottom: 20px;
//
//        img {
//            box-shadow: $boxshadow-dark;
//            border-radius: $border-radius;
//        }
//    }
//
//    .item .thumb {
//        //width: 25%;
//        cursor: pointer;
//        float: left;
//        border-radius: $border-radius;
//    }
//    .item .thumb img {
//        width: 100%;
//    }
//    .item img {
//        width: 100%;
//    }
//
//    .carousel-control {
//        &.left, &.right {
//            opacity: 1;
//            background: none !important;
//        }
//
//        &.right > span {
//            right: 25%;
//        }
//
//        &.left > span {
//            left: 25%;
//        }
//    }
//
//    .carousel-control {
//        height: 40px;
//        width: 40px;
//        color: #c0c1c2;
//        background: transparent;
//        margin-top: 42px;
//        margin-left: -55px;
//        text-shadow: 0 0 2px #ffffff;
//
//        &.right {
//            margin-right: -55px;
//        }
//    }
//}
//
//.carousel-styled {
//    .carousel-bg-images {
//        .item {
//            min-height: 500px;
//            background: transparent center / cover no-repeat;
//
//            .carousel-caption {
//                padding-bottom: 70px;
//            }
//
//            .title {
//                color: $white;
//                font-size: 31px;
//                line-height: 1.8;
//                font-weight: 700;
//                text-shadow: 2px 3px rgba(0, 0, 0, 0.7);
//            }
//
//            .btn {
//                border: 0;
//                font-size: 17px;
//                margin-top: 80px;
//                margin-bottom: 25px;
//            }
//        }
//    }
//
//    .carousel-control {
//        &.left, &.right {
//            opacity: 1;
//            background: none !important;
//        }
//
//        &.right > span {
//            right: 25%;
//        }
//
//        &.left > span {
//            left: 25%;
//        }
//    }
//}
//
//.items-carousel {
//    margin-bottom: 0;
//
//    .item {
//        a {
//            width: 100%;
//            border: 8px solid $white;
//            color: $white;
//            display: block;
//            height: 200px;
//            text-align: center;
//            background-color: $white;
//            border-radius: $border-radius;
//
//            .fa {
//                padding: 4px;
//                font-size: 17px;
//                background-color: $main-bg;
//            }
//
//            .text {
//                display: none;
//            }
//
//            &:hover {
//                .fa {
//                    display: none;
//                }
//
//                .text {
//                    height: 100%;
//                    padding: 11px;
//                    display: block;
//                    font-size: 17px;
//                    padding-top: 6px;
//                    background-color: rgba(211, 9, 105, 0.8);
//
//                    span.bold {
//                        display: block;
//                        font-size: 14px;
//                        margin-top: 15px;
//                    }
//                }
//            }
//        }
//    }
//
//    /* The controlsy */
//    .carousel-control {
//        height: 40px;
//        width: 40px;
//        color: #c0c1c2;
//        background: transparent;
//        margin-top: 80px;
//        margin-left: -55px;
//        text-shadow: 0 0 2px #ffffff;
//
//        &.right {
//            margin-right: -55px;
//        }
//
//        @media (min-width: 800px) and (max-width: 1024px) {
//            margin-left: -39px;
//
//            &.right {
//                margin-right: -39px;
//            }
//        }
//
//        @media (min-width: 768px) and (max-width: 800px) {
//            margin-left: -55px;
//
//            &.right {
//                margin-right: -26px;
//            }
//        }
//    }
//}
//
///* News */
//.panel-main {
//  position: relative;
//  border: none;
//  box-shadow: 0 1px 2px 0.5px rgba(0, 0, 0, .09);
//
//  .panel-heading {
//    padding: 0;
//
//    .panel-image {
//      width: 100%;
//      height: 188px;
//      border-top-left-radius: 4px;
//      border-top-right-radius: 4px;
//      background: transparent center / cover no-repeat;
//    }
//
//    .panel-title {
//      color: $black;
//      font-size: 24px;
//      font-weight: 300;
//    }
//  }
//
//  .panel-body {
//    padding: 0 17px 15px;
//
//    .title {
//      font-size: 26px;
//      font-weight: 300;
//    }
//
//    p {
//      color: #4d4d4d;
//      font-size: 16px;
//      line-height: 24px;
//      font-weight: 300;
//    }
//  }
//
//  .panel-footer {
//    right: 0;
//    border: 0;
//    bottom: 0;
//    padding: 0;
//    color: $black;
//    position: absolute;
//    background-color: $white;
//    border-bottom-left-radius: 4px;
//    border-bottom-right-radius: 4px;
//
//    > a {
//      font-weight: 600;
//
//      .btn {
//        border: 0;
//        margin-left: 9px;
//        padding: 7px 21px 7px 12px;
//      }
//    }
//  }
//}
//
//.panel-newslistitem {
//  .panel-body {
//    .title {
//      font-size: 20px;
//    }
//
//    padding-bottom: 34px;
//  }
//}
//
//.panel-styled {
//  border: none;
//
//  .panel-heading {
//    padding: 9px 9px 2px;
//
//    .image {
//      width: 100%;
//      height: 145px;
//      background: transparent center / cover no-repeat;
//    }
//
//    .title {
//      color: $main;
//      font-size: 16px;
//      margin-top: 20px;
//      margin-bottom: 13px;
//      padding: 0 7px;
//      font-weight: bold;
//    }
//  }
//
//  .panel-body {
//    padding: 0 17px 15px;
//  }
//
//  .panel-footer {
//    border: 0;
//    color: $main;
//    padding: 0 33px 15px;
//    background-color: $white;
//
//    > a {
//      font-weight: 600;
//    }
//  }
//}
//
//.panel-location {
//  border: none;
//
//  &[style*="min-height"] {
//    position: relative;
//
//    .panel-footer {
//      bottom: 0;
//      width: 100%;
//      position: absolute;
//    }
//  }
//
//  .panel-heading {
//    padding: 12px 11px 2px;
//    border-top-left-radius: $border-radius;
//    border-top-right-radius: $border-radius;
//
//    .bg-image {
//      width: 100%;
//      height: 120px;
//      background: transparent center / cover no-repeat;
//    }
//
//    .meta {
//      color: $black;
//      margin: 0 5px;
//      font-size: 11px;
//      font-weight: 400;
//
//      display: block;
//      min-height: 34px;
//    }
//
//    .title {
//      font-size: 16px;
//      margin-top: 15px;
//      margin-bottom: 5px;
//      padding: 0 5px;
//      color: $main;
//      font-weight: bold;
//    }
//  }
//
//  .panel-body {
//    padding: 0 17px 15px;
//  }
//
//  .panel-footer {
//    border: 0;
//    padding-top: 0;
//    color: $main;
//    background-color: $white;
//
//    > a {
//      font-weight: 600;
//    }
//  }
//}
//
//.panel-news {
//  border: none;
//
//  .panel-heading {
//    padding: 12px 11px 2px;
//
//    .news-image {
//      width: 100%;
//      height: 85px;
//      background: transparent center / cover no-repeat;
//    }
//
//    .news-meta {
//      color: $black;
//      margin: 0 5px;
//      font-size: 11px;
//      font-weight: 400;
//    }
//
//    .title {
//      color: $main;
//      font-size: 16px;
//      margin-top: 15px;
//      margin-bottom: 5px;
//      padding: 0 5px;
//      font-weight: bold;
//    }
//  }
//
//  .panel-body {
//    padding: 0 17px 15px;
//  }
//
//  .panel-footer {
//    border: 0;
//    color: $main;
//    padding: 0 33px 15px;
//    background-color: $white;
//
//    > a {
//      font-weight: 600;
//    }
//  }
//
//  &.panel-single {
//    .panel-heading {
//      margin-bottom: 10px;
//
//      .news-image {
//        height: 250px;
//      }
//
//      .title {
//        color: $main;
//        font-size: 28px;
//        font-weight: bold;
//      }
//    }
//  }
//}
//
//.panel-search {
//  .panel-body {
//    padding: 5px;
//
//    .search-title {
//      color: $green;
//      font-size: 18px;
//      font-weight: 600;
//      line-height: 2.2;
//      padding-top: 3px;
//    }
//
//    @media (min-width: 768px) {
//      .form-control {
//        height: 40px;
//        padding: 19px 16px;
//      }
//
//      .form-group {
//        margin-bottom: 0;
//
//        .form-control {
//          height: 40px;
//          padding: 19px 16px;
//        }
//      }
//    }
//
//    .btn {
//      padding: 9px 0;
//    }
//  }
//}

//.paginate {
//  margin: 0 25px;
//  margin-bottom: -8px;
//  text-align: center;
//
//  .prev, .next {
//    line-height: 2.2;
//    font-size: 15px;
//    font-weight: 600;
//  }
//
//  .pagination {
//    margin: 0;
//
//    li {
//      a, span {
//        border: 0;
//        color: $black;
//        margin: 0 3px;
//        font-weight: 600;
//        border-radius: 4px;
//        background-color: $white;
//      }
//
//      span.text-grey {
//        color: $grey;
//      }
//
//      a:hover {
//        color: $main;
//      }
//
//      &.active {
//        a, span {
//          color: $main;
//          border-radius: 4px;
//          border: 1px solid $main;
//
//          &:hover {
//            background-color: $white;
//          }
//        }
//      }
//    }
//  }
//
//  @media (max-width: 600px) {
//    margin: 0 25px -8px;
//
//    .pagination {
//      li {
//        a, span {
//          margin: 0;
//        }
//      }
//    }
//  }
//}

.info-block {
  display: block;
  font-weight: 600;
  min-height: 65px;
  padding: 9px 10px;
  margin-bottom: 20px;
  border: 1px solid $greylight-border;

  i {
    float: left;
    font-size: 35px;
    margin-top: 6px;
    margin-left: 6px;
    margin-right: 16px;
  }

  p {
    margin: 0;
  }
}

//
//.feedbackform-text {
//    @media (min-width: 768px) {
//        margin-top: -3px;
//
//        .arrow-small-purple {
//            float: right;
//            display: block;
//            margin-top: 19px;
//            margin-right: -25px;
//        }
//    }
//}

.avatar {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background: transparent center / cover no-repeat;
}

//.thema-image-block {
//    height: 267px;
//    text-align: center;
//    position: relative;
//    border-radius: $border-radius;
//
//    .title {
//        margin-top: 0;
//        font-size: 25px;
//        padding-top: 45px;
//        text-shadow: 0 1px rgba(0, 0, 0, .7);
//    }
//
//    button {
//        left: 50%;
//        bottom: 33px;
//        position: absolute;
//        -ms-transform: translateX(-50%);
//        -webkit-transform: translateX(-50%);
//        transform: translateX(-50%);
//
//    }
//}

.partners {
  img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    padding: 0 10px;
    opacity: 0.4;
  }
}

.file-button {
  display: block;
  border-radius: 6px;
  border: 1px solid $greylight-border;

  > .row {
    padding: 9px 5px;
  }

  .fa[class*="fa-file-"] {
    font-size: 40px;
    margin-top: 5px;
  }

  .label {
    z-index: 100;
    position: relative;
    margin-top: -20px;
  }

  .file-name {
    line-height: 2;
  }

  .file-link {
    &:hover {
      .file-info {
        display: none;
        transition: all 0.2s ease-in-out 0s;
      }
    }

    &:not(:hover) {
      .file-meta {
        display: none;
        transition: all 0.2s ease-in-out 0s;
      }
    }
  }
}

.color-file.docx {
  color: #2c4098;
}

.color-file.pdf {
  color: #db4437;
}

form[data-prepost] {
  .prepost-loader {
    display: none;
  }

  &.prepost-loading {
    .prepost-form {
      display: none;
    }

    .prepost-loader {
      display: block;

      .fa {
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;
      }
    }

    .prepost-loading-disable {
      filter: alpha(opacity=65);
      opacity: .65;
      box-shadow: none;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.carousel-multi {
  @media (min-width: 992px) { //768
    /* show 3 items */
    .carousel-inner .active,
    .carousel-inner .active + .carousel-item,
    .carousel-inner .active + .carousel-item + .carousel-item {
      display: block;
    }

    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item,
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
      transition: none;
    }

    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-prev {
      position: relative;
      transform: translate3d(0, 0, 0);
    }

    .carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: absolute;
      top: 0;
      right: -33%;
      z-index: -1;
      display: block;
      visibility: visible;
    }

    /* left or forward direction */
    .active.carousel-item-left + .carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }

    /* farthest right hidden item must be abso position for animations */
    .carousel-inner .carousel-item-prev.carousel-item-right {
      position: absolute;
      top: 0;
      left: -33%;
      z-index: -1;
      display: block;
      visibility: visible;
    }

    /* right or prev direction */
    .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block;
    }

  }

  @media (min-width: 768px) and (max-width: 991px) {
    /* show 2 items */
    .carousel-inner .active,
    .carousel-inner .active + .carousel-item {
      display: block;
    }

    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
      transition: none;
    }

    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-prev {
      position: relative;
      transform: translate3d(0, 0, 0);
    }

    .carousel-inner .active.carousel-item + .carousel-item + .carousel-item
      {
      position: absolute;
      top: 0;
      right: -50%;
      z-index: -1;
      display: block;
      visibility: visible;
    }

    /* left or forward direction */
    .active.carousel-item-left + .carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left + .carousel-item
    //.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item
    {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }

    /* farthest right hidden item must be abso position for animations */
    .carousel-inner .carousel-item-prev.carousel-item-right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      visibility: visible;
    }

    /* right or prev direction */
    .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right + .carousel-item
    //.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item
    {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block;
      visibility: visible;
    }
  }

  @media (max-width: 767px) {
    /* show 2 items */
    .carousel-inner .active {
      display: block;
    }

    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) {
      transition: none;
    }

    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-prev {
      position: relative;
      transform: translate3d(0, 0, 0);
    }

    .carousel-inner .active.carousel-item + .carousel-item
    {
      position: absolute;
      top: 0;
      right: -100%;
      z-index: -1;
      display: block;
      visibility: visible;
    }

    /* left or forward direction */
    .active.carousel-item-left + .carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left + .carousel-item
      //.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item
    {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }

    /* farthest right hidden item must be abso position for animations */
    .carousel-inner .carousel-item-prev.carousel-item-right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      visibility: visible;
    }

    /* right or prev direction */
    .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right + .carousel-item
      //.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item
    {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block;
    }
  }

  //@media (min-width: 501px) and (max-width: 767px) {
  //  /* show 3 items */
  //  .carousel-inner .active,
  //  .carousel-inner .active + .carousel-item {
  //    display: block;
  //  }
  //
  //  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  //  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
  //    transition: none;
  //  }
  //
  //  .carousel-inner .carousel-item-next,
  //  .carousel-inner .carousel-item-prev {
  //    position: relative;
  //    transform: translate3d(0, 0, 0);
  //  }
  //
  //  .carousel-inner .active.carousel-item + .carousel-item + .carousel-item {
  //    position: absolute;
  //    top: 0;
  //    right: -25%;
  //    z-index: -1;
  //    display: block;
  //    visibility: visible;
  //  }
  //
  //  /* left or forward direction */
  //  .active.carousel-item-left + .carousel-item-next.carousel-item-left,
  //  .carousel-item-next.carousel-item-left + .carousel-item,
  //  .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
  //    position: relative;
  //    transform: translate3d(-100%, 0, 0);
  //    visibility: visible;
  //  }
  //
  //  /* farthest right hidden item must be abso position for animations */
  //  .carousel-inner .carousel-item-prev.carousel-item-right {
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    z-index: -1;
  //    display: block;
  //    visibility: visible;
  //  }
  //
  //  /* right or prev direction */
  //  .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
  //  .carousel-item-prev.carousel-item-right + .carousel-item,
  //  .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
  //    position: relative;
  //    transform: translate3d(100%, 0, 0);
  //    visibility: visible;
  //    display: block;
  //    visibility: visible;
  //  }
  //}

  //@media (max-width: 767px) {
  //  .carousel-inner .carousel-item:not(.active) {
  //    display: none;
  //  }
  //
  //  .carousel-inner .active.carousel-item + .carousel-item {
  //    position: absolute;
  //    top: 0;
  //    right: -50%;
  //    z-index: -1;
  //    display: block;
  //    visibility: visible;
  //  }
  //}

  a[data-slide] {
    color: gray;
    font-weight: bold;
  }

  .carousel {
    margin-top: 37px;
    margin-bottom: 67px;

    .carousel-control-next,
    .carousel-control-prev {
      opacity: 1;

      > i {
        font-size: 46px;
        color: #312f2f !important;
      }
    }
  }

  @media (max-width: 1392px) {
    .carousel-control-next,
    .carousel-control-prev {
      width: 10%;
    }
  }

  @media (max-width: 1280px) {
    .carousel-control-next,
    .carousel-control-prev {
      width: 6%;
    }
  }

  @media (max-width: 1199px) {
    .carousel-control-next,
    .carousel-control-prev {
      width: 11%;
    }
  }

  @media (max-width: 1113px) {
    .carousel-control-next,
    .carousel-control-prev {
      width: 6%;
    }
  }

  @media (max-width: 1047px) {
    .carousel-control-next,
    .carousel-control-prev {
      width: 3%;
    }
  }

  @media (max-width: 989px) {
    .carousel-control-next,
    .carousel-control-prev {
      width: 15%;
    }
  }

  @media (max-width: 886px) {
    .carousel-control-next,
    .carousel-control-prev {
      width: 10%;
    }
  }

  @media (max-width: 804px) {
    .carousel-control-next,
    .carousel-control-prev {
      width: 6%;
    }
  }

  @media (max-width: 500px) {
    .carousel-item {
      width: 100%;
    }
  }
}

