/* =============
   Tour page
============= */

div.hopscotch-bubble {
  border: 3px solid $custom;
  border-radius: 5px;

  .hopscotch-next {
    background-color: $custom !important;
    background-image: none !important;
    border-color: $custom !important;
    text-shadow: none !important;
    margin: 0 0 0 5px !important;
  }
  .hopscotch-prev {
    background-color: $custom !important;
    background-image: none !important;
    border-color: $custom !important;
    text-shadow: none !important;
    color: $white !important;
  }
}
div.hopscotch-bubble .hopscotch-bubble-number {
  background: $custom;
  padding: 0px;
  border-radius: 50%;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
    border-right: 19px solid $custom;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
    border-left: 19px solid $custom;
    left: -2px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
    border: none;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 0px solid $custom;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 19px solid $custom;
  top: 0px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 0px solid rgba(0,0,0,.5);
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 19px solid $custom;
  top: -2px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 0px solid rgba(0,0,0,.5);
}

@media (max-width: 419px) {
  div.hopscotch-bubble .hopscotch-bubble-container {
    max-width: 180px;
  }
}
