body.manager {
  .btn {
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  a {
    &:not(.btn) {
      color: #00587b;
    }

    &:hover, &:not(.btn):hover {
      text-decoration: none !important;
    }
  }

  .page-title {
    line-height: 1.6;
  }

  .profile-dropdown {
    width: 188px;
  }

  .navbar-custom {
    background-color: #046C9F;
  }

  #sidebar-menu > ul > li {
    > a.active {
      background-color: #59B946;
    }

    li.active {
      a {
        color: #00587b;
        font-weight: bold;
      }
    }
  }

  .footer {
    color: #ffffff;
    background-color: #00587C;
  }
}

