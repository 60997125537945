.radio:not(.radio-styled) {
  label {
    &:before, &:after {
      margin-left: -15px;
    }
  }
}

[v-cloak], [ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

html.cloaked {
  [ready\:cloak], [ready-cloak], [data-ready-cloak], .ready-cloak {
    display: none !important;
  }
}

/**
 *   DataTables Bootstrap
 **/
table.dataTable {
  border: 0 !important;
  width: 100% !important;
}

table.dataTable {
  [type="button"] {
    -webkit-appearance: none;
  }
}

table.dataTable .dataTables_empty {
  padding: 16px 0 !important;
}

table.dataTable thead th {
  border: none;
}

table.table-bordered.dataTable td:first-child,
table.table-bordered.dataTable th:first-child {
  border-left-width: 1px;
}

table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable th:last-child {
  border-right-width: 1px;
}

table.table-bordered.dataTable tbody tr:last-child td {
  border-bottom-width: 1px;
}

table.dataTable tbody tr td:last-child {
  padding-top: 8px;
  padding-bottom: 0;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after,
table.dataTable thead th.sorting_asc_disabled:after,
table.dataTable thead th.sorting_desc_disabled:after {
  font-family: 'Font Awesome 5 Pro';
}

table.dataTable thead .sorting:after {
  content: "\f0dc";
  /* sort */
}

table.dataTable thead .sorting_asc:after {
  content: "\f0de";
  /* sort-by-attributes */
}

table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  /* sort-by-attributes-alt */
}

/* Datetimepicer */
.bootstrap-datetimepicker-widget table td.disabled, .bootstrap-datetimepicker-widget table td.disabled:hover {
  color: $gray-light;
}

/* Summernote */
.note-popover .popover-content .dropdown-menu,
.panel-heading.note-toolbar .dropdown-menu {
  min-width: 123px;
}

.note-editor.note-frame {
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.note-toolbar.panel-heading {
  border-radius: 4px 4px 0 0;
}

.note-editor.note-frame .note-statusbar {
  border-radius: 0 0 4px 4px;
}

.note-editable img, .panel-content img {
  display: block;
  max-width: 100%;
  height: auto;
}

.note-editor {
  .panel-heading {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #dddddd;
  }
}

.panel-body img {
  &[style*="float: right"],
  &[style*="float:right"] {
    margin-left: 10px;
  }

  &[style*="float: left"],
  &[style*="float:left"] {
    margin-right: 10px;
  }
}

.link-dialog {
  .modal-header {
    .close {
      top: 21px;
      right: 20px;
      position: absolute;
    }
  }
}

.news-editor {
  .note-options {
    display: none;
  }
}

.fancybox-overlay {
  .fancybox-close {
    background-image: url(../images/fancybox_sprite_purple.png);
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {

  .fancybox-overlay {
    .fancybox-close {
      background-image: url(../images/fancybox_sprite_purple@2x.png);
      background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
    }
  }
}

@media (min-width: $screen-xs-max) {
  .fancybox-overlay {
    .fancybox-close {
      top: 0;
      right: -41px;
    }
    .fancybox-skin {
      border-radius: 0;
      text-shadow: none;
    }

    .fancybox-prev {
      //left: -70px;

      span {
        color: white;
        font-size: 40px;
        line-height: 40px;
        visibility: visible;
        background: transparent;
        font-family: 'Font Awesome 5 Pro';

        &:after {
          content: '\f053';
        }

      }
    }

    .fancybox-next {
      //right: -70px;

      span {
        color: white;
        font-size: 40px;
        line-height: 38px;
        visibility: visible;
        background: transparent;
        font-family: 'Font Awesome 5 Pro';

        &:after {
          content: '\f054';
        }

      }
    }
  }
}

/* drag & drop list */
.dd-item {
  cursor: move;

  .dd-handle {
    position: relative;
  }

  .dd-item-btn-right {
    right: 10px;
    position: absolute;
  }
}

/* Selectize */
.has-error {
  .selectize-control .selectize-input {
    border-color: #a94442;
  }
}

.selectize-control {
  text-align: left;

  .description {
    display: block;
    font-size: 12px;
  }

  &.selectize_single {
    .selectize-input > div,
    .selectize-input > div.active {
      width: 100%;
      color: #333333;
      background: transparent;
    }

    &.plugin-remove_button [data-value] .remove {
      color: #c2c2c2;
      font-size: 31px;
    }
  }

  &.input-lg {
    &.multi .selectize-input.has-items,
    .selectize-input {
      padding: 13px 15px;
    }
  }

  &.single {
    &:not(.selectize-hide-dropdown) {
      .selectize-input {
        &:after {
          top: auto;
          right: 17px;
          border: none;
          content: "\f078";
          color: #c2c2c2;
          position: absolute;
          padding-right: 8px;
          margin-top: -2px;
          display: inline-block;
          text-rendering: auto;
          //-webkit-transition: all 0.15s ease-in-out;
          //transition: all 0.15s ease-in-out;
          -webkit-font-smoothing: antialiased;
          font-family: 'Font Awesome 5 Pro';
        }

        &.dropdown-active {
          &:after {
            border: none;
            //margin-top: -4px
            content: "\f077";
          }
        }
      }
    }

    &.selectize-hide-dropdown {
      .selectize-input {
        &:after {
          display: none;
        }
      }
    }
  }

  .selectize-dropdown {
    text-align: left;

    .selectize-dropdown-content {
      border-radius: $border-radius;
    }
  }

  .selectize-input {
    font-weight: 400;
    border-color: $input-border;
    border-radius: $border-radius;

    &.dropdown-active {
      border-radius: $border-radius;
    }
  }
}

fieldset[disabled] {
  .form-control {
    &.selectize-control:not(.tags-min-height) {
      height: 34px;
    }
  }
}

/* Tooltipster */
.tooltipster-sidetip {
  &.tooltipster-top {
    .tooltipster-arrow-background {
      border-top-color: #0d9c90 !important;
    }
  }

  .tooltipster-arrow-border {
    border: none !important;
  }

  .tooltipster-box {
    background: #b4e391 !important;
    background: -moz-linear-gradient(top, #b4e391 0%, #61c419 50%, #b4e391 100%) !important;
    background: -webkit-linear-gradient(top, #b4e391 0%, #61c419 50%, #b4e391 100%) !important;
    background: linear-gradient(#add333, #0d9c90) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b4e391', endColorstr='#b4e391', GradientType=0) !important;
    border: none !important;
    max-width: 350px;
    word-wrap: break-word;
  }
}
