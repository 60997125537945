$border-radius: 4px;
$input-border: #D9D9D9;
$input-color-placeholder: #B0B0B0;
$input-group-addon-icon-color: #8a8a8a;

// Colors

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #eee

$brand-primary:         darken(#428bca, 6.5%); // #337ab7
$brand-success:         #5cb85c;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;

$white: #ffffff;
$text: #728f95;
$black: #000001;
$danger: #f05050;
$success: #81c868;
$warning: #ffbd4a;
$primary: #5d9cec;
$purple: #771386; /*#6B0081;*/ /*740086*/
$purple-border: #741385;
$greylight-border: #c5c5c5;
$grey-blockqoute: #a2a3a4;

$main: #00587b;
$main2: #59B946;
$main-bg: #0062A7;
$main-bg-light: #de0b70;
$main-bg-hover: transparent;

$blue-dark: #012739;

$yellow: #fcde34;
$yellow-border: #d0b827;
$yellow-shadow: #c9a922;
$yellow-bg: #fcde34;

$grey: #728f95;//#6b6c6d;
$grey-bg: #EFEFEF;
$greylight-bg: #edeff0; // NOT USE???
$greylight-border: #DFDFDF;

$darklight: #393a3b;

$green: #25a398;
$green-bg: #25a398;
$green-footer-bg: #25a398; /*#18A798;*/
$success-bg: #25a398;

$purple-bg: #761487;


$yellow-star: #e49221;

$h4: 18px;

$boxshadow: 0 0 3px rgba(0, 0, 0, .2);
$boxshadow-light: 0 0 1px 0.5px rgba(0, 0, 0, .14);
$boxshadow-dark: 0 2px 10px rgba(0, 0, 0, .2);

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                normal;

$btn-default-color:              #333;
$btn-default-bg:                 #fff;
$btn-default-border:             #ccc;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             darken($btn-primary-bg, 5%);

$btn-success-color:              #fff;
$btn-success-bg:                 $brand-success;
$btn-success-border:             darken($btn-success-bg, 5%);

$btn-info-color:                 #fff;
$btn-info-bg:                    $brand-info;
$btn-info-border:                darken($btn-info-bg, 5%);

$btn-warning-color:              #fff;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             darken($btn-warning-bg, 5%);

$btn-danger-color:               #fff;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              darken($btn-danger-bg, 5%);

$btn-link-disabled-color:        $gray-light;

//// Allows for customizing button radius independently from global border radius
//$btn-border-radius-base:         $border-radius-base;
//$btn-border-radius-large:        $border-radius-large;
//$btn-border-radius-small:        $border-radius-small;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md-min:              $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);
