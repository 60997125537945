[data-slide-to] {
  cursor: pointer;
}

.carousel-cover {
  .carousel-item {
    height: 840px;
  }

  .carousel-caption {
    bottom: 53px;
    color: #333;
    border-radius: 13px;
    background: rgba(255, 255, 255, 0.7019607843137254) !important;
    padding: 20px 30px;

    p:last-of-type {
      margin-bottom: 0;
    }

    .btn {
      font-weight: 600;
    }
  }

  @media (max-width: 1665px) {
    .carousel-item {
      height: 650px;
    }
  }

  @media (max-height: 850px) and (min-width: 1155px) {
    .carousel-item {
      height: 450px;
    }
  }

  @media (max-width: 1155px) {
    .carousel-item {
      height: 450px;
    }

    .carouse-title {
      font-size: 31px;
    }
  }

  @media (max-width: 1035px) and (min-width: 376px) {
    .carouse-title {
      font-size: 26px;
    }

    .btn {
      padding: .25rem .5rem;
      font-size: .875rem;
      line-height: 1.5;
      border-radius: .2rem;
    }
  }

  @media (max-width: 375px) {
    .carouse-title {
      font-size: 20px;
    }

    .carousel-caption {
      padding: 9px 12px;
    }

    .btn-lg {
      padding: .25rem .5rem;
      font-size: .875rem;
      line-height: 1.5;
      border-radius: .2rem;
    }
  }
}
