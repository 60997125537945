
button:focus {
  outline: none !important;
}

a.btn {
  &:focus {
    color: $white !important;
  }
}

.btn-rounded {
    border-radius: 2em !important;
    padding: .5rem 1.5rem;
}

.btn-label {
  background: rgba($dark, 0.1);
  display: inline-block;
  padding: 7px 15px;
  border-radius: 3px 0 0 3px;
  margin: -7px -14px;
  margin-right: 12px;
}

.btn-rounded .btn-label {
  padding: 7px 15px 7px 20px;
  margin-left: -20px;
}

.btn-rounded .btn-label-right {
  margin-right: -20px;
  margin-left: 12px;
}

.btn-label-right {
  margin-left: 12px;
  margin-right: -13px;
  border-radius: 0px 3px 3px 0px;
}


.btn-custom {
  background-color: $custom;
  border-color: $custom;
  color: $white;
}

.btn-custom:hover, .btn-custom:focus, .btn-custom:active, .btn-custom.active,
.btn-custom.focus, .btn-custom:active, .btn-custom:focus, .btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
  background-color: darken($custom,7%);
  border-color: darken($custom,7%);
  color: $white;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active,
.btn-primary.focus, .btn-primary:active, .btn-primary:focus, .btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
  background-color: darken($primary,8%);
  border-color: darken($primary,8%);
  color: $white;
}

.btn-success {
  background-color: $success;
  border-color: $success;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active,
.btn-success.focus, .btn-success:active, .btn-success:focus, .btn-success:hover,
.open > .dropdown-toggle.btn-success {
  background-color: darken($success,8%);
  border-color: darken($success,8%);
  color: $white;
}

.btn-info {
  background-color: $info;
  border-color: $info;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active,
.btn-info.focus, .btn-info:active, .btn-info:focus, .btn-info:hover,
.open > .dropdown-toggle.btn-info {
  background-color: darken($info,8%);
  border-color: darken($info,8%);
  color: $white;
}

.btn-warning {
  background-color: $warning;
  border-color: $warning;
  color: $white;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active,
.btn-warning.focus, .btn-warning:active, .btn-warning:focus, .btn-warning:hover,
.open > .dropdown-toggle.btn-warning {
  background-color: darken($warning,8%);
  border-color: darken($warning,8%);
  color: $white;
}

.btn-danger {
  background-color: $danger;
  border-color: $danger;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active,
.btn-danger.focus, .btn-danger:active, .btn-danger:focus, .btn-danger:hover,
.open > .dropdown-toggle.btn-danger {
  background-color: darken($danger,8%);
  border-color: darken($danger,8%);
  color: $white;
}

.btn-dark {
  background-color: $dark;
  border-color: $dark;
  color: $white;
}

.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active,
.btn-dark.focus, .btn-dark:active, .btn-dark:focus, .btn-dark:hover,
.open > .dropdown-toggle.btn-dark {
  background-color: darken($dark,8%);
  border-color: darken($dark,8%);
  color: $white;
}

.btn-pink {
  background-color: $pink;
  border-color: $pink;
  color: $white;
}

.btn-pink:hover, .btn-pink:focus, .btn-pink:active, .btn-pink.active,
.btn-pink.focus, .btn-pink:active, .btn-pink:focus, .btn-pink:hover,
.open > .dropdown-toggle.btn-pink {
  background-color: darken($pink,8%);
  border-color: darken($pink,8%);
  color: $white;
}

.btn-purple {
  background-color: $purple;
  border-color: $purple;
  color: $white;
}

.btn-purple:hover, .btn-purple:focus, .btn-purple:active, .btn-purple.active,
.btn-purple.focus, .btn-purple:active, .btn-purple:focus, .btn-purple:hover,
.open > .dropdown-toggle.btn-purple {
  background-color: darken($purple,8%);
  border-color: darken($purple,8%);
  color: $white;
}


.btn-outline-custom {
  color: $custom;
  background-image: none;
  background-color: transparent;
  border-color: $custom;
}

// Outline Buttons

.btn-outline-custom:hover, .btn-outline-custom:focus, .btn-outline-custom:active, .btn-outline-custom.active,
.btn-outline-custom.focus, .btn-outline-custom:active, .btn-outline-custom-primary:focus, .btn-outline-custom:hover,
.open > .dropdown-toggle.btn-outline-custom {
  color: $white;
  background-color: $custom;
  border-color: $custom;
}


.btn-outline-primary {
  color: $primary;
  background-image: none;
  background-color: transparent;
  border-color: $primary;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active,
.btn-outline-primary.focus, .btn-outline-primary:active, .btn-outline-primary:focus, .btn-outline-primary:hover,
.open > .dropdown-toggle.btn-outline-primary {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.btn-outline-success {
  color: $success;
  background-image: none;
  background-color: transparent;
  border-color: $success;
}

.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active,
.btn-outline-success.focus, .btn-outline-success:active, .btn-outline-success:focus, .btn-outline-success:hover,
.open > .dropdown-toggle.btn-outline-success {
  color: $white;
  background-color: $success;
  border-color: $success;
}

.btn-outline-info {
  color: $info;
  background-image: none;
  background-color: transparent;
  border-color: $info;
}

.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active,
.btn-outline-info.focus, .btn-outline-info:active, .btn-outline-info:focus, .btn-outline-info:hover,
.open > .dropdown-toggle.btn-outline-info {
  color: $white;
  background-color: $info;
  border-color: $info;
}

.btn-outline-warning {
  color: $warning;
  background-image: none;
  background-color: transparent;
  border-color: $warning;
}

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active,
.btn-outline-warning.focus, .btn-outline-warning:active, .btn-outline-warning:focus, .btn-outline-warning:hover,
.open > .dropdown-toggle.btn-outline-warning {
  color: $white;
  background-color: $warning;
  border-color: $warning;
}

.btn-outline-danger {
  color: $danger;
  background-image: none;
  background-color: transparent;
  border-color: $danger;
}

.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active,
.btn-outline-danger.focus, .btn-outline-danger:active, .btn-outline-danger:focus, .btn-outline-danger:hover,
.open > .dropdown-toggle.btn-outline-danger {
  color: $white;
  background-color: $danger;
  border-color: $danger;
}

.btn-outline-pink {
  color: $pink;
  background-image: none;
  background-color: transparent;
  border-color: $pink;
}

.btn-outline-pink:hover, .btn-outline-pink:focus, .btn-outline-pink:active, .btn-outline-pink.active,
.btn-outline-pink.focus, .btn-outline-pink:active, .btn-outline-pink:focus, .btn-outline-pink:hover,
.open > .dropdown-toggle.btn-outline-pink {
  color: $white;
  background-color: $pink;
  border-color: $pink;
}

.btn-outline-purple {
  color: $purple;
  background-image: none;
  background-color: transparent;
  border-color: $purple;
}

.btn-outline-purple:hover, .btn-outline-purple:focus, .btn-outline-purple:active, .btn-outline-purple.active,
.btn-outline-purple.focus, .btn-outline-purple:active, .btn-outline-purple:focus, .btn-outline-purple:hover,
.open > .dropdown-toggle.btn-outline-purple {
  color: $white;
  background-color: $purple;
  border-color: $purple;
}

.btn-outline-dark {
  color: $dark;
  background-image: none;
  background-color: transparent;
  border-color: $dark;
}

.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active,
.btn-outline-dark.focus, .btn-outline-dark:active, .btn-outline-dark:focus, .btn-outline-dark:hover,
.open > .dropdown-toggle.btn-outline-dark {
  color: $white;
  background-color: $dark;
  border-color: $dark;
}



/* Social Buttons */

.btn-facebook {
    color: $white !important;
    background-color: #3b5998 !important;
}
.btn-twitter {
    color: $white !important;
    background-color: #00aced !important;
}
.btn-linkedin {
    color: $white !important;
    background-color: #007bb6 !important;
}
.btn-dribbble {
    color: $white !important;
    background-color: #ea4c89 !important;
}
.btn-googleplus {
    color: $white !important;
    background-color: #dd4b39 !important;
}
.btn-instagram  {
    color: $white !important;
    background-color: #517fa4 !important;
}
.btn-pinterest  {
    color: $white !important;
    background-color: #cb2027 !important;
}
.btn-dropbox  {
    color: $white !important;
    background-color: #007ee5 !important;
}
.btn-flickr  {
    color: $white !important;
    background-color: #ff0084 !important;
}
.btn-tumblr  {
    color: $white !important;
    background-color: #32506d !important;
}
.btn-skype {
    color: $white !important;
    background-color: #00aff0 !important;
}
.btn-youtube  {
    color: $white !important;
    background-color: #bb0000 !important;
}
.btn-github  {
    color: $white !important;
    background-color: #171515 !important;
}
