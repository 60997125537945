.card {
  &.card-focus {
    border: 0;
    height: 100%;
    border-radius: 13px;
    box-shadow: 0 4px 20px 1px rgba(0, 88, 123, 0.16);

    //&:hover {
    //  box-shadow: 0 3px 25px 1px rgba(49, 148, 187, 0.36);
    //}

    i {
      color: #00587b;
      margin-top: 20px;
    }

    .card-title {
      color: #00587b;
      font-size: 20px;
      margin-top: 12px;
      font-weight: bold;
    }

    &.card-style-dark.bg-white {
      color: #333;
    }
  }

  .card-absolute-footer {
    right: 0;
    bottom: 0;
    position: absolute;
  }

  .card-footer-absolute {
    margin-top: 32px;

    .btn {
      right: 25px;
      bottom: 25px;
      position: absolute;
    }
  }

  .card-footer {
    border-top: 0;
    border-radius: 0 0 13px 13px;
    background-color: rgb(234, 234, 234);
  }

  .card-body {
    .card-footer {
      border-radius: 8px;
      background-color: rgba(0,0,0,.03);
    }
  }

  &.card-newsitem {
    margin-bottom: 25px;

    .bg-image {
      height: 200px;
      border-radius: 17px;
    }

    .card-content {
      //height: 87px;
    }

    .card-title {
      min-height: 96px;
      font-weight: bold;
    }
  }

  &.card-productitem {
    margin-bottom: 25px;

    .bg-image {
      height: 200px;
      border-radius: 17px;
      background: center/contain no-repeat;
    }

    .card-content {
      height: 87px;
    }

    .card-title {
      font-weight: bold;
    }

    //.card-text {
    //  min-height: 78px
    //}
  }

  &.card-style-image {
    width: 100%;
    margin-bottom: 30px;
  }

  .filters {
    color: #00587b;
    //padding: 6px 0;
    //border-top: 1px solid #9A9A9A;
    dt {
      font-size: 15px;
      line-height: 22px;
      padding-left: 9px;
      width: calc(100% - 100px);
    }

    dd {
      width: 100px;
    }

    .stars>.fa-star {
      font-size: 9px;
    }

    @media (min-width: 576px) and (max-width: 767px) {
      dt {
        font-size: 13px;
        line-height: 26px;
      }
    }
  }

  &[class*="card-style-"] {
    border: none;
    //height: 100%;
    //margin-top: 60px;
    border-radius: 13px;

    .card-title {
      font-size: 20px;
      margin-top: 12px;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 22px;
    }

    .card-text {
      font-size: 14px;
      margin-top: 0;//14px;
      line-height: 26px;
      font-weight: 500;

      + .card-title {
        margin-top: 14px;
      }

      p {
        margin-bottom: 0;
      }

      img {
        max-width: 100%;
      }

      h1 + p,
      h2 + p,
      h3 + p,
      h4 + p,
      h5 + p,
      h6 + p,
      p + p {
        margin-top: 1rem;
      }
    }

    &.card-style-image {
      border-radius: 13px;
      background-color: transparent;

      img {
        border-radius: 13px;
      }
    }

    //&.card-style-default {
    .card-body {
      padding: 24px;
    }
    //}

    .btn {
      font-size: 13px;
      line-height: 26px;
      border-radius: 10px;

      i {
        margin-left: 13px;
      }
    }

    &.card-style-default {
      .card-title {
        font-size: 21px;
        margin-top: 3px;
        font-weight: 500;
        line-height: 1.4;
      }
    }

    &.card-style-dark {
      color: #ffffff;
      background: #434343;
    }

    &.card-style-footer {
      color: #3B3B3B;
      margin-top: -93px;
      //margin-bottom: 92px;
      background: transparent;
      //border: 6px solid rgba(244, 244, 244, 0.33);

      .card-title {
        margin-top: 0;
      }

      .card-body {
        position: relative;
        border-radius: 13px;
        background-color: #F4F4F4;
        //border: 6px solid rgba(244, 244, 244, 0.33);

        > * {
          z-index: 2;
          position: relative;
        }

        &:after {
          top: -6px;
          left: -6px;
          z-index: 1;
          content: "";
          position: absolute;
          border-radius: 19px;
          width: calc(100% + 12px);
          height: calc(100% + 12px);
          border: 6px solid rgba(244, 244, 244, 0.33);
        }
      }
    }
  }

  + .card {
    margin-top: 20px;
  }
}

/* Special */
.card-footer-bg {
  height: 335px;
}
