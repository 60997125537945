/* =========== */
/* Sweet Alert */
/* =========== */

.sweet-alert {
  padding: 30px 25px;
  p {
    font-size: 15px;
    line-height: 24px;
    font-weight: normal;
  }
  p:last-of-type {
    margin-bottom: 0 !important;
  }
  .icon.success  {
    .placeholder {
      border: 4px solid rgba($success,0.3);
    }
    .line {
      background-color: $success;
    }
  }

  .icon.error  {
    border-color: $danger;

    .line {
      background-color: $danger;
    }
  }

  .icon.warning {
    border-color: $warning;
  }
  .icon.info {
    border-color: $info;
  }
  .btn-warning:focus,.btn-info:focus,.btn-success:focus,.btn-danger:focus,.btn-default:focus {
    box-shadow: none;
  }
}