.container-element {
  ul {
    li {
      border: none;
    }
  }

  .column {
    min-height: 100px;
    border: 2px dashed #d1d7db;
  }

  .row-preset {
    width: 40px;
    height: 40px;
    padding: 4px;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 3px;
    display: inline-block;
    vertical-align: bottom;
    background-color: #d4d4d4a6;

    > div {
      height: 100%;
      border-radius: 1px;
      display: inline-block;
      background-color: #3b7694;
      border-left: 1px solid #d4d4d4a6;
      border-right: 1px solid #d4d4d4a6;

      &:first-of-type {
        border-left: none;
      }
    }

    .row-preset-12 {
      width: 100%;
    }

    .row-preset-9 {
      width: 75%;
    }

    .row-preset-6 {
      width: 50%;
    }

    .row-preset-4 {
      width: 33.3333333%;
    }

    .row-preset-3 {
      width: 25%;
    }

    .row-preset-2 {
      width: 16.6666667%;
    }
  }
}

.page-builder-drag-item {
  padding: 5px;
  cursor: grab;
  display: block;
  margin-bottom: 3px;

  &:hover {
    background-color: #f3f3f3;
  }
}

.row-items-list {
  z-index: 2;
  padding-bottom: 61px;

  .card-box {
    padding: 0.75rem;
  }

  .row-item:not(:last-of-type) {
    margin-bottom: 20px;
  }

  &+ .no-items-placeholder {
    margin-top: -46px;
  }
}

.no-items-placeholder {
  z-index: 1;
  width: 100%;
  padding: 10px;
  display: block;
  border-radius: 3px;
  position: absolute;
  border: 2px dashed #d1d7db;
}

.widget-item {
  .error {
    color: red;
  }

  .card {
    .card-heading {
      cursor: pointer;

      .item-title {
        text-overflow: ellipsis;
        vertical-align: middle;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100px;
      }
    }

    .btn-remove-item {
      top: 4px;
      right: 13px;
      position: absolute;
    }

    .card-collapse.collapse.in {
      border-bottom: 1px solid grey;
      border-radius: 2px;
    }
  }
}


















//.nestable-pages {
//  &[data-nestable-type="public"] {
//    .btn-set-homepage.is-private[data-confirm="true"] {
//      display: none;
//    }
//  }
//
//  &[data-nestable-type="private"] {
//    .btn-set-homepage[data-confirm="true"]:not(.is-private) {
//      display: none;
//    }
//  }
//}
//
//.dd-item {
//  .fa-homepage-clear {
//    width: 10px;
//    height: 0;
//    line-height: 1.6em;
//    vertical-align: top;
//    font-size: 12px;
//  }
//}
//
//.dd-dragel {
//  .btn-set-homepage[data-confirm="true"] {
//    display: none;
//  }
//}

body ul[dnd-list] {
  list-style: none;



  position: relative;
  min-height: 66px;
  padding-left: 0;

  &.widget-dropzone-list {
    > li {
      margin-bottom: 10px;
      position: relative;
      padding-left: 0;
      display: block;
    }

    .dndDraggingSource {
      display: none;
    }

    .dndPlaceholder {
      background-color: #dddddd;
      position: relative;
      min-height: 42px;
      display: block;
    }

    .dndDragging {
      opacity: 0.7;
    }
  }
}

.widget-drag-item {
  border: 1px solid #dddddd;
  margin-bottom: 2px;
  padding: 5px;
  cursor: move;
  > span {
    > .text-hover {
      display: none;
    }
  }

  &:hover {
    background-color: #f2f4f8;

    > span {
      > .text-hover {
        display: inline-block;
      }
      > .text {
        opacity: 0;
      }
    }
  }
}





ul[dnd-list] {
  padding-left: 0;
  position: relative;

  &.widget-dropzone-list {
    > li {
      margin-bottom: 10px;
      position: relative;
      padding-left: 0;
      display: block;
    }

    .dndDraggingSource {
      display: none;
    }

    .dndPlaceholder {
      background-color: #dddddd;
      position: relative;
      min-height: 42px;
      display: block;
    }

    .dndDragging {
      opacity: 0.7;

      .drag-hide {
        opacity: 0;
      }
    }
  }

  &.assignment-deliverables-dropzone-list,
  &.assignment-submit-deliverables-dropzone-list {
    &:not(.dnd-list-empty) {
      min-height: 66px;
    }
  }

  &.assignment-submit-deliverables-dropzone-list {
    z-index: 10;

    &.dndDragover {
      z-index: 12;
    }

    .dndPlaceholder {
      min-height: 60px;
    }

    > li {
      margin-bottom: 0;
      background: #fff;
    }
  }
}


.widget-drag-item {
  border: 1px solid #dddddd;
  margin-bottom: 2px;
  padding: 5px;
  cursor: move;
  > span {
    > .text-hover {
      display: none;
    }
  }

  &:hover {
    background-color: #f2f4f8;

    > span {
      > .text-hover {
        display: inline-block;
      }
      > .text {
        opacity: 0;
      }
    }
  }
}
