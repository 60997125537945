/* =============
   Calendar
============= */


.calendar {
  float: left;
  margin-bottom: 0px;
}

.fc-view {
  margin-top: 30px;
}
.none-border {
  .modal-footer {
    border-top: none;
  }
}

.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px;
  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
  }
}

.fc-day {
  background: $white;
}

.fc-title,.fc-time {
  color: $white;
}

.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active,
.fc-toolbar button:focus, .fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}


.fc {
  th.fc-widget-header {
    background: $gray-lighter;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0px;
    text-transform: uppercase;
  }
}
.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-popover {
    border-color: $gray-lighter;
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: $gray-light;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: $gray-lighter;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-state-down, .fc-state-active,.fc-state-disabled {
  background-color: $custom !important;
  color: $white !important;
  text-shadow: none !important;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed {
  .fc-today {
    background: $white;
  }
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.external-event {
  color: $white;
  cursor: move;
  margin: 10px 0;
  padding: 6px 10px;
}

.fc-basic-view {
  td.fc-week-number {
    span {
      padding-right: 8px;
      font-weight: 700;
    }
  }
  td.fc-day-number {
    padding-right: 8px;
    font-weight: 700;
  }
}

