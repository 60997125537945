@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,700");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary, .btn-main {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover, .btn-main:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-main:focus, .btn-primary.focus, .focus.btn-main {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .disabled.btn-main, .btn-primary:disabled, .btn-main:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-main:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-main:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.show > .dropdown-toggle.btn-main {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-main:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-main:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.show > .dropdown-toggle.btn-main:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark, .btn-main-2 {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover, .btn-main-2:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-main-2:focus, .btn-dark.focus, .focus.btn-main-2 {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .disabled.btn-main-2, .btn-dark:disabled, .btn-main-2:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-main-2:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .btn-main-2:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle,
.show > .dropdown-toggle.btn-main-2 {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-main-2:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .btn-main-2:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus,
.show > .dropdown-toggle.btn-main-2:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80bdff;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  appearance: none;
}

.custom-range::-webkit-slider-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  appearance: none;
}

.custom-range::-moz-range-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  appearance: none;
}

.custom-range::-ms-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-custom {
  color: #64b0f2;
}

.text-primary {
  color: #039cfd !important;
}

.text-success {
  color: #1bb99a !important;
}

.text-info {
  color: #3db9dc !important;
}

.text-warning {
  color: #f1b53d !important;
}

.text-danger {
  color: #ff5d48 !important;
}

.text-purple {
  color: #9261c6;
}

.text-pink {
  color: #ff7aa3;
}

.text-dark {
  color: #2b3d51;
}

.text-white {
  color: #ffffff;
}

.text-muted {
  color: #818a91;
}

.dropdown-lg {
  width: 280px;
}

.dropdown-menu {
  box-shadow: 0 0px 24px 0 rgba(43, 61, 81, 0.06), 0 1px 0px 0 rgba(43, 61, 81, 0.02);
  border: 1px solid rgba(43, 61, 81, 0.05);
}

html {
  position: relative;
  min-height: 100%;
}

body {
  font-size: 0.9rem;
  background-color: #f5f5f5;
  padding-bottom: 60px;
}

.page-title-box {
  background-color: #ffffff;
  margin: -20px -20px 20px -20px;
  padding: 20px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.page-title-box .page-title {
  margin-bottom: 0;
  font-size: 1.2rem;
}

.page-title-box .breadcrumb {
  padding: 0;
  background-color: transparent !important;
}

.card-box {
  padding: 20px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.header-title {
  font-size: 1rem;
  text-transform: uppercase;
}

.page-header {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 1.15rem;
  padding-bottom: 10px;
  border-bottom: 1px solid #eceeef;
}

.button-list {
  margin-bottom: -5px;
  margin-left: -7px;
}

.button-list button, .button-list a {
  margin: 5px 7px;
}

canvas {
  max-width: 100%;
}

svg {
  max-width: 100%;
}

a {
  outline: none !important;
  text-decoration: none !important;
}

.row {
  margin-right: -10px;
  margin-left: -10px;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9 {
  padding-right: 10px;
  padding-left: 10px;
}

.bg-primary {
  background-color: #039cfd !important;
}

.bg-success {
  background-color: #1bb99a !important;
}

.bg-info {
  background-color: #3db9dc !important;
}

.bg-warning {
  background-color: #f1b53d !important;
}

.bg-danger {
  background-color: #ff5d48 !important;
}

.bg-purple {
  background-color: #9261c6 !important;
}

.bg-pink {
  background-color: #ff7aa3 !important;
}

.bg-dark {
  background-color: #2b3d51 !important;
}

.nav-pills .nav-item.show .nav-link, .nav-pills .nav-link.active {
  background-color: #039cfd;
}

.dropdown-item {
  font-size: 0.9rem;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
  background-color: rgba(255, 255, 255, 0.45);
}

.waves-effect.waves-red .waves-ripple {
  background-color: rgba(244, 67, 54, 0.7);
}

.waves-effect.waves-yellow .waves-ripple {
  background-color: rgba(255, 235, 59, 0.7);
}

.waves-effect.waves-orange .waves-ripple {
  background-color: rgba(255, 152, 0, 0.7);
}

.waves-effect.waves-purple .waves-ripple {
  background-color: rgba(156, 39, 176, 0.7);
}

.waves-effect.waves-green .waves-ripple {
  background-color: rgba(76, 175, 80, 0.7);
}

.waves-effect.waves-teal .waves-ripple {
  background-color: rgba(0, 150, 136, 0.7);
}

.waves-notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

.waves-circle {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}

.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.waves-block {
  display: block;
}

/* IE9, IE10, IE11 */
@media screen and (min-width: 0\0) {
  .form-control {
    padding: 0.55rem 0.75rem !important;
  }
  .input-group-addon {
    line-height: inherit !important;
    font-size: 1rem !important;
  }
  .carousel-control span {
    right: 20px;
  }
}

.manager {
  /*
Template Name:
Author: CoderThemesUplon Dashboard
Email: coderthemes@gmail.com
File: Menu
*/
  /* Notification */
  /* Right sidebar */
  /* Seach */
  /* Body min-height set */
}

.manager .topbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.manager .topbar .topbar-left {
  background: #ffffff;
  float: left;
  text-align: center;
  height: 70px;
  position: relative;
  width: 250px;
  z-index: 1;
}

.manager .topbar .topbar-left .logo {
  line-height: 70px;
}

.manager .navbar-custom {
  background-color: #046C9F;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 10px;
  margin-left: 250px;
  min-height: 70px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.manager .navbar-custom .nav-link {
  padding: 0;
  line-height: 70px;
  color: rgba(255, 255, 255, 0.6);
}

.manager .navbar-custom .dropdown-toggle:after {
  content: initial;
}

.manager .navbar-custom .menu-left {
  overflow: hidden;
}

.manager .logo {
  color: #2b3d51 !important;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.manager .logo span span {
  color: #64b0f2;
}

.manager .user-box {
  text-align: center;
  padding: 30px 0 20px 0;
}

.manager .user-box .user-img {
  position: relative;
  height: 88px;
  width: 88px;
  margin: 0 auto;
}

.manager .user-box h5 a {
  color: #373a3c;
}

.manager .user-box .user-status {
  height: 12px;
  width: 12px;
  position: absolute;
  bottom: 7px;
  right: 15px;
}

.manager .user-box .user-status i {
  font-size: 15px;
}

.manager .user-box .user-status.away i {
  color: #f1b53d;
}

.manager .user-box .user-status.offline i {
  color: #ff5d48;
}

.manager .user-box .user-status.online i {
  color: #1bb99a;
}

.manager .user-box .user-status.busy i {
  color: #373a3c;
}

.manager .user-box ul li a {
  color: #373a3c;
}

.manager .user-box ul li a:hover {
  color: #64b0f2;
}

.manager .side-menu {
  top: 70px;
  width: 250px;
  z-index: 10;
  background: #ffffff;
  bottom: 70px;
  margin-bottom: -70px;
  margin-top: 0;
  padding-bottom: 70px;
  position: absolute;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.manager .content-page {
  margin-left: 250px;
  overflow: hidden;
}

.manager .content-page .content {
  padding: 0 5px;
  margin-top: 73px;
}

.manager .side-menu.left {
  position: absolute;
}

.manager body.fixed-left .side-menu.left {
  bottom: 50px;
  margin-bottom: -70px;
  margin-top: 0;
  padding-bottom: 70px;
  position: fixed;
}

.manager .button-menu-mobile {
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 21px;
  line-height: 70px;
  padding: 0 15px;
  margin-right: 10px;
}

.manager .button-menu-mobile:hover {
  color: #64b0f2;
}

.manager .sidebar-inner {
  height: 100%;
}

.manager #sidebar-menu, .manager #sidebar-menu ul, .manager #sidebar-menu li, .manager #sidebar-menu a {
  border: 0;
  font-weight: normal;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

.manager #sidebar-menu {
  padding-bottom: 30px;
  width: 100%;
}

.manager #sidebar-menu a {
  line-height: 1.3;
}

.manager #sidebar-menu ul li .menu-arrow {
  -webkit-transition: -webkit-transform .15s;
  -o-transition: -o-transform .15s;
  transition: transform .15s;
  position: absolute;
  right: 20px;
  top: 16px;
  display: inline-block;
  font-family: 'Material-Design-Iconic-Font';
  text-rendering: auto;
  line-height: 18px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.manager #sidebar-menu ul li .menu-arrow:before {
  content: '\f2fb';
}

.manager #sidebar-menu ul li a.subdrop .menu-arrow {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.manager #sidebar-menu ul ul {
  display: none;
}

.manager #sidebar-menu ul ul li {
  border-top: 0;
}

.manager #sidebar-menu ul ul li.active a {
  color: #64b0f2;
}

.manager #sidebar-menu ul ul a {
  color: rgba(43, 61, 81, 0.8);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  display: block;
  padding: 10px 20px 10px 60px;
}

.manager #sidebar-menu ul ul a:hover {
  color: #2b3d51;
}

.manager #sidebar-menu ul ul a i {
  margin-right: 5px;
}

.manager #sidebar-menu ul ul ul a {
  padding-left: 80px;
}

.manager #sidebar-menu .label {
  margin-top: 2px;
}

.manager #sidebar-menu .subdrop {
  color: #2b3d51 !important;
}

.manager #sidebar-menu .has_sub ul .has_sub .menu-arrow {
  top: 10px !important;
}

.manager #sidebar-menu > ul > li > a {
  color: rgba(43, 61, 81, 0.8);
  display: block;
  padding: 14px 20px;
  font-weight: 500;
  font-size: 0.95rem;
}

.manager #sidebar-menu > ul > li > a:hover {
  color: #2b3d51;
  text-decoration: none;
}

.manager #sidebar-menu > ul > li > a > span {
  vertical-align: middle;
}

.manager #sidebar-menu ul li a i {
  display: inline-block;
  font-size: 18px;
  line-height: 17px;
  margin-left: 3px;
  margin-right: 15px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
}

.manager #sidebar-menu ul li a .drop-arrow {
  float: right;
}

.manager #sidebar-menu ul li a .drop-arrow i {
  margin-right: 0;
}

.manager #sidebar-menu > ul > li > a.active {
  color: #ffffff !important;
  background-color: #64b0f2;
}

.manager .menu-title {
  padding: 12px 20px !important;
  letter-spacing: .035em;
  pointer-events: none;
  cursor: default;
  font-size: 13px;
}

.manager .footer {
  border-top: 1px solid rgba(152, 166, 173, 0.2);
  bottom: 0;
  color: #818a91;
  text-align: left !important;
  padding: 19px 30px 20px;
  position: absolute;
  right: 0;
  left: 250px;
}

.manager .content-page > .content {
  margin-top: 70px;
  padding: 20px 5px 15px 5px;
}

.manager #wrapper.enlarged .menu-title {
  display: none;
}

.manager #wrapper.enlarged .menu-arrow {
  display: none !important;
}

.manager #wrapper.enlarged .footer {
  left: 70px;
}

.manager #wrapper.enlarged #sidebar-menu ul ul {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 10px 0 rgba(0, 0, 0, 0.08);
}

.manager #wrapper.enlarged .left.side-menu {
  width: 70px;
  z-index: 5;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a {
  padding-left: 10px;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a:hover {
  background-color: #64b0f2;
}

.manager #wrapper.enlarged .left.side-menu span.pull-right {
  display: none !important;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu ul > li {
  position: relative;
  white-space: nowrap;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > a {
  background-color: #64b0f2;
  position: relative;
  width: 260px;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > ul {
  display: block;
  left: 70px;
  position: absolute;
  width: 190px;
  padding: 5px 0;
  background: #ffffff;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > ul a {
  background: #ffffff;
  border: none;
  box-shadow: none;
  padding-left: 15px;
  position: relative;
  width: 190px;
  z-index: 6;
  color: rgba(43, 61, 81, 0.8);
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > ul a:hover {
  color: #64b0f2;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover a span {
  display: inline;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu ul > li > ul {
  display: none;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu ul ul li:hover > ul {
  display: block;
  left: 190px;
  margin-top: -36px;
  position: absolute;
  width: 190px;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu ul ul li > a span.pull-right {
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  position: absolute;
  right: 20px;
  top: 12px;
  transform: rotate(270deg);
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu ul > li > a span {
  display: none;
  padding-left: 10px;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a {
  padding: 15px 20px;
  min-height: 52.09px;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a i {
  margin-right: 20px !important;
  font-size: 20px;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > a {
  position: relative;
  width: 260px;
  background-color: #64b0f2;
  color: #ffffff;
}

.manager #wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > a i {
  color: #ffffff;
}

.manager #wrapper.enlarged .content-page {
  margin-left: 70px;
}

.manager #wrapper.enlarged .navbar-custom {
  margin-left: 70px;
}

.manager #wrapper.enlarged .topbar .topbar-left {
  width: 70px !important;
}

.manager #wrapper.enlarged .topbar .topbar-left .logo span {
  display: none;
  opacity: 0;
}

.manager #wrapper.enlarged .topbar .topbar-left .logo i {
  margin-right: 0;
}

.manager #wrapper.enlarged #sidebar-menu > ul > li:hover > a.open :after {
  display: none;
}

.manager #wrapper.enlarged #sidebar-menu > ul > li:hover > a.active :after {
  display: none;
}

.manager #wrapper.enlarged .user-detail {
  bottom: 0;
  padding: 15px 0;
  width: 70px;
  text-align: center;
}

.manager #wrapper.enlarged .user-detail .dropup {
  margin: 0 auto;
  margin-left: 17px;
}

.manager #wrapper.enlarged .user-detail h5 {
  display: none;
}

.manager #wrapper.enlarged .user-detail p {
  position: absolute;
  right: 15px;
  top: 32px;
}

.manager #wrapper.enlarged .user-detail p span {
  display: none;
}

.manager #wrapper.enlarged #sidebar-menu ul ul li.active a {
  color: #039cfd;
}

.manager #wrapper.right-bar-enabled .right-bar {
  right: 0;
}

.manager #wrapper.right-bar-enabled .left-layout {
  left: 0;
}

.manager .notification-list {
  margin-left: 0 !important;
}

.manager .notification-list .noti-title {
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: #64b0f2;
  margin: -6px -1px 0px -1px;
  width: auto;
  padding: 12px 20px;
}

.manager .notification-list .noti-title h5 {
  color: #ffffff;
  margin: 0;
}

.manager .notification-list .noti-title .label {
  float: right;
}

.manager .notification-list .noti-icon {
  font-size: 22px;
  padding: 0 12px;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.8);
}

.manager .notification-list .noti-icon-badge {
  height: 10px;
  width: 10px;
  background-color: #ff5d48;
  display: inline-block;
  position: absolute;
  top: 23px;
  right: 12px;
  border-radius: 50%;
  border: 2px solid #2b3d51;
}

.manager .notification-list .notify-item {
  padding: 10px 20px;
}

.manager .notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
  color: #ffffff;
}

.manager .notification-list .notify-item .notify-icon img {
  margin-top: 4px;
}

.manager .notification-list .notify-item .notify-details {
  margin-bottom: 0;
  overflow: hidden;
  margin-left: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.manager .notification-list .notify-item .notify-details b {
  font-weight: 500;
}

.manager .notification-list .notify-item .notify-details small {
  display: block;
}

.manager .notification-list .notify-item .notify-details span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}

.manager .notification-list .notify-all {
  border-radius: 0 0 0.25rem 0.25rem;
  margin: 0 0 -5px 0;
  background-color: #eceeef;
}

.manager .profile-dropdown {
  width: 170px;
}

.manager .profile-dropdown i {
  font-size: 17px;
  vertical-align: middle;
  margin-right: 5px;
}

.manager .profile-dropdown span {
  vertical-align: middle;
}

.manager .nav-user {
  padding: 0 12px !important;
}

.manager .nav-user img {
  height: 36px;
  width: 36px;
}

.manager .side-bar.right-bar {
  float: right !important;
  right: -266px;
  top: 71px;
}

.manager .side-bar {
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  background-color: #ffffff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  display: block;
  float: left;
  height: 100%;
  position: fixed;
  transition: all 200ms ease-out;
  width: 240px;
  z-index: 9;
}

.manager .right-bar {
  background: #ffffff !important;
}

.manager .right-bar .nav-tabs {
  border: none !important;
}

.manager .right-bar .nav-tabs .nav-item {
  float: none;
  display: inline-block;
  margin-bottom: 0;
}

.manager .right-bar .nav-tabs .nav-link {
  border: none !important;
  font-weight: 500;
  font-size: 16px;
  color: #2b3d51;
}

.manager .right-bar .nav-tabs .nav-link.active {
  color: #64b0f2;
  background-color: transparent;
}

.manager .right-bar .tab-content {
  padding: 20px;
}

.manager .right-bar .right-bar-toggle {
  float: right;
  line-height: 46px;
  font-size: 20px;
  color: #333;
  padding: 0 10px;
}

.manager .right-bar .nicescroll {
  height: 88%;
}

.manager .right-bar .contact-list {
  max-height: 600px;
}

.manager .right-bar h5 {
  font-size: 15px;
}

.manager .app-search {
  position: relative;
  padding-top: 18px;
}

.manager .app-search a {
  position: absolute;
  top: 18px;
  left: 215px;
  display: block;
  height: 34px;
  line-height: 34px;
  width: 34px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.manager .app-search a:hover {
  color: #ffffff;
}

.manager .app-search .form-control,
.manager .app-search .form-control:focus {
  border: 1px solid rgba(129, 138, 145, 0.3);
  font-size: 13px;
  height: 34px;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 40px;
  background: rgba(129, 138, 145, 0.1);
  box-shadow: none;
  border-radius: 30px;
  width: 200px;
}

@media (max-width: 768px) {
  .manager body {
    overflow-x: hidden;
  }
  .manager .enlarged .left.side-menu {
    margin-left: -75px;
  }
  .manager .topbar-left {
    width: 70px !important;
  }
  .manager .topbar-left span {
    display: none !important;
  }
  .manager .topbar-left i {
    display: block !important;
    line-height: 75px !important;
  }
  .manager .navbar-custom {
    margin-left: 70px !important;
  }
  .manager .content-page {
    margin-left: 0 !important;
  }
  .manager .footer {
    left: 0 !important;
  }
  .manager .mobile-sidebar {
    left: 0;
  }
  .manager .mobile-content {
    left: 250px;
    right: -250px;
  }
  .manager .wrapper-page {
    width: 90%;
  }
  .manager .dataTables_wrapper .col-xs-6 {
    width: 100%;
    text-align: left;
  }
  .manager div#datatable-buttons_info {
    float: none;
  }
  .manager .ms-container {
    width: 100%;
  }
  .manager .navbar-toggleable-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (max-width: 767px) {
  .manager .dropdown-lg {
    width: 200px;
  }
  .manager .notification-list .notify-item {
    padding: 10px 15px;
  }
  .manager .page-title-box .page-title, .manager .page-title-box .breadcrumb {
    float: none;
  }
}

@media (max-width: 480px) {
  .manager .notification-list .noti-icon {
    font-size: 18px;
    padding: 0 10px;
  }
  .manager .hidden-mobile {
    display: none !important;
  }
}

.manager body.fixed-left-void {
  min-height: 1170px;
}

.manager .mobile.smallscreen .sidebar-inner.slimscrollleft {
  overflow: scroll !important;
}

button:focus {
  outline: none !important;
}

a.btn:focus {
  color: #ffffff !important;
}

.btn-rounded {
  border-radius: 2em !important;
  padding: .5rem 1.5rem;
}

.btn-label {
  background: rgba(43, 61, 81, 0.1);
  display: inline-block;
  padding: 7px 15px;
  border-radius: 3px 0 0 3px;
  margin: -7px -14px;
  margin-right: 12px;
}

.btn-rounded .btn-label {
  padding: 7px 15px 7px 20px;
  margin-left: -20px;
}

.btn-rounded .btn-label-right {
  margin-right: -20px;
  margin-left: 12px;
}

.btn-label-right {
  margin-left: 12px;
  margin-right: -13px;
  border-radius: 0px 3px 3px 0px;
}

.btn-custom {
  background-color: #64b0f2;
  border-color: #64b0f2;
  color: #ffffff;
}

.btn-custom:hover, .btn-custom:focus, .btn-custom:active, .btn-custom.active,
.btn-custom.focus, .btn-custom:active, .btn-custom:focus, .btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
  background-color: #439fef;
  border-color: #439fef;
  color: #ffffff;
}

.btn-primary, .btn-main {
  background-color: #039cfd;
  border-color: #039cfd;
}

.btn-primary:hover, .btn-main:hover, .btn-primary:focus, .btn-main:focus, .btn-primary:active, .btn-main:active, .btn-primary.active, .active.btn-main, .btn-primary.focus, .focus.btn-main, .btn-primary:active, .btn-main:active, .btn-primary:focus, .btn-main:focus, .btn-primary:hover, .btn-main:hover,
.open > .dropdown-toggle.btn-primary,
.open > .dropdown-toggle.btn-main {
  background-color: #0283d6;
  border-color: #0283d6;
  color: #ffffff;
}

.btn-success {
  background-color: #1bb99a;
  border-color: #1bb99a;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active,
.btn-success.focus, .btn-success:active, .btn-success:focus, .btn-success:hover,
.open > .dropdown-toggle.btn-success {
  background-color: #16957c;
  border-color: #16957c;
  color: #ffffff;
}

.btn-info {
  background-color: #3db9dc;
  border-color: #3db9dc;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active,
.btn-info.focus, .btn-info:active, .btn-info:focus, .btn-info:hover,
.open > .dropdown-toggle.btn-info {
  background-color: #25a7cb;
  border-color: #25a7cb;
  color: #ffffff;
}

.btn-warning {
  background-color: #f1b53d;
  border-color: #f1b53d;
  color: #ffffff;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active,
.btn-warning.focus, .btn-warning:active, .btn-warning:focus, .btn-warning:hover,
.open > .dropdown-toggle.btn-warning {
  background-color: #eea617;
  border-color: #eea617;
  color: #ffffff;
}

.btn-danger {
  background-color: #ff5d48;
  border-color: #ff5d48;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active,
.btn-danger.focus, .btn-danger:active, .btn-danger:focus, .btn-danger:hover,
.open > .dropdown-toggle.btn-danger {
  background-color: #ff391f;
  border-color: #ff391f;
  color: #ffffff;
}

.btn-dark, .btn-main-2 {
  background-color: #2b3d51;
  border-color: #2b3d51;
  color: #ffffff;
}

.btn-dark:hover, .btn-main-2:hover, .btn-dark:focus, .btn-main-2:focus, .btn-dark:active, .btn-main-2:active, .btn-dark.active, .active.btn-main-2, .btn-dark.focus, .focus.btn-main-2, .btn-dark:active, .btn-main-2:active, .btn-dark:focus, .btn-main-2:focus, .btn-dark:hover, .btn-main-2:hover,
.open > .dropdown-toggle.btn-dark,
.open > .dropdown-toggle.btn-main-2 {
  background-color: #1d2936;
  border-color: #1d2936;
  color: #ffffff;
}

.btn-pink {
  background-color: #ff7aa3;
  border-color: #ff7aa3;
  color: #ffffff;
}

.btn-pink:hover, .btn-pink:focus, .btn-pink:active, .btn-pink.active,
.btn-pink.focus, .btn-pink:active, .btn-pink:focus, .btn-pink:hover,
.open > .dropdown-toggle.btn-pink {
  background-color: #ff5187;
  border-color: #ff5187;
  color: #ffffff;
}

.btn-purple {
  background-color: #9261c6;
  border-color: #9261c6;
  color: #ffffff;
}

.btn-purple:hover, .btn-purple:focus, .btn-purple:active, .btn-purple.active,
.btn-purple.focus, .btn-purple:active, .btn-purple:focus, .btn-purple:hover,
.open > .dropdown-toggle.btn-purple {
  background-color: #7d43bb;
  border-color: #7d43bb;
  color: #ffffff;
}

.btn-outline-custom {
  color: #64b0f2;
  background-image: none;
  background-color: transparent;
  border-color: #64b0f2;
}

.btn-outline-custom:hover, .btn-outline-custom:focus, .btn-outline-custom:active, .btn-outline-custom.active,
.btn-outline-custom.focus, .btn-outline-custom:active, .btn-outline-custom-primary:focus, .btn-outline-custom:hover,
.open > .dropdown-toggle.btn-outline-custom {
  color: #ffffff;
  background-color: #64b0f2;
  border-color: #64b0f2;
}

.btn-outline-primary {
  color: #039cfd;
  background-image: none;
  background-color: transparent;
  border-color: #039cfd;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active,
.btn-outline-primary.focus, .btn-outline-primary:active, .btn-outline-primary:focus, .btn-outline-primary:hover,
.open > .dropdown-toggle.btn-outline-primary {
  color: #ffffff;
  background-color: #039cfd;
  border-color: #039cfd;
}

.btn-outline-success {
  color: #1bb99a;
  background-image: none;
  background-color: transparent;
  border-color: #1bb99a;
}

.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active,
.btn-outline-success.focus, .btn-outline-success:active, .btn-outline-success:focus, .btn-outline-success:hover,
.open > .dropdown-toggle.btn-outline-success {
  color: #ffffff;
  background-color: #1bb99a;
  border-color: #1bb99a;
}

.btn-outline-info {
  color: #3db9dc;
  background-image: none;
  background-color: transparent;
  border-color: #3db9dc;
}

.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active,
.btn-outline-info.focus, .btn-outline-info:active, .btn-outline-info:focus, .btn-outline-info:hover,
.open > .dropdown-toggle.btn-outline-info {
  color: #ffffff;
  background-color: #3db9dc;
  border-color: #3db9dc;
}

.btn-outline-warning {
  color: #f1b53d;
  background-image: none;
  background-color: transparent;
  border-color: #f1b53d;
}

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active,
.btn-outline-warning.focus, .btn-outline-warning:active, .btn-outline-warning:focus, .btn-outline-warning:hover,
.open > .dropdown-toggle.btn-outline-warning {
  color: #ffffff;
  background-color: #f1b53d;
  border-color: #f1b53d;
}

.btn-outline-danger {
  color: #ff5d48;
  background-image: none;
  background-color: transparent;
  border-color: #ff5d48;
}

.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active,
.btn-outline-danger.focus, .btn-outline-danger:active, .btn-outline-danger:focus, .btn-outline-danger:hover,
.open > .dropdown-toggle.btn-outline-danger {
  color: #ffffff;
  background-color: #ff5d48;
  border-color: #ff5d48;
}

.btn-outline-pink {
  color: #ff7aa3;
  background-image: none;
  background-color: transparent;
  border-color: #ff7aa3;
}

.btn-outline-pink:hover, .btn-outline-pink:focus, .btn-outline-pink:active, .btn-outline-pink.active,
.btn-outline-pink.focus, .btn-outline-pink:active, .btn-outline-pink:focus, .btn-outline-pink:hover,
.open > .dropdown-toggle.btn-outline-pink {
  color: #ffffff;
  background-color: #ff7aa3;
  border-color: #ff7aa3;
}

.btn-outline-purple {
  color: #9261c6;
  background-image: none;
  background-color: transparent;
  border-color: #9261c6;
}

.btn-outline-purple:hover, .btn-outline-purple:focus, .btn-outline-purple:active, .btn-outline-purple.active,
.btn-outline-purple.focus, .btn-outline-purple:active, .btn-outline-purple:focus, .btn-outline-purple:hover,
.open > .dropdown-toggle.btn-outline-purple {
  color: #ffffff;
  background-color: #9261c6;
  border-color: #9261c6;
}

.btn-outline-dark {
  color: #2b3d51;
  background-image: none;
  background-color: transparent;
  border-color: #2b3d51;
}

.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active,
.btn-outline-dark.focus, .btn-outline-dark:active, .btn-outline-dark:focus, .btn-outline-dark:hover,
.open > .dropdown-toggle.btn-outline-dark {
  color: #ffffff;
  background-color: #2b3d51;
  border-color: #2b3d51;
}

/* Social Buttons */
.btn-facebook {
  color: #ffffff !important;
  background-color: #3b5998 !important;
}

.btn-twitter {
  color: #ffffff !important;
  background-color: #00aced !important;
}

.btn-linkedin {
  color: #ffffff !important;
  background-color: #007bb6 !important;
}

.btn-dribbble {
  color: #ffffff !important;
  background-color: #ea4c89 !important;
}

.btn-googleplus {
  color: #ffffff !important;
  background-color: #dd4b39 !important;
}

.btn-instagram {
  color: #ffffff !important;
  background-color: #517fa4 !important;
}

.btn-pinterest {
  color: #ffffff !important;
  background-color: #cb2027 !important;
}

.btn-dropbox {
  color: #ffffff !important;
  background-color: #007ee5 !important;
}

.btn-flickr {
  color: #ffffff !important;
  background-color: #ff0084 !important;
}

.btn-tumblr {
  color: #ffffff !important;
  background-color: #32506d !important;
}

.btn-skype {
  color: #ffffff !important;
  background-color: #00aff0 !important;
}

.btn-youtube {
  color: #ffffff !important;
  background-color: #bb0000 !important;
}

.btn-github {
  color: #ffffff !important;
  background-color: #171515 !important;
}

/* Custom-modal */
.modal-demo {
  background-color: #ffffff;
  width: 600px;
  border-radius: 4px;
  display: none;
}

.modal-demo .close {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #ffffff;
  opacity: 0.5 !important;
}

.modal-demo .close:hover {
  opacity: 1 !important;
}

.custom-modal-title {
  padding: 15px 25px 15px 25px;
  line-height: 22px;
  font-size: 18px;
  background-color: #64b0f2;
  color: #ffffff;
  text-align: left;
  margin: 0px;
}

.custom-modal-text {
  padding: 20px;
  text-align: left;
}

.custombox-modal-flash .close, .custombox-modal-rotatedown .close {
  top: 20px;
  z-index: 9999;
}

/* =========== */
/* Sweet Alert */
/* =========== */
.sweet-alert {
  padding: 30px 25px;
}

.sweet-alert p {
  font-size: 15px;
  line-height: 24px;
  font-weight: normal;
}

.sweet-alert p:last-of-type {
  margin-bottom: 0 !important;
}

.sweet-alert .icon.success .placeholder {
  border: 4px solid rgba(27, 185, 154, 0.3);
}

.sweet-alert .icon.success .line {
  background-color: #1bb99a;
}

.sweet-alert .icon.error {
  border-color: #ff5d48;
}

.sweet-alert .icon.error .line {
  background-color: #ff5d48;
}

.sweet-alert .icon.warning {
  border-color: #f1b53d;
}

.sweet-alert .icon.info {
  border-color: #3db9dc;
}

.sweet-alert .btn-warning:focus, .sweet-alert .btn-info:focus, .sweet-alert .btn-success:focus, .sweet-alert .btn-danger:focus, .sweet-alert .btn-default:focus {
  box-shadow: none;
}

.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -.0667em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-alarm-clock:before {
  content: "\f34e";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-allergies:before {
  content: "\f461";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-archive:before {
  content: "\f187";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-alt-down:before {
  content: "\f354";
}

.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}

.fa-arrow-alt-from-left:before {
  content: "\f347";
}

.fa-arrow-alt-from-right:before {
  content: "\f348";
}

.fa-arrow-alt-from-top:before {
  content: "\f349";
}

.fa-arrow-alt-left:before {
  content: "\f355";
}

.fa-arrow-alt-right:before {
  content: "\f356";
}

.fa-arrow-alt-square-down:before {
  content: "\f350";
}

.fa-arrow-alt-square-left:before {
  content: "\f351";
}

.fa-arrow-alt-square-right:before {
  content: "\f352";
}

.fa-arrow-alt-square-up:before {
  content: "\f353";
}

.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}

.fa-arrow-alt-to-left:before {
  content: "\f34b";
}

.fa-arrow-alt-to-right:before {
  content: "\f34c";
}

.fa-arrow-alt-to-top:before {
  content: "\f34d";
}

.fa-arrow-alt-up:before {
  content: "\f357";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-from-bottom:before {
  content: "\f342";
}

.fa-arrow-from-left:before {
  content: "\f343";
}

.fa-arrow-from-right:before {
  content: "\f344";
}

.fa-arrow-from-top:before {
  content: "\f345";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-square-down:before {
  content: "\f339";
}

.fa-arrow-square-left:before {
  content: "\f33a";
}

.fa-arrow-square-right:before {
  content: "\f33b";
}

.fa-arrow-square-up:before {
  content: "\f33c";
}

.fa-arrow-to-bottom:before {
  content: "\f33d";
}

.fa-arrow-to-left:before {
  content: "\f33e";
}

.fa-arrow-to-right:before {
  content: "\f340";
}

.fa-arrow-to-top:before {
  content: "\f341";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-aws:before {
  content: "\f375";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-badge:before {
  content: "\f335";
}

.fa-badge-check:before {
  content: "\f336";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-band-aid:before {
  content: "\f462";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-barcode-alt:before {
  content: "\f463";
}

.fa-barcode-read:before {
  content: "\f464";
}

.fa-barcode-scan:before {
  content: "\f465";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-baseball:before {
  content: "\f432";
}

.fa-baseball-ball:before {
  content: "\f433";
}

.fa-basketball-ball:before {
  content: "\f434";
}

.fa-basketball-hoop:before {
  content: "\f435";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-bolt:before {
  content: "\f376";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-slash:before {
  content: "\f377";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-bed:before {
  content: "\f236";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-book:before {
  content: "\f02d";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-bowling-ball:before {
  content: "\f436";
}

.fa-bowling-pins:before {
  content: "\f437";
}

.fa-box:before {
  content: "\f466";
}

.fa-box-check:before {
  content: "\f467";
}

.fa-boxes:before {
  content: "\f468";
}

.fa-boxing-glove:before {
  content: "\f438";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-briefcase-medical:before {
  content: "\f469";
}

.fa-browser:before {
  content: "\f37e";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-burn:before {
  content: "\f46a";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-bus:before {
  content: "\f207";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-edit:before {
  content: "\f333";
}

.fa-calendar-exclamation:before {
  content: "\f334";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-alt:before {
  content: "\f332";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-capsules:before {
  content: "\f46b";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-caret-circle-down:before {
  content: "\f32d";
}

.fa-caret-circle-left:before {
  content: "\f32e";
}

.fa-caret-circle-right:before {
  content: "\f330";
}

.fa-caret-circle-up:before {
  content: "\f331";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-chess:before {
  content: "\f439";
}

.fa-chess-bishop:before {
  content: "\f43a";
}

.fa-chess-bishop-alt:before {
  content: "\f43b";
}

.fa-chess-board:before {
  content: "\f43c";
}

.fa-chess-clock:before {
  content: "\f43d";
}

.fa-chess-clock-alt:before {
  content: "\f43e";
}

.fa-chess-king:before {
  content: "\f43f";
}

.fa-chess-king-alt:before {
  content: "\f440";
}

.fa-chess-knight:before {
  content: "\f441";
}

.fa-chess-knight-alt:before {
  content: "\f442";
}

.fa-chess-pawn:before {
  content: "\f443";
}

.fa-chess-pawn-alt:before {
  content: "\f444";
}

.fa-chess-queen:before {
  content: "\f445";
}

.fa-chess-queen-alt:before {
  content: "\f446";
}

.fa-chess-rook:before {
  content: "\f447";
}

.fa-chess-rook-alt:before {
  content: "\f448";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-double-down:before {
  content: "\f322";
}

.fa-chevron-double-left:before {
  content: "\f323";
}

.fa-chevron-double-right:before {
  content: "\f324";
}

.fa-chevron-double-up:before {
  content: "\f325";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-square-down:before {
  content: "\f329";
}

.fa-chevron-square-left:before {
  content: "\f32a";
}

.fa-chevron-square-right:before {
  content: "\f32b";
}

.fa-chevron-square-up:before {
  content: "\f32c";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clipboard-check:before {
  content: "\f46c";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-upload:before {
  content: "\f0ee";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-club:before {
  content: "\f327";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-code-commit:before {
  content: "\f386";
}

.fa-code-merge:before {
  content: "\f387";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comments:before {
  content: "\f086";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-alt:before {
  content: "\f422";
}

.fa-compress-wide:before {
  content: "\f326";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-conveyor-belt:before {
  content: "\f46e";
}

.fa-conveyor-belt-alt:before {
  content: "\f46f";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-credit-card-blank:before {
  content: "\f389";
}

.fa-credit-card-front:before {
  content: "\f38a";
}

.fa-cricket:before {
  content: "\f449";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-curling:before {
  content: "\f44a";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-desktop-alt:before {
  content: "\f390";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-diagnoses:before {
  content: "\f470";
}

.fa-diamond:before {
  content: "\f219";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-dna:before {
  content: "\f471";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dolly:before {
  content: "\f472";
}

.fa-dolly-empty:before {
  content: "\f473";
}

.fa-dolly-flatbed:before {
  content: "\f474";
}

.fa-dolly-flatbed-alt:before {
  content: "\f475";
}

.fa-dolly-flatbed-empty:before {
  content: "\f476";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dumbbell:before {
  content: "\f44b";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edit:before {
  content: "\f044";
}

.fa-eject:before {
  content: "\f052";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-h-alt:before {
  content: "\f39b";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ellipsis-v-alt:before {
  content: "\f39c";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-square:before {
  content: "\f321";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-alt:before {
  content: "\f424";
}

.fa-expand-arrows:before {
  content: "\f31d";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expand-wide:before {
  content: "\f320";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square:before {
  content: "\f14c";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-female:before {
  content: "\f182";
}

.fa-field-hockey:before {
  content: "\f44c";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-check:before {
  content: "\f316";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-edit:before {
  content: "\f31c";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-exclamation:before {
  content: "\f31a";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-medical:before {
  content: "\f477";
}

.fa-file-medical-alt:before {
  content: "\f478";
}

.fa-file-minus:before {
  content: "\f318";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-plus:before {
  content: "\f319";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-times:before {
  content: "\f317";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-film:before {
  content: "\f008";
}

.fa-film-alt:before {
  content: "\f3a0";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-first-aid:before {
  content: "\f479";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-fly:before {
  content: "\f417";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-font-awesome-flag:before {
  content: "\f425";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-football-ball:before {
  content: "\f44e";
}

.fa-football-helmet:before {
  content: "\f44f";
}

.fa-forklift:before {
  content: "\f47a";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-frown:before {
  content: "\f119";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golf-ball:before {
  content: "\f450";
}

.fa-golf-club:before {
  content: "\f451";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-h1:before {
  content: "\f313";
}

.fa-h2:before {
  content: "\f314";
}

.fa-h3:before {
  content: "\f315";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hand-holding-box:before {
  content: "\f47b";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-receiving:before {
  content: "\f47c";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-hexagon:before {
  content: "\f312";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-hockey-puck:before {
  content: "\f453";
}

.fa-hockey-sticks:before {
  content: "\f454";
}

.fa-home:before {
  content: "\f015";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hospital-alt:before {
  content: "\f47d";
}

.fa-hospital-symbol:before {
  content: "\f47e";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-id-card-alt:before {
  content: "\f47f";
}

.fa-image:before {
  content: "\f03e";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-inbox-in:before {
  content: "\f310";
}

.fa-inbox-out:before {
  content: "\f311";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-industry-alt:before {
  content: "\f3b3";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-info-square:before {
  content: "\f30f";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-inventory:before {
  content: "\f480";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-jack-o-lantern:before {
  content: "\f30e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-key:before {
  content: "\f084";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-less:before {
  content: "\f41d";
}

.fa-level-down:before {
  content: "\f149";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up:before {
  content: "\f148";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-alt:before {
  content: "\f30d";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-lock-open-alt:before {
  content: "\f3c2";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-luchador:before {
  content: "\f455";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-male:before {
  content: "\f183";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-hexagon:before {
  content: "\f307";
}

.fa-minus-octagon:before {
  content: "\f308";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-mobile-android:before {
  content: "\f3ce";
}

.fa-mobile-android-alt:before {
  content: "\f3cf";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-moon:before {
  content: "\f186";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-music:before {
  content: "\f001";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nintendo-switch:before {
  content: "\f418";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-notes-medical:before {
  content: "\f481";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-octagon:before {
  content: "\f306";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-pallet:before {
  content: "\f482";
}

.fa-pallet-alt:before {
  content: "\f483";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-pennant:before {
  content: "\f456";
}

.fa-percent:before {
  content: "\f295";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-php:before {
  content: "\f457";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pills:before {
  content: "\f484";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-alt:before {
  content: "\f3de";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-hexagon:before {
  content: "\f300";
}

.fa-plus-octagon:before {
  content: "\f301";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-prescription-bottle:before {
  content: "\f485";
}

.fa-prescription-bottle-alt:before {
  content: "\f486";
}

.fa-print:before {
  content: "\f02f";
}

.fa-procedures:before {
  content: "\f487";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-question-square:before {
  content: "\f2fd";
}

.fa-quidditch:before {
  content: "\f458";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-racquet:before {
  content: "\f45a";
}

.fa-random:before {
  content: "\f074";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-react:before {
  content: "\f41b";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-rectangle-landscape:before {
  content: "\f2fa";
}

.fa-rectangle-portrait:before {
  content: "\f2fb";
}

.fa-rectangle-wide:before {
  content: "\f2fc";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-rendact:before {
  content: "\f3e4";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-repeat:before {
  content: "\f363";
}

.fa-repeat-1:before {
  content: "\f365";
}

.fa-repeat-1-alt:before {
  content: "\f366";
}

.fa-repeat-alt:before {
  content: "\f364";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-retweet-alt:before {
  content: "\f361";
}

.fa-road:before {
  content: "\f018";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-safari:before {
  content: "\f267";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-scanner:before {
  content: "\f488";
}

.fa-scanner-keyboard:before {
  content: "\f489";
}

.fa-scanner-touchscreen:before {
  content: "\f48a";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scrubber:before {
  content: "\f2f8";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-all:before {
  content: "\f367";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield:before {
  content: "\f132";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-shield-check:before {
  content: "\f2f7";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shipping-fast:before {
  content: "\f48b";
}

.fa-shipping-timed:before {
  content: "\f48c";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-shuttlecock:before {
  content: "\f45b";
}

.fa-sign-in:before {
  content: "\f090";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out:before {
  content: "\f08b";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-sliders-h-square:before {
  content: "\f3f0";
}

.fa-sliders-v:before {
  content: "\f3f1";
}

.fa-sliders-v-square:before {
  content: "\f3f2";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-smoking:before {
  content: "\f48d";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-spade:before {
  content: "\f2f4";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-spinner-third:before {
  content: "\f3f4";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-square-full:before {
  content: "\f45c";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-exclamation:before {
  content: "\f2f3";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-strava:before {
  content: "\f428";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-sun:before {
  content: "\f185";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-syringe:before {
  content: "\f48e";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-table-tennis:before {
  content: "\f45d";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablet-android:before {
  content: "\f3fb";
}

.fa-tablet-android-alt:before {
  content: "\f3fc";
}

.fa-tablet-rugged:before {
  content: "\f48f";
}

.fa-tablets:before {
  content: "\f490";
}

.fa-tachometer:before {
  content: "\f0e4";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-tennis-ball:before {
  content: "\f45e";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer:before {
  content: "\f491";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-ticket:before {
  content: "\f145";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-times-hexagon:before {
  content: "\f2ee";
}

.fa-times-octagon:before {
  content: "\f2f0";
}

.fa-times-square:before {
  content: "\f2d3";
}

.fa-tint:before {
  content: "\f043";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-train:before {
  content: "\f238";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-tree-alt:before {
  content: "\f400";
}

.fa-trello:before {
  content: "\f181";
}

.fa-triangle:before {
  content: "\f2ec";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-trophy-alt:before {
  content: "\f2eb";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-tv-retro:before {
  content: "\f401";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-usb:before {
  content: "\f287";
}

.fa-usd-circle:before {
  content: "\f2e8";
}

.fa-usd-square:before {
  content: "\f2e9";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-fork:before {
  content: "\f2e3";
}

.fa-utensil-knife:before {
  content: "\f2e4";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-utensils-alt:before {
  content: "\f2e6";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-vial:before {
  content: "\f492";
}

.fa-vials:before {
  content: "\f493";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-volleyball-ball:before {
  content: "\f45f";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f2e2";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-warehouse:before {
  content: "\f494";
}

.fa-warehouse-alt:before {
  content: "\f495";
}

.fa-watch:before {
  content: "\f2e1";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weight:before {
  content: "\f496";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whistle:before {
  content: "\f460";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-window:before {
  content: "\f40e";
}

.fa-window-alt:before {
  content: "\f40f";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-x-ray:before {
  content: "\f497";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../fonts/fa-brands-400.eot");
  src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/fa-regular-400.eot");
  src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/fa-light-300.eot");
  src: url("../fonts/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.woff") format("woff"), url("../fonts/fa-light-300.ttf") format("truetype"), url("../fonts/fa-light-300.svg#fontawesome") format("svg");
}

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("../fonts/fa-solid-900.eot");
  src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

@font-face {
  font-family: 'Material-Design-Iconic-Font';
  src: url("../fonts/Material-Design-Iconic-Font.woff2?v=2.2.0") format("woff2"), url("../fonts/Material-Design-Iconic-Font.woff?v=2.2.0") format("woff"), url("../fonts/Material-Design-Iconic-Font.ttf?v=2.2.0") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.zmdi, .navbar.navbar-menu .navbar-nav > li .dropdown-toggle:after, .navbar.navbar-menu .navbar-nav > li > .dropdown-menu li:before {
  display: inline-block;
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zmdi-hc-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.zmdi-hc-2x {
  font-size: 2em;
}

.zmdi-hc-3x {
  font-size: 3em;
}

.zmdi-hc-4x {
  font-size: 4em;
}

.zmdi-hc-5x {
  font-size: 5em;
}

.zmdi-hc-fw {
  width: 1.28571429em;
  text-align: center;
}

.zmdi-hc-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}

.zmdi-hc-ul > li {
  position: relative;
}

.zmdi-hc-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}

.zmdi-hc-li.zmdi-hc-lg {
  left: -1.85714286em;
}

.zmdi-hc-border {
  padding: .1em .25em;
  border: solid 0.1em #9e9e9e;
  border-radius: 2px;
}

.zmdi-hc-border-circle {
  padding: .1em .25em;
  border: solid 0.1em #9e9e9e;
  border-radius: 50%;
}

.zmdi.pull-left, .navbar.navbar-menu .navbar-nav > li .pull-left.dropdown-toggle:after, .navbar.navbar-menu .navbar-nav > li > .dropdown-menu li.pull-left:before {
  float: left;
  margin-right: .15em;
}

.zmdi.pull-right, .navbar.navbar-menu .navbar-nav > li .pull-right.dropdown-toggle:after, .navbar.navbar-menu .navbar-nav > li > .dropdown-menu li.pull-right:before {
  float: right;
  margin-left: .15em;
}

.zmdi-hc-spin {
  -webkit-animation: zmdi-spin 1.5s infinite linear;
  animation: zmdi-spin 1.5s infinite linear;
}

.zmdi-hc-spin-reverse {
  -webkit-animation: zmdi-spin-reverse 1.5s infinite linear;
  animation: zmdi-spin-reverse 1.5s infinite linear;
}

@-webkit-keyframes zmdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes zmdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes zmdi-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@keyframes zmdi-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

.zmdi-hc-rotate-90 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.zmdi-hc-rotate-180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.zmdi-hc-rotate-270 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.zmdi-hc-flip-horizontal {
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.zmdi-hc-flip-vertical {
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

.zmdi-hc-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.zmdi-hc-stack-1x,
.zmdi-hc-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.zmdi-hc-stack-1x {
  line-height: inherit;
}

.zmdi-hc-stack-2x {
  font-size: 2em;
}

.zmdi-hc-inverse {
  color: #ffffff;
}

/* Material Design Iconic Font uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.zmdi-3d-rotation:before {
  content: '\f101';
}

.zmdi-airplane-off:before {
  content: '\f102';
}

.zmdi-airplane:before {
  content: '\f103';
}

.zmdi-album:before {
  content: '\f104';
}

.zmdi-archive:before {
  content: '\f105';
}

.zmdi-assignment-account:before {
  content: '\f106';
}

.zmdi-assignment-alert:before {
  content: '\f107';
}

.zmdi-assignment-check:before {
  content: '\f108';
}

.zmdi-assignment-o:before {
  content: '\f109';
}

.zmdi-assignment-return:before {
  content: '\f10a';
}

.zmdi-assignment-returned:before {
  content: '\f10b';
}

.zmdi-assignment:before {
  content: '\f10c';
}

.zmdi-attachment-alt:before {
  content: '\f10d';
}

.zmdi-attachment:before {
  content: '\f10e';
}

.zmdi-audio:before {
  content: '\f10f';
}

.zmdi-badge-check:before {
  content: '\f110';
}

.zmdi-balance-wallet:before {
  content: '\f111';
}

.zmdi-balance:before {
  content: '\f112';
}

.zmdi-battery-alert:before {
  content: '\f113';
}

.zmdi-battery-flash:before {
  content: '\f114';
}

.zmdi-battery-unknown:before {
  content: '\f115';
}

.zmdi-battery:before {
  content: '\f116';
}

.zmdi-bike:before {
  content: '\f117';
}

.zmdi-block-alt:before {
  content: '\f118';
}

.zmdi-block:before {
  content: '\f119';
}

.zmdi-boat:before {
  content: '\f11a';
}

.zmdi-book-image:before {
  content: '\f11b';
}

.zmdi-book:before {
  content: '\f11c';
}

.zmdi-bookmark-outline:before {
  content: '\f11d';
}

.zmdi-bookmark:before {
  content: '\f11e';
}

.zmdi-brush:before {
  content: '\f11f';
}

.zmdi-bug:before {
  content: '\f120';
}

.zmdi-bus:before {
  content: '\f121';
}

.zmdi-cake:before {
  content: '\f122';
}

.zmdi-car-taxi:before {
  content: '\f123';
}

.zmdi-car-wash:before {
  content: '\f124';
}

.zmdi-car:before {
  content: '\f125';
}

.zmdi-card-giftcard:before {
  content: '\f126';
}

.zmdi-card-membership:before {
  content: '\f127';
}

.zmdi-card-travel:before {
  content: '\f128';
}

.zmdi-card:before {
  content: '\f129';
}

.zmdi-case-check:before {
  content: '\f12a';
}

.zmdi-case-download:before {
  content: '\f12b';
}

.zmdi-case-play:before {
  content: '\f12c';
}

.zmdi-case:before {
  content: '\f12d';
}

.zmdi-cast-connected:before {
  content: '\f12e';
}

.zmdi-cast:before {
  content: '\f12f';
}

.zmdi-chart-donut:before {
  content: '\f130';
}

.zmdi-chart:before {
  content: '\f131';
}

.zmdi-city-alt:before {
  content: '\f132';
}

.zmdi-city:before {
  content: '\f133';
}

.zmdi-close-circle-o:before {
  content: '\f134';
}

.zmdi-close-circle:before {
  content: '\f135';
}

.zmdi-close:before {
  content: '\f136';
}

.zmdi-cocktail:before {
  content: '\f137';
}

.zmdi-code-setting:before {
  content: '\f138';
}

.zmdi-code-smartphone:before {
  content: '\f139';
}

.zmdi-code:before {
  content: '\f13a';
}

.zmdi-coffee:before {
  content: '\f13b';
}

.zmdi-collection-bookmark:before {
  content: '\f13c';
}

.zmdi-collection-case-play:before {
  content: '\f13d';
}

.zmdi-collection-folder-image:before {
  content: '\f13e';
}

.zmdi-collection-image-o:before {
  content: '\f13f';
}

.zmdi-collection-image:before {
  content: '\f140';
}

.zmdi-collection-item-1:before {
  content: '\f141';
}

.zmdi-collection-item-2:before {
  content: '\f142';
}

.zmdi-collection-item-3:before {
  content: '\f143';
}

.zmdi-collection-item-4:before {
  content: '\f144';
}

.zmdi-collection-item-5:before {
  content: '\f145';
}

.zmdi-collection-item-6:before {
  content: '\f146';
}

.zmdi-collection-item-7:before {
  content: '\f147';
}

.zmdi-collection-item-8:before {
  content: '\f148';
}

.zmdi-collection-item-9-plus:before {
  content: '\f149';
}

.zmdi-collection-item-9:before {
  content: '\f14a';
}

.zmdi-collection-item:before {
  content: '\f14b';
}

.zmdi-collection-music:before {
  content: '\f14c';
}

.zmdi-collection-pdf:before {
  content: '\f14d';
}

.zmdi-collection-plus:before {
  content: '\f14e';
}

.zmdi-collection-speaker:before {
  content: '\f14f';
}

.zmdi-collection-text:before {
  content: '\f150';
}

.zmdi-collection-video:before {
  content: '\f151';
}

.zmdi-compass:before {
  content: '\f152';
}

.zmdi-cutlery:before {
  content: '\f153';
}

.zmdi-delete:before {
  content: '\f154';
}

.zmdi-dialpad:before {
  content: '\f155';
}

.zmdi-dns:before {
  content: '\f156';
}

.zmdi-drink:before {
  content: '\f157';
}

.zmdi-edit:before {
  content: '\f158';
}

.zmdi-email-open:before {
  content: '\f159';
}

.zmdi-email:before {
  content: '\f15a';
}

.zmdi-eye-off:before {
  content: '\f15b';
}

.zmdi-eye:before {
  content: '\f15c';
}

.zmdi-eyedropper:before {
  content: '\f15d';
}

.zmdi-favorite-outline:before {
  content: '\f15e';
}

.zmdi-favorite:before {
  content: '\f15f';
}

.zmdi-filter-list:before {
  content: '\f160';
}

.zmdi-fire:before {
  content: '\f161';
}

.zmdi-flag:before {
  content: '\f162';
}

.zmdi-flare:before {
  content: '\f163';
}

.zmdi-flash-auto:before {
  content: '\f164';
}

.zmdi-flash-off:before {
  content: '\f165';
}

.zmdi-flash:before {
  content: '\f166';
}

.zmdi-flip:before {
  content: '\f167';
}

.zmdi-flower-alt:before {
  content: '\f168';
}

.zmdi-flower:before {
  content: '\f169';
}

.zmdi-font:before {
  content: '\f16a';
}

.zmdi-fullscreen-alt:before {
  content: '\f16b';
}

.zmdi-fullscreen-exit:before {
  content: '\f16c';
}

.zmdi-fullscreen:before {
  content: '\f16d';
}

.zmdi-functions:before {
  content: '\f16e';
}

.zmdi-gas-station:before {
  content: '\f16f';
}

.zmdi-gesture:before {
  content: '\f170';
}

.zmdi-globe-alt:before {
  content: '\f171';
}

.zmdi-globe-lock:before {
  content: '\f172';
}

.zmdi-globe:before {
  content: '\f173';
}

.zmdi-graduation-cap:before {
  content: '\f174';
}

.zmdi-home:before {
  content: '\f175';
}

.zmdi-hospital-alt:before {
  content: '\f176';
}

.zmdi-hospital:before {
  content: '\f177';
}

.zmdi-hotel:before {
  content: '\f178';
}

.zmdi-hourglass-alt:before {
  content: '\f179';
}

.zmdi-hourglass-outline:before {
  content: '\f17a';
}

.zmdi-hourglass:before {
  content: '\f17b';
}

.zmdi-http:before {
  content: '\f17c';
}

.zmdi-image-alt:before {
  content: '\f17d';
}

.zmdi-image-o:before {
  content: '\f17e';
}

.zmdi-image:before {
  content: '\f17f';
}

.zmdi-inbox:before {
  content: '\f180';
}

.zmdi-invert-colors-off:before {
  content: '\f181';
}

.zmdi-invert-colors:before {
  content: '\f182';
}

.zmdi-key:before {
  content: '\f183';
}

.zmdi-label-alt-outline:before {
  content: '\f184';
}

.zmdi-label-alt:before {
  content: '\f185';
}

.zmdi-label-heart:before {
  content: '\f186';
}

.zmdi-label:before {
  content: '\f187';
}

.zmdi-labels:before {
  content: '\f188';
}

.zmdi-lamp:before {
  content: '\f189';
}

.zmdi-landscape:before {
  content: '\f18a';
}

.zmdi-layers-off:before {
  content: '\f18b';
}

.zmdi-layers:before {
  content: '\f18c';
}

.zmdi-library:before {
  content: '\f18d';
}

.zmdi-link:before {
  content: '\f18e';
}

.zmdi-lock-open:before {
  content: '\f18f';
}

.zmdi-lock-outline:before {
  content: '\f190';
}

.zmdi-lock:before {
  content: '\f191';
}

.zmdi-mail-reply-all:before {
  content: '\f192';
}

.zmdi-mail-reply:before {
  content: '\f193';
}

.zmdi-mail-send:before {
  content: '\f194';
}

.zmdi-mall:before {
  content: '\f195';
}

.zmdi-map:before {
  content: '\f196';
}

.zmdi-menu:before {
  content: '\f197';
}

.zmdi-money-box:before {
  content: '\f198';
}

.zmdi-money-off:before {
  content: '\f199';
}

.zmdi-money:before {
  content: '\f19a';
}

.zmdi-more-vert:before {
  content: '\f19b';
}

.zmdi-more:before {
  content: '\f19c';
}

.zmdi-movie-alt:before {
  content: '\f19d';
}

.zmdi-movie:before {
  content: '\f19e';
}

.zmdi-nature-people:before {
  content: '\f19f';
}

.zmdi-nature:before {
  content: '\f1a0';
}

.zmdi-navigation:before {
  content: '\f1a1';
}

.zmdi-open-in-browser:before {
  content: '\f1a2';
}

.zmdi-open-in-new:before {
  content: '\f1a3';
}

.zmdi-palette:before {
  content: '\f1a4';
}

.zmdi-parking:before {
  content: '\f1a5';
}

.zmdi-pin-account:before {
  content: '\f1a6';
}

.zmdi-pin-assistant:before {
  content: '\f1a7';
}

.zmdi-pin-drop:before {
  content: '\f1a8';
}

.zmdi-pin-help:before {
  content: '\f1a9';
}

.zmdi-pin-off:before {
  content: '\f1aa';
}

.zmdi-pin:before {
  content: '\f1ab';
}

.zmdi-pizza:before {
  content: '\f1ac';
}

.zmdi-plaster:before {
  content: '\f1ad';
}

.zmdi-power-setting:before {
  content: '\f1ae';
}

.zmdi-power:before {
  content: '\f1af';
}

.zmdi-print:before {
  content: '\f1b0';
}

.zmdi-puzzle-piece:before {
  content: '\f1b1';
}

.zmdi-quote:before {
  content: '\f1b2';
}

.zmdi-railway:before {
  content: '\f1b3';
}

.zmdi-receipt:before {
  content: '\f1b4';
}

.zmdi-refresh-alt:before {
  content: '\f1b5';
}

.zmdi-refresh-sync-alert:before {
  content: '\f1b6';
}

.zmdi-refresh-sync-off:before {
  content: '\f1b7';
}

.zmdi-refresh-sync:before {
  content: '\f1b8';
}

.zmdi-refresh:before {
  content: '\f1b9';
}

.zmdi-roller:before {
  content: '\f1ba';
}

.zmdi-ruler:before {
  content: '\f1bb';
}

.zmdi-scissors:before {
  content: '\f1bc';
}

.zmdi-screen-rotation-lock:before {
  content: '\f1bd';
}

.zmdi-screen-rotation:before {
  content: '\f1be';
}

.zmdi-search-for:before {
  content: '\f1bf';
}

.zmdi-search-in-file:before {
  content: '\f1c0';
}

.zmdi-search-in-page:before {
  content: '\f1c1';
}

.zmdi-search-replace:before {
  content: '\f1c2';
}

.zmdi-search:before {
  content: '\f1c3';
}

.zmdi-seat:before {
  content: '\f1c4';
}

.zmdi-settings-square:before {
  content: '\f1c5';
}

.zmdi-settings:before {
  content: '\f1c6';
}

.zmdi-shield-check:before {
  content: '\f1c7';
}

.zmdi-shield-security:before {
  content: '\f1c8';
}

.zmdi-shopping-basket:before {
  content: '\f1c9';
}

.zmdi-shopping-cart-plus:before {
  content: '\f1ca';
}

.zmdi-shopping-cart:before {
  content: '\f1cb';
}

.zmdi-sign-in:before {
  content: '\f1cc';
}

.zmdi-sort-amount-asc:before {
  content: '\f1cd';
}

.zmdi-sort-amount-desc:before {
  content: '\f1ce';
}

.zmdi-sort-asc:before {
  content: '\f1cf';
}

.zmdi-sort-desc:before {
  content: '\f1d0';
}

.zmdi-spellcheck:before {
  content: '\f1d1';
}

.zmdi-storage:before {
  content: '\f1d2';
}

.zmdi-store-24:before {
  content: '\f1d3';
}

.zmdi-store:before {
  content: '\f1d4';
}

.zmdi-subway:before {
  content: '\f1d5';
}

.zmdi-sun:before {
  content: '\f1d6';
}

.zmdi-tab-unselected:before {
  content: '\f1d7';
}

.zmdi-tab:before {
  content: '\f1d8';
}

.zmdi-tag-close:before {
  content: '\f1d9';
}

.zmdi-tag-more:before {
  content: '\f1da';
}

.zmdi-tag:before {
  content: '\f1db';
}

.zmdi-thumb-down:before {
  content: '\f1dc';
}

.zmdi-thumb-up-down:before {
  content: '\f1dd';
}

.zmdi-thumb-up:before {
  content: '\f1de';
}

.zmdi-ticket-star:before {
  content: '\f1df';
}

.zmdi-toll:before {
  content: '\f1e0';
}

.zmdi-toys:before {
  content: '\f1e1';
}

.zmdi-traffic:before {
  content: '\f1e2';
}

.zmdi-translate:before {
  content: '\f1e3';
}

.zmdi-triangle-down:before {
  content: '\f1e4';
}

.zmdi-triangle-up:before {
  content: '\f1e5';
}

.zmdi-truck:before {
  content: '\f1e6';
}

.zmdi-turning-sign:before {
  content: '\f1e7';
}

.zmdi-wallpaper:before {
  content: '\f1e8';
}

.zmdi-washing-machine:before {
  content: '\f1e9';
}

.zmdi-window-maximize:before {
  content: '\f1ea';
}

.zmdi-window-minimize:before {
  content: '\f1eb';
}

.zmdi-window-restore:before {
  content: '\f1ec';
}

.zmdi-wrench:before {
  content: '\f1ed';
}

.zmdi-zoom-in:before {
  content: '\f1ee';
}

.zmdi-zoom-out:before {
  content: '\f1ef';
}

.zmdi-alert-circle-o:before {
  content: '\f1f0';
}

.zmdi-alert-circle:before {
  content: '\f1f1';
}

.zmdi-alert-octagon:before {
  content: '\f1f2';
}

.zmdi-alert-polygon:before {
  content: '\f1f3';
}

.zmdi-alert-triangle:before {
  content: '\f1f4';
}

.zmdi-help-outline:before {
  content: '\f1f5';
}

.zmdi-help:before {
  content: '\f1f6';
}

.zmdi-info-outline:before {
  content: '\f1f7';
}

.zmdi-info:before {
  content: '\f1f8';
}

.zmdi-notifications-active:before {
  content: '\f1f9';
}

.zmdi-notifications-add:before {
  content: '\f1fa';
}

.zmdi-notifications-none:before {
  content: '\f1fb';
}

.zmdi-notifications-off:before {
  content: '\f1fc';
}

.zmdi-notifications-paused:before {
  content: '\f1fd';
}

.zmdi-notifications:before {
  content: '\f1fe';
}

.zmdi-account-add:before {
  content: '\f1ff';
}

.zmdi-account-box-mail:before {
  content: '\f200';
}

.zmdi-account-box-o:before {
  content: '\f201';
}

.zmdi-account-box-phone:before {
  content: '\f202';
}

.zmdi-account-box:before {
  content: '\f203';
}

.zmdi-account-calendar:before {
  content: '\f204';
}

.zmdi-account-circle:before {
  content: '\f205';
}

.zmdi-account-o:before {
  content: '\f206';
}

.zmdi-account:before {
  content: '\f207';
}

.zmdi-accounts-add:before {
  content: '\f208';
}

.zmdi-accounts-alt:before {
  content: '\f209';
}

.zmdi-accounts-list-alt:before {
  content: '\f20a';
}

.zmdi-accounts-list:before {
  content: '\f20b';
}

.zmdi-accounts-outline:before {
  content: '\f20c';
}

.zmdi-accounts:before {
  content: '\f20d';
}

.zmdi-face:before {
  content: '\f20e';
}

.zmdi-female:before {
  content: '\f20f';
}

.zmdi-male-alt:before {
  content: '\f210';
}

.zmdi-male-female:before {
  content: '\f211';
}

.zmdi-male:before {
  content: '\f212';
}

.zmdi-mood-bad:before {
  content: '\f213';
}

.zmdi-mood:before {
  content: '\f214';
}

.zmdi-run:before {
  content: '\f215';
}

.zmdi-walk:before {
  content: '\f216';
}

.zmdi-cloud-box:before {
  content: '\f217';
}

.zmdi-cloud-circle:before {
  content: '\f218';
}

.zmdi-cloud-done:before {
  content: '\f219';
}

.zmdi-cloud-download:before {
  content: '\f21a';
}

.zmdi-cloud-off:before {
  content: '\f21b';
}

.zmdi-cloud-outline-alt:before {
  content: '\f21c';
}

.zmdi-cloud-outline:before {
  content: '\f21d';
}

.zmdi-cloud-upload:before {
  content: '\f21e';
}

.zmdi-cloud:before {
  content: '\f21f';
}

.zmdi-download:before {
  content: '\f220';
}

.zmdi-file-plus:before {
  content: '\f221';
}

.zmdi-file-text:before {
  content: '\f222';
}

.zmdi-file:before {
  content: '\f223';
}

.zmdi-folder-outline:before {
  content: '\f224';
}

.zmdi-folder-person:before {
  content: '\f225';
}

.zmdi-folder-star-alt:before {
  content: '\f226';
}

.zmdi-folder-star:before {
  content: '\f227';
}

.zmdi-folder:before {
  content: '\f228';
}

.zmdi-gif:before {
  content: '\f229';
}

.zmdi-upload:before {
  content: '\f22a';
}

.zmdi-border-all:before {
  content: '\f22b';
}

.zmdi-border-bottom:before {
  content: '\f22c';
}

.zmdi-border-clear:before {
  content: '\f22d';
}

.zmdi-border-color:before {
  content: '\f22e';
}

.zmdi-border-horizontal:before {
  content: '\f22f';
}

.zmdi-border-inner:before {
  content: '\f230';
}

.zmdi-border-left:before {
  content: '\f231';
}

.zmdi-border-outer:before {
  content: '\f232';
}

.zmdi-border-right:before {
  content: '\f233';
}

.zmdi-border-style:before {
  content: '\f234';
}

.zmdi-border-top:before {
  content: '\f235';
}

.zmdi-border-vertical:before {
  content: '\f236';
}

.zmdi-copy:before {
  content: '\f237';
}

.zmdi-crop:before {
  content: '\f238';
}

.zmdi-format-align-center:before {
  content: '\f239';
}

.zmdi-format-align-justify:before {
  content: '\f23a';
}

.zmdi-format-align-left:before {
  content: '\f23b';
}

.zmdi-format-align-right:before {
  content: '\f23c';
}

.zmdi-format-bold:before {
  content: '\f23d';
}

.zmdi-format-clear-all:before {
  content: '\f23e';
}

.zmdi-format-clear:before {
  content: '\f23f';
}

.zmdi-format-color-fill:before {
  content: '\f240';
}

.zmdi-format-color-reset:before {
  content: '\f241';
}

.zmdi-format-color-text:before {
  content: '\f242';
}

.zmdi-format-indent-decrease:before {
  content: '\f243';
}

.zmdi-format-indent-increase:before {
  content: '\f244';
}

.zmdi-format-italic:before {
  content: '\f245';
}

.zmdi-format-line-spacing:before {
  content: '\f246';
}

.zmdi-format-list-bulleted:before {
  content: '\f247';
}

.zmdi-format-list-numbered:before {
  content: '\f248';
}

.zmdi-format-ltr:before {
  content: '\f249';
}

.zmdi-format-rtl:before {
  content: '\f24a';
}

.zmdi-format-size:before {
  content: '\f24b';
}

.zmdi-format-strikethrough-s:before {
  content: '\f24c';
}

.zmdi-format-strikethrough:before {
  content: '\f24d';
}

.zmdi-format-subject:before {
  content: '\f24e';
}

.zmdi-format-underlined:before {
  content: '\f24f';
}

.zmdi-format-valign-bottom:before {
  content: '\f250';
}

.zmdi-format-valign-center:before {
  content: '\f251';
}

.zmdi-format-valign-top:before {
  content: '\f252';
}

.zmdi-redo:before {
  content: '\f253';
}

.zmdi-select-all:before {
  content: '\f254';
}

.zmdi-space-bar:before {
  content: '\f255';
}

.zmdi-text-format:before {
  content: '\f256';
}

.zmdi-transform:before {
  content: '\f257';
}

.zmdi-undo:before {
  content: '\f258';
}

.zmdi-wrap-text:before {
  content: '\f259';
}

.zmdi-comment-alert:before {
  content: '\f25a';
}

.zmdi-comment-alt-text:before {
  content: '\f25b';
}

.zmdi-comment-alt:before {
  content: '\f25c';
}

.zmdi-comment-edit:before {
  content: '\f25d';
}

.zmdi-comment-image:before {
  content: '\f25e';
}

.zmdi-comment-list:before {
  content: '\f25f';
}

.zmdi-comment-more:before {
  content: '\f260';
}

.zmdi-comment-outline:before {
  content: '\f261';
}

.zmdi-comment-text-alt:before {
  content: '\f262';
}

.zmdi-comment-text:before {
  content: '\f263';
}

.zmdi-comment-video:before {
  content: '\f264';
}

.zmdi-comment:before {
  content: '\f265';
}

.zmdi-comments:before {
  content: '\f266';
}

.zmdi-check-all:before {
  content: '\f267';
}

.zmdi-check-circle-u:before {
  content: '\f268';
}

.zmdi-check-circle:before {
  content: '\f269';
}

.zmdi-check-square:before {
  content: '\f26a';
}

.zmdi-check:before {
  content: '\f26b';
}

.zmdi-circle-o:before {
  content: '\f26c';
}

.zmdi-circle:before {
  content: '\f26d';
}

.zmdi-dot-circle-alt:before {
  content: '\f26e';
}

.zmdi-dot-circle:before {
  content: '\f26f';
}

.zmdi-minus-circle-outline:before {
  content: '\f270';
}

.zmdi-minus-circle:before {
  content: '\f271';
}

.zmdi-minus-square:before {
  content: '\f272';
}

.zmdi-minus:before {
  content: '\f273';
}

.zmdi-plus-circle-o-duplicate:before {
  content: '\f274';
}

.zmdi-plus-circle-o:before {
  content: '\f275';
}

.zmdi-plus-circle:before {
  content: '\f276';
}

.zmdi-plus-square:before {
  content: '\f277';
}

.zmdi-plus:before {
  content: '\f278';
}

.zmdi-square-o:before {
  content: '\f279';
}

.zmdi-star-circle:before {
  content: '\f27a';
}

.zmdi-star-half:before {
  content: '\f27b';
}

.zmdi-star-outline:before {
  content: '\f27c';
}

.zmdi-star:before {
  content: '\f27d';
}

.zmdi-bluetooth-connected:before {
  content: '\f27e';
}

.zmdi-bluetooth-off:before {
  content: '\f27f';
}

.zmdi-bluetooth-search:before {
  content: '\f280';
}

.zmdi-bluetooth-setting:before {
  content: '\f281';
}

.zmdi-bluetooth:before {
  content: '\f282';
}

.zmdi-camera-add:before {
  content: '\f283';
}

.zmdi-camera-alt:before {
  content: '\f284';
}

.zmdi-camera-bw:before {
  content: '\f285';
}

.zmdi-camera-front:before {
  content: '\f286';
}

.zmdi-camera-mic:before {
  content: '\f287';
}

.zmdi-camera-party-mode:before {
  content: '\f288';
}

.zmdi-camera-rear:before {
  content: '\f289';
}

.zmdi-camera-roll:before {
  content: '\f28a';
}

.zmdi-camera-switch:before {
  content: '\f28b';
}

.zmdi-camera:before {
  content: '\f28c';
}

.zmdi-card-alert:before {
  content: '\f28d';
}

.zmdi-card-off:before {
  content: '\f28e';
}

.zmdi-card-sd:before {
  content: '\f28f';
}

.zmdi-card-sim:before {
  content: '\f290';
}

.zmdi-desktop-mac:before {
  content: '\f291';
}

.zmdi-desktop-windows:before {
  content: '\f292';
}

.zmdi-device-hub:before {
  content: '\f293';
}

.zmdi-devices-off:before {
  content: '\f294';
}

.zmdi-devices:before {
  content: '\f295';
}

.zmdi-dock:before {
  content: '\f296';
}

.zmdi-floppy:before {
  content: '\f297';
}

.zmdi-gamepad:before {
  content: '\f298';
}

.zmdi-gps-dot:before {
  content: '\f299';
}

.zmdi-gps-off:before {
  content: '\f29a';
}

.zmdi-gps:before {
  content: '\f29b';
}

.zmdi-headset-mic:before {
  content: '\f29c';
}

.zmdi-headset:before {
  content: '\f29d';
}

.zmdi-input-antenna:before {
  content: '\f29e';
}

.zmdi-input-composite:before {
  content: '\f29f';
}

.zmdi-input-hdmi:before {
  content: '\f2a0';
}

.zmdi-input-power:before {
  content: '\f2a1';
}

.zmdi-input-svideo:before {
  content: '\f2a2';
}

.zmdi-keyboard-hide:before {
  content: '\f2a3';
}

.zmdi-keyboard:before {
  content: '\f2a4';
}

.zmdi-laptop-chromebook:before {
  content: '\f2a5';
}

.zmdi-laptop-mac:before {
  content: '\f2a6';
}

.zmdi-laptop:before {
  content: '\f2a7';
}

.zmdi-mic-off:before {
  content: '\f2a8';
}

.zmdi-mic-outline:before {
  content: '\f2a9';
}

.zmdi-mic-setting:before {
  content: '\f2aa';
}

.zmdi-mic:before {
  content: '\f2ab';
}

.zmdi-mouse:before {
  content: '\f2ac';
}

.zmdi-network-alert:before {
  content: '\f2ad';
}

.zmdi-network-locked:before {
  content: '\f2ae';
}

.zmdi-network-off:before {
  content: '\f2af';
}

.zmdi-network-outline:before {
  content: '\f2b0';
}

.zmdi-network-setting:before {
  content: '\f2b1';
}

.zmdi-network:before {
  content: '\f2b2';
}

.zmdi-phone-bluetooth:before {
  content: '\f2b3';
}

.zmdi-phone-end:before {
  content: '\f2b4';
}

.zmdi-phone-forwarded:before {
  content: '\f2b5';
}

.zmdi-phone-in-talk:before {
  content: '\f2b6';
}

.zmdi-phone-locked:before {
  content: '\f2b7';
}

.zmdi-phone-missed:before {
  content: '\f2b8';
}

.zmdi-phone-msg:before {
  content: '\f2b9';
}

.zmdi-phone-paused:before {
  content: '\f2ba';
}

.zmdi-phone-ring:before {
  content: '\f2bb';
}

.zmdi-phone-setting:before {
  content: '\f2bc';
}

.zmdi-phone-sip:before {
  content: '\f2bd';
}

.zmdi-phone:before {
  content: '\f2be';
}

.zmdi-portable-wifi-changes:before {
  content: '\f2bf';
}

.zmdi-portable-wifi-off:before {
  content: '\f2c0';
}

.zmdi-portable-wifi:before {
  content: '\f2c1';
}

.zmdi-radio:before {
  content: '\f2c2';
}

.zmdi-reader:before {
  content: '\f2c3';
}

.zmdi-remote-control-alt:before {
  content: '\f2c4';
}

.zmdi-remote-control:before {
  content: '\f2c5';
}

.zmdi-router:before {
  content: '\f2c6';
}

.zmdi-scanner:before {
  content: '\f2c7';
}

.zmdi-smartphone-android:before {
  content: '\f2c8';
}

.zmdi-smartphone-download:before {
  content: '\f2c9';
}

.zmdi-smartphone-erase:before {
  content: '\f2ca';
}

.zmdi-smartphone-info:before {
  content: '\f2cb';
}

.zmdi-smartphone-iphone:before {
  content: '\f2cc';
}

.zmdi-smartphone-landscape-lock:before {
  content: '\f2cd';
}

.zmdi-smartphone-landscape:before {
  content: '\f2ce';
}

.zmdi-smartphone-lock:before {
  content: '\f2cf';
}

.zmdi-smartphone-portrait-lock:before {
  content: '\f2d0';
}

.zmdi-smartphone-ring:before {
  content: '\f2d1';
}

.zmdi-smartphone-setting:before {
  content: '\f2d2';
}

.zmdi-smartphone-setup:before {
  content: '\f2d3';
}

.zmdi-smartphone:before {
  content: '\f2d4';
}

.zmdi-speaker:before {
  content: '\f2d5';
}

.zmdi-tablet-android:before {
  content: '\f2d6';
}

.zmdi-tablet-mac:before {
  content: '\f2d7';
}

.zmdi-tablet:before {
  content: '\f2d8';
}

.zmdi-tv-alt-play:before {
  content: '\f2d9';
}

.zmdi-tv-list:before {
  content: '\f2da';
}

.zmdi-tv-play:before {
  content: '\f2db';
}

.zmdi-tv:before {
  content: '\f2dc';
}

.zmdi-usb:before {
  content: '\f2dd';
}

.zmdi-videocam-off:before {
  content: '\f2de';
}

.zmdi-videocam-switch:before {
  content: '\f2df';
}

.zmdi-videocam:before {
  content: '\f2e0';
}

.zmdi-watch:before {
  content: '\f2e1';
}

.zmdi-wifi-alt-2:before {
  content: '\f2e2';
}

.zmdi-wifi-alt:before {
  content: '\f2e3';
}

.zmdi-wifi-info:before {
  content: '\f2e4';
}

.zmdi-wifi-lock:before {
  content: '\f2e5';
}

.zmdi-wifi-off:before {
  content: '\f2e6';
}

.zmdi-wifi-outline:before {
  content: '\f2e7';
}

.zmdi-wifi:before {
  content: '\f2e8';
}

.zmdi-arrow-left-bottom:before {
  content: '\f2e9';
}

.zmdi-arrow-left:before {
  content: '\f2ea';
}

.zmdi-arrow-merge:before {
  content: '\f2eb';
}

.zmdi-arrow-missed:before {
  content: '\f2ec';
}

.zmdi-arrow-right-top:before {
  content: '\f2ed';
}

.zmdi-arrow-right:before {
  content: '\f2ee';
}

.zmdi-arrow-split:before {
  content: '\f2ef';
}

.zmdi-arrows:before {
  content: '\f2f0';
}

.zmdi-caret-down-circle:before {
  content: '\f2f1';
}

.zmdi-caret-down:before {
  content: '\f2f2';
}

.zmdi-caret-left-circle:before {
  content: '\f2f3';
}

.zmdi-caret-left:before {
  content: '\f2f4';
}

.zmdi-caret-right-circle:before {
  content: '\f2f5';
}

.zmdi-caret-right:before {
  content: '\f2f6';
}

.zmdi-caret-up-circle:before {
  content: '\f2f7';
}

.zmdi-caret-up:before {
  content: '\f2f8';
}

.zmdi-chevron-down:before {
  content: '\f2f9';
}

.zmdi-chevron-left:before {
  content: '\f2fa';
}

.zmdi-chevron-right:before {
  content: '\f2fb';
}

.zmdi-chevron-up:before {
  content: '\f2fc';
}

.zmdi-forward:before {
  content: '\f2fd';
}

.zmdi-long-arrow-down:before {
  content: '\f2fe';
}

.zmdi-long-arrow-left:before {
  content: '\f2ff';
}

.zmdi-long-arrow-return:before {
  content: '\f300';
}

.zmdi-long-arrow-right:before {
  content: '\f301';
}

.zmdi-long-arrow-tab:before {
  content: '\f302';
}

.zmdi-long-arrow-up:before {
  content: '\f303';
}

.zmdi-rotate-ccw:before {
  content: '\f304';
}

.zmdi-rotate-cw:before {
  content: '\f305';
}

.zmdi-rotate-left:before {
  content: '\f306';
}

.zmdi-rotate-right:before {
  content: '\f307';
}

.zmdi-square-down:before {
  content: '\f308';
}

.zmdi-square-right:before {
  content: '\f309';
}

.zmdi-swap-alt:before {
  content: '\f30a';
}

.zmdi-swap-vertical-circle:before {
  content: '\f30b';
}

.zmdi-swap-vertical:before {
  content: '\f30c';
}

.zmdi-swap:before {
  content: '\f30d';
}

.zmdi-trending-down:before {
  content: '\f30e';
}

.zmdi-trending-flat:before {
  content: '\f30f';
}

.zmdi-trending-up:before {
  content: '\f310';
}

.zmdi-unfold-less:before {
  content: '\f311';
}

.zmdi-unfold-more:before {
  content: '\f312';
}

.zmdi-apps:before {
  content: '\f313';
}

.zmdi-grid-off:before {
  content: '\f314';
}

.zmdi-grid:before {
  content: '\f315';
}

.zmdi-view-agenda:before {
  content: '\f316';
}

.zmdi-view-array:before {
  content: '\f317';
}

.zmdi-view-carousel:before {
  content: '\f318';
}

.zmdi-view-column:before {
  content: '\f319';
}

.zmdi-view-comfy:before {
  content: '\f31a';
}

.zmdi-view-compact:before {
  content: '\f31b';
}

.zmdi-view-dashboard:before {
  content: '\f31c';
}

.zmdi-view-day:before {
  content: '\f31d';
}

.zmdi-view-headline:before {
  content: '\f31e';
}

.zmdi-view-list-alt:before {
  content: '\f31f';
}

.zmdi-view-list:before {
  content: '\f320';
}

.zmdi-view-module:before {
  content: '\f321';
}

.zmdi-view-quilt:before {
  content: '\f322';
}

.zmdi-view-stream:before {
  content: '\f323';
}

.zmdi-view-subtitles:before {
  content: '\f324';
}

.zmdi-view-toc:before {
  content: '\f325';
}

.zmdi-view-web:before {
  content: '\f326';
}

.zmdi-view-week:before {
  content: '\f327';
}

.zmdi-widgets:before {
  content: '\f328';
}

.zmdi-alarm-check:before {
  content: '\f329';
}

.zmdi-alarm-off:before {
  content: '\f32a';
}

.zmdi-alarm-plus:before {
  content: '\f32b';
}

.zmdi-alarm-snooze:before {
  content: '\f32c';
}

.zmdi-alarm:before {
  content: '\f32d';
}

.zmdi-calendar-alt:before {
  content: '\f32e';
}

.zmdi-calendar-check:before {
  content: '\f32f';
}

.zmdi-calendar-close:before {
  content: '\f330';
}

.zmdi-calendar-note:before {
  content: '\f331';
}

.zmdi-calendar:before {
  content: '\f332';
}

.zmdi-time-countdown:before {
  content: '\f333';
}

.zmdi-time-interval:before {
  content: '\f334';
}

.zmdi-time-restore-setting:before {
  content: '\f335';
}

.zmdi-time-restore:before {
  content: '\f336';
}

.zmdi-time:before {
  content: '\f337';
}

.zmdi-timer-off:before {
  content: '\f338';
}

.zmdi-timer:before {
  content: '\f339';
}

.zmdi-android-alt:before {
  content: '\f33a';
}

.zmdi-android:before {
  content: '\f33b';
}

.zmdi-apple:before {
  content: '\f33c';
}

.zmdi-behance:before {
  content: '\f33d';
}

.zmdi-codepen:before {
  content: '\f33e';
}

.zmdi-dribbble:before {
  content: '\f33f';
}

.zmdi-dropbox:before {
  content: '\f340';
}

.zmdi-evernote:before {
  content: '\f341';
}

.zmdi-facebook-box:before {
  content: '\f342';
}

.zmdi-facebook:before {
  content: '\f343';
}

.zmdi-github-box:before {
  content: '\f344';
}

.zmdi-github:before {
  content: '\f345';
}

.zmdi-google-drive:before {
  content: '\f346';
}

.zmdi-google-earth:before {
  content: '\f347';
}

.zmdi-google-glass:before {
  content: '\f348';
}

.zmdi-google-maps:before {
  content: '\f349';
}

.zmdi-google-pages:before {
  content: '\f34a';
}

.zmdi-google-play:before {
  content: '\f34b';
}

.zmdi-google-plus-box:before {
  content: '\f34c';
}

.zmdi-google-plus:before {
  content: '\f34d';
}

.zmdi-google:before {
  content: '\f34e';
}

.zmdi-instagram:before {
  content: '\f34f';
}

.zmdi-language-css3:before {
  content: '\f350';
}

.zmdi-language-html5:before {
  content: '\f351';
}

.zmdi-language-javascript:before {
  content: '\f352';
}

.zmdi-language-python-alt:before {
  content: '\f353';
}

.zmdi-language-python:before {
  content: '\f354';
}

.zmdi-lastfm:before {
  content: '\f355';
}

.zmdi-linkedin-box:before {
  content: '\f356';
}

.zmdi-paypal:before {
  content: '\f357';
}

.zmdi-pinterest-box:before {
  content: '\f358';
}

.zmdi-pocket:before {
  content: '\f359';
}

.zmdi-polymer:before {
  content: '\f35a';
}

.zmdi-share:before {
  content: '\f35b';
}

.zmdi-stackoverflow:before {
  content: '\f35c';
}

.zmdi-steam-square:before {
  content: '\f35d';
}

.zmdi-steam:before {
  content: '\f35e';
}

.zmdi-twitter-box:before {
  content: '\f35f';
}

.zmdi-twitter:before {
  content: '\f360';
}

.zmdi-vk:before {
  content: '\f361';
}

.zmdi-wikipedia:before {
  content: '\f362';
}

.zmdi-windows:before {
  content: '\f363';
}

.zmdi-aspect-ratio-alt:before {
  content: '\f364';
}

.zmdi-aspect-ratio:before {
  content: '\f365';
}

.zmdi-blur-circular:before {
  content: '\f366';
}

.zmdi-blur-linear:before {
  content: '\f367';
}

.zmdi-blur-off:before {
  content: '\f368';
}

.zmdi-blur:before {
  content: '\f369';
}

.zmdi-brightness-2:before {
  content: '\f36a';
}

.zmdi-brightness-3:before {
  content: '\f36b';
}

.zmdi-brightness-4:before {
  content: '\f36c';
}

.zmdi-brightness-5:before {
  content: '\f36d';
}

.zmdi-brightness-6:before {
  content: '\f36e';
}

.zmdi-brightness-7:before {
  content: '\f36f';
}

.zmdi-brightness-auto:before {
  content: '\f370';
}

.zmdi-brightness-setting:before {
  content: '\f371';
}

.zmdi-broken-image:before {
  content: '\f372';
}

.zmdi-center-focus-strong:before {
  content: '\f373';
}

.zmdi-center-focus-weak:before {
  content: '\f374';
}

.zmdi-compare:before {
  content: '\f375';
}

.zmdi-crop-16-9:before {
  content: '\f376';
}

.zmdi-crop-3-2:before {
  content: '\f377';
}

.zmdi-crop-5-4:before {
  content: '\f378';
}

.zmdi-crop-7-5:before {
  content: '\f379';
}

.zmdi-crop-din:before {
  content: '\f37a';
}

.zmdi-crop-free:before {
  content: '\f37b';
}

.zmdi-crop-landscape:before {
  content: '\f37c';
}

.zmdi-crop-portrait:before {
  content: '\f37d';
}

.zmdi-crop-square:before {
  content: '\f37e';
}

.zmdi-exposure-alt:before {
  content: '\f37f';
}

.zmdi-exposure:before {
  content: '\f380';
}

.zmdi-filter-b-and-w:before {
  content: '\f381';
}

.zmdi-filter-center-focus:before {
  content: '\f382';
}

.zmdi-filter-frames:before {
  content: '\f383';
}

.zmdi-filter-tilt-shift:before {
  content: '\f384';
}

.zmdi-gradient:before {
  content: '\f385';
}

.zmdi-grain:before {
  content: '\f386';
}

.zmdi-graphic-eq:before {
  content: '\f387';
}

.zmdi-hdr-off:before {
  content: '\f388';
}

.zmdi-hdr-strong:before {
  content: '\f389';
}

.zmdi-hdr-weak:before {
  content: '\f38a';
}

.zmdi-hdr:before {
  content: '\f38b';
}

.zmdi-iridescent:before {
  content: '\f38c';
}

.zmdi-leak-off:before {
  content: '\f38d';
}

.zmdi-leak:before {
  content: '\f38e';
}

.zmdi-looks:before {
  content: '\f38f';
}

.zmdi-loupe:before {
  content: '\f390';
}

.zmdi-panorama-horizontal:before {
  content: '\f391';
}

.zmdi-panorama-vertical:before {
  content: '\f392';
}

.zmdi-panorama-wide-angle:before {
  content: '\f393';
}

.zmdi-photo-size-select-large:before {
  content: '\f394';
}

.zmdi-photo-size-select-small:before {
  content: '\f395';
}

.zmdi-picture-in-picture:before {
  content: '\f396';
}

.zmdi-slideshow:before {
  content: '\f397';
}

.zmdi-texture:before {
  content: '\f398';
}

.zmdi-tonality:before {
  content: '\f399';
}

.zmdi-vignette:before {
  content: '\f39a';
}

.zmdi-wb-auto:before {
  content: '\f39b';
}

.zmdi-eject-alt:before {
  content: '\f39c';
}

.zmdi-eject:before {
  content: '\f39d';
}

.zmdi-equalizer:before {
  content: '\f39e';
}

.zmdi-fast-forward:before {
  content: '\f39f';
}

.zmdi-fast-rewind:before {
  content: '\f3a0';
}

.zmdi-forward-10:before {
  content: '\f3a1';
}

.zmdi-forward-30:before {
  content: '\f3a2';
}

.zmdi-forward-5:before {
  content: '\f3a3';
}

.zmdi-hearing:before {
  content: '\f3a4';
}

.zmdi-pause-circle-outline:before {
  content: '\f3a5';
}

.zmdi-pause-circle:before {
  content: '\f3a6';
}

.zmdi-pause:before {
  content: '\f3a7';
}

.zmdi-play-circle-outline:before {
  content: '\f3a8';
}

.zmdi-play-circle:before {
  content: '\f3a9';
}

.zmdi-play:before {
  content: '\f3aa';
}

.zmdi-playlist-audio:before {
  content: '\f3ab';
}

.zmdi-playlist-plus:before {
  content: '\f3ac';
}

.zmdi-repeat-one:before {
  content: '\f3ad';
}

.zmdi-repeat:before {
  content: '\f3ae';
}

.zmdi-replay-10:before {
  content: '\f3af';
}

.zmdi-replay-30:before {
  content: '\f3b0';
}

.zmdi-replay-5:before {
  content: '\f3b1';
}

.zmdi-replay:before {
  content: '\f3b2';
}

.zmdi-shuffle:before {
  content: '\f3b3';
}

.zmdi-skip-next:before {
  content: '\f3b4';
}

.zmdi-skip-previous:before {
  content: '\f3b5';
}

.zmdi-stop:before {
  content: '\f3b6';
}

.zmdi-surround-sound:before {
  content: '\f3b7';
}

.zmdi-tune:before {
  content: '\f3b8';
}

.zmdi-volume-down:before {
  content: '\f3b9';
}

.zmdi-volume-mute:before {
  content: '\f3ba';
}

.zmdi-volume-off:before {
  content: '\f3bb';
}

.zmdi-volume-up:before {
  content: '\f3bc';
}

.zmdi-n-1-square:before {
  content: '\f3bd';
}

.zmdi-n-2-square:before {
  content: '\f3be';
}

.zmdi-n-3-square:before {
  content: '\f3bf';
}

.zmdi-n-4-square:before {
  content: '\f3c0';
}

.zmdi-n-5-square:before {
  content: '\f3c1';
}

.zmdi-n-6-square:before {
  content: '\f3c2';
}

.zmdi-neg-1:before {
  content: '\f3c3';
}

.zmdi-neg-2:before {
  content: '\f3c4';
}

.zmdi-plus-1:before {
  content: '\f3c5';
}

.zmdi-plus-2:before {
  content: '\f3c6';
}

.zmdi-sec-10:before {
  content: '\f3c7';
}

.zmdi-sec-3:before {
  content: '\f3c8';
}

.zmdi-zero:before {
  content: '\f3c9';
}

.zmdi-airline-seat-flat-angled:before {
  content: '\f3ca';
}

.zmdi-airline-seat-flat:before {
  content: '\f3cb';
}

.zmdi-airline-seat-individual-suite:before {
  content: '\f3cc';
}

.zmdi-airline-seat-legroom-extra:before {
  content: '\f3cd';
}

.zmdi-airline-seat-legroom-normal:before {
  content: '\f3ce';
}

.zmdi-airline-seat-legroom-reduced:before {
  content: '\f3cf';
}

.zmdi-airline-seat-recline-extra:before {
  content: '\f3d0';
}

.zmdi-airline-seat-recline-normal:before {
  content: '\f3d1';
}

.zmdi-airplay:before {
  content: '\f3d2';
}

.zmdi-closed-caption:before {
  content: '\f3d3';
}

.zmdi-confirmation-number:before {
  content: '\f3d4';
}

.zmdi-developer-board:before {
  content: '\f3d5';
}

.zmdi-disc-full:before {
  content: '\f3d6';
}

.zmdi-explicit:before {
  content: '\f3d7';
}

.zmdi-flight-land:before {
  content: '\f3d8';
}

.zmdi-flight-takeoff:before {
  content: '\f3d9';
}

.zmdi-flip-to-back:before {
  content: '\f3da';
}

.zmdi-flip-to-front:before {
  content: '\f3db';
}

.zmdi-group-work:before {
  content: '\f3dc';
}

.zmdi-hd:before {
  content: '\f3dd';
}

.zmdi-hq:before {
  content: '\f3de';
}

.zmdi-markunread-mailbox:before {
  content: '\f3df';
}

.zmdi-memory:before {
  content: '\f3e0';
}

.zmdi-nfc:before {
  content: '\f3e1';
}

.zmdi-play-for-work:before {
  content: '\f3e2';
}

.zmdi-power-input:before {
  content: '\f3e3';
}

.zmdi-present-to-all:before {
  content: '\f3e4';
}

.zmdi-satellite:before {
  content: '\f3e5';
}

.zmdi-tap-and-play:before {
  content: '\f3e6';
}

.zmdi-vibration:before {
  content: '\f3e7';
}

.zmdi-voicemail:before {
  content: '\f3e8';
}

.zmdi-group:before {
  content: '\f3e9';
}

.zmdi-rss:before {
  content: '\f3ea';
}

.zmdi-shape:before {
  content: '\f3eb';
}

.zmdi-spinner:before {
  content: '\f3ec';
}

.zmdi-ungroup:before {
  content: '\f3ed';
}

.zmdi-500px:before {
  content: '\f3ee';
}

.zmdi-8tracks:before {
  content: '\f3ef';
}

.zmdi-amazon:before {
  content: '\f3f0';
}

.zmdi-blogger:before {
  content: '\f3f1';
}

.zmdi-delicious:before {
  content: '\f3f2';
}

.zmdi-disqus:before {
  content: '\f3f3';
}

.zmdi-flattr:before {
  content: '\f3f4';
}

.zmdi-flickr:before {
  content: '\f3f5';
}

.zmdi-github-alt:before {
  content: '\f3f6';
}

.zmdi-google-old:before {
  content: '\f3f7';
}

.zmdi-linkedin:before {
  content: '\f3f8';
}

.zmdi-odnoklassniki:before {
  content: '\f3f9';
}

.zmdi-outlook:before {
  content: '\f3fa';
}

.zmdi-paypal-alt:before {
  content: '\f3fb';
}

.zmdi-pinterest:before {
  content: '\f3fc';
}

.zmdi-playstation:before {
  content: '\f3fd';
}

.zmdi-reddit:before {
  content: '\f3fe';
}

.zmdi-skype:before {
  content: '\f3ff';
}

.zmdi-slideshare:before {
  content: '\f400';
}

.zmdi-soundcloud:before {
  content: '\f401';
}

.zmdi-tumblr:before {
  content: '\f402';
}

.zmdi-twitch:before {
  content: '\f403';
}

.zmdi-vimeo:before {
  content: '\f404';
}

.zmdi-whatsapp:before {
  content: '\f405';
}

.zmdi-xbox:before {
  content: '\f406';
}

.zmdi-yahoo:before {
  content: '\f407';
}

.zmdi-youtube-play:before {
  content: '\f408';
}

.zmdi-youtube:before {
  content: '\f409';
}

.zmdi-3d-rotation:before {
  content: '\f101';
}

.zmdi-airplane-off:before {
  content: '\f102';
}

.zmdi-airplane:before {
  content: '\f103';
}

.zmdi-album:before {
  content: '\f104';
}

.zmdi-archive:before {
  content: '\f105';
}

.zmdi-assignment-account:before {
  content: '\f106';
}

.zmdi-assignment-alert:before {
  content: '\f107';
}

.zmdi-assignment-check:before {
  content: '\f108';
}

.zmdi-assignment-o:before {
  content: '\f109';
}

.zmdi-assignment-return:before {
  content: '\f10a';
}

.zmdi-assignment-returned:before {
  content: '\f10b';
}

.zmdi-assignment:before {
  content: '\f10c';
}

.zmdi-attachment-alt:before {
  content: '\f10d';
}

.zmdi-attachment:before {
  content: '\f10e';
}

.zmdi-audio:before {
  content: '\f10f';
}

.zmdi-badge-check:before {
  content: '\f110';
}

.zmdi-balance-wallet:before {
  content: '\f111';
}

.zmdi-balance:before {
  content: '\f112';
}

.zmdi-battery-alert:before {
  content: '\f113';
}

.zmdi-battery-flash:before {
  content: '\f114';
}

.zmdi-battery-unknown:before {
  content: '\f115';
}

.zmdi-battery:before {
  content: '\f116';
}

.zmdi-bike:before {
  content: '\f117';
}

.zmdi-block-alt:before {
  content: '\f118';
}

.zmdi-block:before {
  content: '\f119';
}

.zmdi-boat:before {
  content: '\f11a';
}

.zmdi-book-image:before {
  content: '\f11b';
}

.zmdi-book:before {
  content: '\f11c';
}

.zmdi-bookmark-outline:before {
  content: '\f11d';
}

.zmdi-bookmark:before {
  content: '\f11e';
}

.zmdi-brush:before {
  content: '\f11f';
}

.zmdi-bug:before {
  content: '\f120';
}

.zmdi-bus:before {
  content: '\f121';
}

.zmdi-cake:before {
  content: '\f122';
}

.zmdi-car-taxi:before {
  content: '\f123';
}

.zmdi-car-wash:before {
  content: '\f124';
}

.zmdi-car:before {
  content: '\f125';
}

.zmdi-card-giftcard:before {
  content: '\f126';
}

.zmdi-card-membership:before {
  content: '\f127';
}

.zmdi-card-travel:before {
  content: '\f128';
}

.zmdi-card:before {
  content: '\f129';
}

.zmdi-case-check:before {
  content: '\f12a';
}

.zmdi-case-download:before {
  content: '\f12b';
}

.zmdi-case-play:before {
  content: '\f12c';
}

.zmdi-case:before {
  content: '\f12d';
}

.zmdi-cast-connected:before {
  content: '\f12e';
}

.zmdi-cast:before {
  content: '\f12f';
}

.zmdi-chart-donut:before {
  content: '\f130';
}

.zmdi-chart:before {
  content: '\f131';
}

.zmdi-city-alt:before {
  content: '\f132';
}

.zmdi-city:before {
  content: '\f133';
}

.zmdi-close-circle-o:before {
  content: '\f134';
}

.zmdi-close-circle:before {
  content: '\f135';
}

.zmdi-close:before {
  content: '\f136';
}

.zmdi-cocktail:before {
  content: '\f137';
}

.zmdi-code-setting:before {
  content: '\f138';
}

.zmdi-code-smartphone:before {
  content: '\f139';
}

.zmdi-code:before {
  content: '\f13a';
}

.zmdi-coffee:before {
  content: '\f13b';
}

.zmdi-collection-bookmark:before {
  content: '\f13c';
}

.zmdi-collection-case-play:before {
  content: '\f13d';
}

.zmdi-collection-folder-image:before {
  content: '\f13e';
}

.zmdi-collection-image-o:before {
  content: '\f13f';
}

.zmdi-collection-image:before {
  content: '\f140';
}

.zmdi-collection-item-1:before {
  content: '\f141';
}

.zmdi-collection-item-2:before {
  content: '\f142';
}

.zmdi-collection-item-3:before {
  content: '\f143';
}

.zmdi-collection-item-4:before {
  content: '\f144';
}

.zmdi-collection-item-5:before {
  content: '\f145';
}

.zmdi-collection-item-6:before {
  content: '\f146';
}

.zmdi-collection-item-7:before {
  content: '\f147';
}

.zmdi-collection-item-8:before {
  content: '\f148';
}

.zmdi-collection-item-9-plus:before {
  content: '\f149';
}

.zmdi-collection-item-9:before {
  content: '\f14a';
}

.zmdi-collection-item:before {
  content: '\f14b';
}

.zmdi-collection-music:before {
  content: '\f14c';
}

.zmdi-collection-pdf:before {
  content: '\f14d';
}

.zmdi-collection-plus:before {
  content: '\f14e';
}

.zmdi-collection-speaker:before {
  content: '\f14f';
}

.zmdi-collection-text:before {
  content: '\f150';
}

.zmdi-collection-video:before {
  content: '\f151';
}

.zmdi-compass:before {
  content: '\f152';
}

.zmdi-cutlery:before {
  content: '\f153';
}

.zmdi-delete:before {
  content: '\f154';
}

.zmdi-dialpad:before {
  content: '\f155';
}

.zmdi-dns:before {
  content: '\f156';
}

.zmdi-drink:before {
  content: '\f157';
}

.zmdi-edit:before {
  content: '\f158';
}

.zmdi-email-open:before {
  content: '\f159';
}

.zmdi-email:before {
  content: '\f15a';
}

.zmdi-eye-off:before {
  content: '\f15b';
}

.zmdi-eye:before {
  content: '\f15c';
}

.zmdi-eyedropper:before {
  content: '\f15d';
}

.zmdi-favorite-outline:before {
  content: '\f15e';
}

.zmdi-favorite:before {
  content: '\f15f';
}

.zmdi-filter-list:before {
  content: '\f160';
}

.zmdi-fire:before {
  content: '\f161';
}

.zmdi-flag:before {
  content: '\f162';
}

.zmdi-flare:before {
  content: '\f163';
}

.zmdi-flash-auto:before {
  content: '\f164';
}

.zmdi-flash-off:before {
  content: '\f165';
}

.zmdi-flash:before {
  content: '\f166';
}

.zmdi-flip:before {
  content: '\f167';
}

.zmdi-flower-alt:before {
  content: '\f168';
}

.zmdi-flower:before {
  content: '\f169';
}

.zmdi-font:before {
  content: '\f16a';
}

.zmdi-fullscreen-alt:before {
  content: '\f16b';
}

.zmdi-fullscreen-exit:before {
  content: '\f16c';
}

.zmdi-fullscreen:before {
  content: '\f16d';
}

.zmdi-functions:before {
  content: '\f16e';
}

.zmdi-gas-station:before {
  content: '\f16f';
}

.zmdi-gesture:before {
  content: '\f170';
}

.zmdi-globe-alt:before {
  content: '\f171';
}

.zmdi-globe-lock:before {
  content: '\f172';
}

.zmdi-globe:before {
  content: '\f173';
}

.zmdi-graduation-cap:before {
  content: '\f174';
}

.zmdi-home:before {
  content: '\f175';
}

.zmdi-hospital-alt:before {
  content: '\f176';
}

.zmdi-hospital:before {
  content: '\f177';
}

.zmdi-hotel:before {
  content: '\f178';
}

.zmdi-hourglass-alt:before {
  content: '\f179';
}

.zmdi-hourglass-outline:before {
  content: '\f17a';
}

.zmdi-hourglass:before {
  content: '\f17b';
}

.zmdi-http:before {
  content: '\f17c';
}

.zmdi-image-alt:before {
  content: '\f17d';
}

.zmdi-image-o:before {
  content: '\f17e';
}

.zmdi-image:before {
  content: '\f17f';
}

.zmdi-inbox:before {
  content: '\f180';
}

.zmdi-invert-colors-off:before {
  content: '\f181';
}

.zmdi-invert-colors:before {
  content: '\f182';
}

.zmdi-key:before {
  content: '\f183';
}

.zmdi-label-alt-outline:before {
  content: '\f184';
}

.zmdi-label-alt:before {
  content: '\f185';
}

.zmdi-label-heart:before {
  content: '\f186';
}

.zmdi-label:before {
  content: '\f187';
}

.zmdi-labels:before {
  content: '\f188';
}

.zmdi-lamp:before {
  content: '\f189';
}

.zmdi-landscape:before {
  content: '\f18a';
}

.zmdi-layers-off:before {
  content: '\f18b';
}

.zmdi-layers:before {
  content: '\f18c';
}

.zmdi-library:before {
  content: '\f18d';
}

.zmdi-link:before {
  content: '\f18e';
}

.zmdi-lock-open:before {
  content: '\f18f';
}

.zmdi-lock-outline:before {
  content: '\f190';
}

.zmdi-lock:before {
  content: '\f191';
}

.zmdi-mail-reply-all:before {
  content: '\f192';
}

.zmdi-mail-reply:before {
  content: '\f193';
}

.zmdi-mail-send:before {
  content: '\f194';
}

.zmdi-mall:before {
  content: '\f195';
}

.zmdi-map:before {
  content: '\f196';
}

.zmdi-menu:before {
  content: '\f197';
}

.zmdi-money-box:before {
  content: '\f198';
}

.zmdi-money-off:before {
  content: '\f199';
}

.zmdi-money:before {
  content: '\f19a';
}

.zmdi-more-vert:before {
  content: '\f19b';
}

.zmdi-more:before {
  content: '\f19c';
}

.zmdi-movie-alt:before {
  content: '\f19d';
}

.zmdi-movie:before {
  content: '\f19e';
}

.zmdi-nature-people:before {
  content: '\f19f';
}

.zmdi-nature:before {
  content: '\f1a0';
}

.zmdi-navigation:before {
  content: '\f1a1';
}

.zmdi-open-in-browser:before {
  content: '\f1a2';
}

.zmdi-open-in-new:before {
  content: '\f1a3';
}

.zmdi-palette:before {
  content: '\f1a4';
}

.zmdi-parking:before {
  content: '\f1a5';
}

.zmdi-pin-account:before {
  content: '\f1a6';
}

.zmdi-pin-assistant:before {
  content: '\f1a7';
}

.zmdi-pin-drop:before {
  content: '\f1a8';
}

.zmdi-pin-help:before {
  content: '\f1a9';
}

.zmdi-pin-off:before {
  content: '\f1aa';
}

.zmdi-pin:before {
  content: '\f1ab';
}

.zmdi-pizza:before {
  content: '\f1ac';
}

.zmdi-plaster:before {
  content: '\f1ad';
}

.zmdi-power-setting:before {
  content: '\f1ae';
}

.zmdi-power:before {
  content: '\f1af';
}

.zmdi-print:before {
  content: '\f1b0';
}

.zmdi-puzzle-piece:before {
  content: '\f1b1';
}

.zmdi-quote:before {
  content: '\f1b2';
}

.zmdi-railway:before {
  content: '\f1b3';
}

.zmdi-receipt:before {
  content: '\f1b4';
}

.zmdi-refresh-alt:before {
  content: '\f1b5';
}

.zmdi-refresh-sync-alert:before {
  content: '\f1b6';
}

.zmdi-refresh-sync-off:before {
  content: '\f1b7';
}

.zmdi-refresh-sync:before {
  content: '\f1b8';
}

.zmdi-refresh:before {
  content: '\f1b9';
}

.zmdi-roller:before {
  content: '\f1ba';
}

.zmdi-ruler:before {
  content: '\f1bb';
}

.zmdi-scissors:before {
  content: '\f1bc';
}

.zmdi-screen-rotation-lock:before {
  content: '\f1bd';
}

.zmdi-screen-rotation:before {
  content: '\f1be';
}

.zmdi-search-for:before {
  content: '\f1bf';
}

.zmdi-search-in-file:before {
  content: '\f1c0';
}

.zmdi-search-in-page:before {
  content: '\f1c1';
}

.zmdi-search-replace:before {
  content: '\f1c2';
}

.zmdi-search:before {
  content: '\f1c3';
}

.zmdi-seat:before {
  content: '\f1c4';
}

.zmdi-settings-square:before {
  content: '\f1c5';
}

.zmdi-settings:before {
  content: '\f1c6';
}

.zmdi-shield-check:before {
  content: '\f1c7';
}

.zmdi-shield-security:before {
  content: '\f1c8';
}

.zmdi-shopping-basket:before {
  content: '\f1c9';
}

.zmdi-shopping-cart-plus:before {
  content: '\f1ca';
}

.zmdi-shopping-cart:before {
  content: '\f1cb';
}

.zmdi-sign-in:before {
  content: '\f1cc';
}

.zmdi-sort-amount-asc:before {
  content: '\f1cd';
}

.zmdi-sort-amount-desc:before {
  content: '\f1ce';
}

.zmdi-sort-asc:before {
  content: '\f1cf';
}

.zmdi-sort-desc:before {
  content: '\f1d0';
}

.zmdi-spellcheck:before {
  content: '\f1d1';
}

.zmdi-storage:before {
  content: '\f1d2';
}

.zmdi-store-24:before {
  content: '\f1d3';
}

.zmdi-store:before {
  content: '\f1d4';
}

.zmdi-subway:before {
  content: '\f1d5';
}

.zmdi-sun:before {
  content: '\f1d6';
}

.zmdi-tab-unselected:before {
  content: '\f1d7';
}

.zmdi-tab:before {
  content: '\f1d8';
}

.zmdi-tag-close:before {
  content: '\f1d9';
}

.zmdi-tag-more:before {
  content: '\f1da';
}

.zmdi-tag:before {
  content: '\f1db';
}

.zmdi-thumb-down:before {
  content: '\f1dc';
}

.zmdi-thumb-up-down:before {
  content: '\f1dd';
}

.zmdi-thumb-up:before {
  content: '\f1de';
}

.zmdi-ticket-star:before {
  content: '\f1df';
}

.zmdi-toll:before {
  content: '\f1e0';
}

.zmdi-toys:before {
  content: '\f1e1';
}

.zmdi-traffic:before {
  content: '\f1e2';
}

.zmdi-translate:before {
  content: '\f1e3';
}

.zmdi-triangle-down:before {
  content: '\f1e4';
}

.zmdi-triangle-up:before {
  content: '\f1e5';
}

.zmdi-truck:before {
  content: '\f1e6';
}

.zmdi-turning-sign:before {
  content: '\f1e7';
}

.zmdi-wallpaper:before {
  content: '\f1e8';
}

.zmdi-washing-machine:before {
  content: '\f1e9';
}

.zmdi-window-maximize:before {
  content: '\f1ea';
}

.zmdi-window-minimize:before {
  content: '\f1eb';
}

.zmdi-window-restore:before {
  content: '\f1ec';
}

.zmdi-wrench:before {
  content: '\f1ed';
}

.zmdi-zoom-in:before {
  content: '\f1ee';
}

.zmdi-zoom-out:before {
  content: '\f1ef';
}

.zmdi-alert-circle-o:before {
  content: '\f1f0';
}

.zmdi-alert-circle:before {
  content: '\f1f1';
}

.zmdi-alert-octagon:before {
  content: '\f1f2';
}

.zmdi-alert-polygon:before {
  content: '\f1f3';
}

.zmdi-alert-triangle:before {
  content: '\f1f4';
}

.zmdi-help-outline:before {
  content: '\f1f5';
}

.zmdi-help:before {
  content: '\f1f6';
}

.zmdi-info-outline:before {
  content: '\f1f7';
}

.zmdi-info:before {
  content: '\f1f8';
}

.zmdi-notifications-active:before {
  content: '\f1f9';
}

.zmdi-notifications-add:before {
  content: '\f1fa';
}

.zmdi-notifications-none:before {
  content: '\f1fb';
}

.zmdi-notifications-off:before {
  content: '\f1fc';
}

.zmdi-notifications-paused:before {
  content: '\f1fd';
}

.zmdi-notifications:before {
  content: '\f1fe';
}

.zmdi-account-add:before {
  content: '\f1ff';
}

.zmdi-account-box-mail:before {
  content: '\f200';
}

.zmdi-account-box-o:before {
  content: '\f201';
}

.zmdi-account-box-phone:before {
  content: '\f202';
}

.zmdi-account-box:before {
  content: '\f203';
}

.zmdi-account-calendar:before {
  content: '\f204';
}

.zmdi-account-circle:before {
  content: '\f205';
}

.zmdi-account-o:before {
  content: '\f206';
}

.zmdi-account:before {
  content: '\f207';
}

.zmdi-accounts-add:before {
  content: '\f208';
}

.zmdi-accounts-alt:before {
  content: '\f209';
}

.zmdi-accounts-list-alt:before {
  content: '\f20a';
}

.zmdi-accounts-list:before {
  content: '\f20b';
}

.zmdi-accounts-outline:before {
  content: '\f20c';
}

.zmdi-accounts:before {
  content: '\f20d';
}

.zmdi-face:before {
  content: '\f20e';
}

.zmdi-female:before {
  content: '\f20f';
}

.zmdi-male-alt:before {
  content: '\f210';
}

.zmdi-male-female:before {
  content: '\f211';
}

.zmdi-male:before {
  content: '\f212';
}

.zmdi-mood-bad:before {
  content: '\f213';
}

.zmdi-mood:before {
  content: '\f214';
}

.zmdi-run:before {
  content: '\f215';
}

.zmdi-walk:before {
  content: '\f216';
}

.zmdi-cloud-box:before {
  content: '\f217';
}

.zmdi-cloud-circle:before {
  content: '\f218';
}

.zmdi-cloud-done:before {
  content: '\f219';
}

.zmdi-cloud-download:before {
  content: '\f21a';
}

.zmdi-cloud-off:before {
  content: '\f21b';
}

.zmdi-cloud-outline-alt:before {
  content: '\f21c';
}

.zmdi-cloud-outline:before {
  content: '\f21d';
}

.zmdi-cloud-upload:before {
  content: '\f21e';
}

.zmdi-cloud:before {
  content: '\f21f';
}

.zmdi-download:before {
  content: '\f220';
}

.zmdi-file-plus:before {
  content: '\f221';
}

.zmdi-file-text:before {
  content: '\f222';
}

.zmdi-file:before {
  content: '\f223';
}

.zmdi-folder-outline:before {
  content: '\f224';
}

.zmdi-folder-person:before {
  content: '\f225';
}

.zmdi-folder-star-alt:before {
  content: '\f226';
}

.zmdi-folder-star:before {
  content: '\f227';
}

.zmdi-folder:before {
  content: '\f228';
}

.zmdi-gif:before {
  content: '\f229';
}

.zmdi-upload:before {
  content: '\f22a';
}

.zmdi-border-all:before {
  content: '\f22b';
}

.zmdi-border-bottom:before {
  content: '\f22c';
}

.zmdi-border-clear:before {
  content: '\f22d';
}

.zmdi-border-color:before {
  content: '\f22e';
}

.zmdi-border-horizontal:before {
  content: '\f22f';
}

.zmdi-border-inner:before {
  content: '\f230';
}

.zmdi-border-left:before {
  content: '\f231';
}

.zmdi-border-outer:before {
  content: '\f232';
}

.zmdi-border-right:before {
  content: '\f233';
}

.zmdi-border-style:before {
  content: '\f234';
}

.zmdi-border-top:before {
  content: '\f235';
}

.zmdi-border-vertical:before {
  content: '\f236';
}

.zmdi-copy:before {
  content: '\f237';
}

.zmdi-crop:before {
  content: '\f238';
}

.zmdi-format-align-center:before {
  content: '\f239';
}

.zmdi-format-align-justify:before {
  content: '\f23a';
}

.zmdi-format-align-left:before {
  content: '\f23b';
}

.zmdi-format-align-right:before {
  content: '\f23c';
}

.zmdi-format-bold:before {
  content: '\f23d';
}

.zmdi-format-clear-all:before {
  content: '\f23e';
}

.zmdi-format-clear:before {
  content: '\f23f';
}

.zmdi-format-color-fill:before {
  content: '\f240';
}

.zmdi-format-color-reset:before {
  content: '\f241';
}

.zmdi-format-color-text:before {
  content: '\f242';
}

.zmdi-format-indent-decrease:before {
  content: '\f243';
}

.zmdi-format-indent-increase:before {
  content: '\f244';
}

.zmdi-format-italic:before {
  content: '\f245';
}

.zmdi-format-line-spacing:before {
  content: '\f246';
}

.zmdi-format-list-bulleted:before {
  content: '\f247';
}

.zmdi-format-list-numbered:before {
  content: '\f248';
}

.zmdi-format-ltr:before {
  content: '\f249';
}

.zmdi-format-rtl:before {
  content: '\f24a';
}

.zmdi-format-size:before {
  content: '\f24b';
}

.zmdi-format-strikethrough-s:before {
  content: '\f24c';
}

.zmdi-format-strikethrough:before {
  content: '\f24d';
}

.zmdi-format-subject:before {
  content: '\f24e';
}

.zmdi-format-underlined:before {
  content: '\f24f';
}

.zmdi-format-valign-bottom:before {
  content: '\f250';
}

.zmdi-format-valign-center:before {
  content: '\f251';
}

.zmdi-format-valign-top:before {
  content: '\f252';
}

.zmdi-redo:before {
  content: '\f253';
}

.zmdi-select-all:before {
  content: '\f254';
}

.zmdi-space-bar:before {
  content: '\f255';
}

.zmdi-text-format:before {
  content: '\f256';
}

.zmdi-transform:before {
  content: '\f257';
}

.zmdi-undo:before {
  content: '\f258';
}

.zmdi-wrap-text:before {
  content: '\f259';
}

.zmdi-comment-alert:before {
  content: '\f25a';
}

.zmdi-comment-alt-text:before {
  content: '\f25b';
}

.zmdi-comment-alt:before {
  content: '\f25c';
}

.zmdi-comment-edit:before {
  content: '\f25d';
}

.zmdi-comment-image:before {
  content: '\f25e';
}

.zmdi-comment-list:before {
  content: '\f25f';
}

.zmdi-comment-more:before {
  content: '\f260';
}

.zmdi-comment-outline:before {
  content: '\f261';
}

.zmdi-comment-text-alt:before {
  content: '\f262';
}

.zmdi-comment-text:before {
  content: '\f263';
}

.zmdi-comment-video:before {
  content: '\f264';
}

.zmdi-comment:before {
  content: '\f265';
}

.zmdi-comments:before {
  content: '\f266';
}

.zmdi-check-all:before {
  content: '\f267';
}

.zmdi-check-circle-u:before {
  content: '\f268';
}

.zmdi-check-circle:before {
  content: '\f269';
}

.zmdi-check-square:before {
  content: '\f26a';
}

.zmdi-check:before {
  content: '\f26b';
}

.zmdi-circle-o:before {
  content: '\f26c';
}

.zmdi-circle:before {
  content: '\f26d';
}

.zmdi-dot-circle-alt:before {
  content: '\f26e';
}

.zmdi-dot-circle:before {
  content: '\f26f';
}

.zmdi-minus-circle-outline:before {
  content: '\f270';
}

.zmdi-minus-circle:before {
  content: '\f271';
}

.zmdi-minus-square:before {
  content: '\f272';
}

.zmdi-minus:before {
  content: '\f273';
}

.zmdi-plus-circle-o-duplicate:before {
  content: '\f274';
}

.zmdi-plus-circle-o:before {
  content: '\f275';
}

.zmdi-plus-circle:before {
  content: '\f276';
}

.zmdi-plus-square:before {
  content: '\f277';
}

.zmdi-plus:before {
  content: '\f278';
}

.zmdi-square-o:before {
  content: '\f279';
}

.zmdi-star-circle:before {
  content: '\f27a';
}

.zmdi-star-half:before {
  content: '\f27b';
}

.zmdi-star-outline:before {
  content: '\f27c';
}

.zmdi-star:before {
  content: '\f27d';
}

.zmdi-bluetooth-connected:before {
  content: '\f27e';
}

.zmdi-bluetooth-off:before {
  content: '\f27f';
}

.zmdi-bluetooth-search:before {
  content: '\f280';
}

.zmdi-bluetooth-setting:before {
  content: '\f281';
}

.zmdi-bluetooth:before {
  content: '\f282';
}

.zmdi-camera-add:before {
  content: '\f283';
}

.zmdi-camera-alt:before {
  content: '\f284';
}

.zmdi-camera-bw:before {
  content: '\f285';
}

.zmdi-camera-front:before {
  content: '\f286';
}

.zmdi-camera-mic:before {
  content: '\f287';
}

.zmdi-camera-party-mode:before {
  content: '\f288';
}

.zmdi-camera-rear:before {
  content: '\f289';
}

.zmdi-camera-roll:before {
  content: '\f28a';
}

.zmdi-camera-switch:before {
  content: '\f28b';
}

.zmdi-camera:before {
  content: '\f28c';
}

.zmdi-card-alert:before {
  content: '\f28d';
}

.zmdi-card-off:before {
  content: '\f28e';
}

.zmdi-card-sd:before {
  content: '\f28f';
}

.zmdi-card-sim:before {
  content: '\f290';
}

.zmdi-desktop-mac:before {
  content: '\f291';
}

.zmdi-desktop-windows:before {
  content: '\f292';
}

.zmdi-device-hub:before {
  content: '\f293';
}

.zmdi-devices-off:before {
  content: '\f294';
}

.zmdi-devices:before {
  content: '\f295';
}

.zmdi-dock:before {
  content: '\f296';
}

.zmdi-floppy:before {
  content: '\f297';
}

.zmdi-gamepad:before {
  content: '\f298';
}

.zmdi-gps-dot:before {
  content: '\f299';
}

.zmdi-gps-off:before {
  content: '\f29a';
}

.zmdi-gps:before {
  content: '\f29b';
}

.zmdi-headset-mic:before {
  content: '\f29c';
}

.zmdi-headset:before {
  content: '\f29d';
}

.zmdi-input-antenna:before {
  content: '\f29e';
}

.zmdi-input-composite:before {
  content: '\f29f';
}

.zmdi-input-hdmi:before {
  content: '\f2a0';
}

.zmdi-input-power:before {
  content: '\f2a1';
}

.zmdi-input-svideo:before {
  content: '\f2a2';
}

.zmdi-keyboard-hide:before {
  content: '\f2a3';
}

.zmdi-keyboard:before {
  content: '\f2a4';
}

.zmdi-laptop-chromebook:before {
  content: '\f2a5';
}

.zmdi-laptop-mac:before {
  content: '\f2a6';
}

.zmdi-laptop:before {
  content: '\f2a7';
}

.zmdi-mic-off:before {
  content: '\f2a8';
}

.zmdi-mic-outline:before {
  content: '\f2a9';
}

.zmdi-mic-setting:before {
  content: '\f2aa';
}

.zmdi-mic:before {
  content: '\f2ab';
}

.zmdi-mouse:before {
  content: '\f2ac';
}

.zmdi-network-alert:before {
  content: '\f2ad';
}

.zmdi-network-locked:before {
  content: '\f2ae';
}

.zmdi-network-off:before {
  content: '\f2af';
}

.zmdi-network-outline:before {
  content: '\f2b0';
}

.zmdi-network-setting:before {
  content: '\f2b1';
}

.zmdi-network:before {
  content: '\f2b2';
}

.zmdi-phone-bluetooth:before {
  content: '\f2b3';
}

.zmdi-phone-end:before {
  content: '\f2b4';
}

.zmdi-phone-forwarded:before {
  content: '\f2b5';
}

.zmdi-phone-in-talk:before {
  content: '\f2b6';
}

.zmdi-phone-locked:before {
  content: '\f2b7';
}

.zmdi-phone-missed:before {
  content: '\f2b8';
}

.zmdi-phone-msg:before {
  content: '\f2b9';
}

.zmdi-phone-paused:before {
  content: '\f2ba';
}

.zmdi-phone-ring:before {
  content: '\f2bb';
}

.zmdi-phone-setting:before {
  content: '\f2bc';
}

.zmdi-phone-sip:before {
  content: '\f2bd';
}

.zmdi-phone:before {
  content: '\f2be';
}

.zmdi-portable-wifi-changes:before {
  content: '\f2bf';
}

.zmdi-portable-wifi-off:before {
  content: '\f2c0';
}

.zmdi-portable-wifi:before {
  content: '\f2c1';
}

.zmdi-radio:before {
  content: '\f2c2';
}

.zmdi-reader:before {
  content: '\f2c3';
}

.zmdi-remote-control-alt:before {
  content: '\f2c4';
}

.zmdi-remote-control:before {
  content: '\f2c5';
}

.zmdi-router:before {
  content: '\f2c6';
}

.zmdi-scanner:before {
  content: '\f2c7';
}

.zmdi-smartphone-android:before {
  content: '\f2c8';
}

.zmdi-smartphone-download:before {
  content: '\f2c9';
}

.zmdi-smartphone-erase:before {
  content: '\f2ca';
}

.zmdi-smartphone-info:before {
  content: '\f2cb';
}

.zmdi-smartphone-iphone:before {
  content: '\f2cc';
}

.zmdi-smartphone-landscape-lock:before {
  content: '\f2cd';
}

.zmdi-smartphone-landscape:before {
  content: '\f2ce';
}

.zmdi-smartphone-lock:before {
  content: '\f2cf';
}

.zmdi-smartphone-portrait-lock:before {
  content: '\f2d0';
}

.zmdi-smartphone-ring:before {
  content: '\f2d1';
}

.zmdi-smartphone-setting:before {
  content: '\f2d2';
}

.zmdi-smartphone-setup:before {
  content: '\f2d3';
}

.zmdi-smartphone:before {
  content: '\f2d4';
}

.zmdi-speaker:before {
  content: '\f2d5';
}

.zmdi-tablet-android:before {
  content: '\f2d6';
}

.zmdi-tablet-mac:before {
  content: '\f2d7';
}

.zmdi-tablet:before {
  content: '\f2d8';
}

.zmdi-tv-alt-play:before {
  content: '\f2d9';
}

.zmdi-tv-list:before {
  content: '\f2da';
}

.zmdi-tv-play:before {
  content: '\f2db';
}

.zmdi-tv:before {
  content: '\f2dc';
}

.zmdi-usb:before {
  content: '\f2dd';
}

.zmdi-videocam-off:before {
  content: '\f2de';
}

.zmdi-videocam-switch:before {
  content: '\f2df';
}

.zmdi-videocam:before {
  content: '\f2e0';
}

.zmdi-watch:before {
  content: '\f2e1';
}

.zmdi-wifi-alt-2:before {
  content: '\f2e2';
}

.zmdi-wifi-alt:before {
  content: '\f2e3';
}

.zmdi-wifi-info:before {
  content: '\f2e4';
}

.zmdi-wifi-lock:before {
  content: '\f2e5';
}

.zmdi-wifi-off:before {
  content: '\f2e6';
}

.zmdi-wifi-outline:before {
  content: '\f2e7';
}

.zmdi-wifi:before {
  content: '\f2e8';
}

.zmdi-arrow-left-bottom:before {
  content: '\f2e9';
}

.zmdi-arrow-left:before {
  content: '\f2ea';
}

.zmdi-arrow-merge:before {
  content: '\f2eb';
}

.zmdi-arrow-missed:before {
  content: '\f2ec';
}

.zmdi-arrow-right-top:before {
  content: '\f2ed';
}

.zmdi-arrow-right:before {
  content: '\f2ee';
}

.zmdi-arrow-split:before {
  content: '\f2ef';
}

.zmdi-arrows:before {
  content: '\f2f0';
}

.zmdi-caret-down-circle:before {
  content: '\f2f1';
}

.zmdi-caret-down:before {
  content: '\f2f2';
}

.zmdi-caret-left-circle:before {
  content: '\f2f3';
}

.zmdi-caret-left:before {
  content: '\f2f4';
}

.zmdi-caret-right-circle:before {
  content: '\f2f5';
}

.zmdi-caret-right:before {
  content: '\f2f6';
}

.zmdi-caret-up-circle:before {
  content: '\f2f7';
}

.zmdi-caret-up:before {
  content: '\f2f8';
}

.zmdi-chevron-down:before {
  content: '\f2f9';
}

.zmdi-chevron-left:before {
  content: '\f2fa';
}

.zmdi-chevron-right:before {
  content: '\f2fb';
}

.zmdi-chevron-up:before {
  content: '\f2fc';
}

.zmdi-forward:before {
  content: '\f2fd';
}

.zmdi-long-arrow-down:before {
  content: '\f2fe';
}

.zmdi-long-arrow-left:before {
  content: '\f2ff';
}

.zmdi-long-arrow-return:before {
  content: '\f300';
}

.zmdi-long-arrow-right:before {
  content: '\f301';
}

.zmdi-long-arrow-tab:before {
  content: '\f302';
}

.zmdi-long-arrow-up:before {
  content: '\f303';
}

.zmdi-rotate-ccw:before {
  content: '\f304';
}

.zmdi-rotate-cw:before {
  content: '\f305';
}

.zmdi-rotate-left:before {
  content: '\f306';
}

.zmdi-rotate-right:before {
  content: '\f307';
}

.zmdi-square-down:before {
  content: '\f308';
}

.zmdi-square-right:before {
  content: '\f309';
}

.zmdi-swap-alt:before {
  content: '\f30a';
}

.zmdi-swap-vertical-circle:before {
  content: '\f30b';
}

.zmdi-swap-vertical:before {
  content: '\f30c';
}

.zmdi-swap:before {
  content: '\f30d';
}

.zmdi-trending-down:before {
  content: '\f30e';
}

.zmdi-trending-flat:before {
  content: '\f30f';
}

.zmdi-trending-up:before {
  content: '\f310';
}

.zmdi-unfold-less:before {
  content: '\f311';
}

.zmdi-unfold-more:before {
  content: '\f312';
}

.zmdi-apps:before {
  content: '\f313';
}

.zmdi-grid-off:before {
  content: '\f314';
}

.zmdi-grid:before {
  content: '\f315';
}

.zmdi-view-agenda:before {
  content: '\f316';
}

.zmdi-view-array:before {
  content: '\f317';
}

.zmdi-view-carousel:before {
  content: '\f318';
}

.zmdi-view-column:before {
  content: '\f319';
}

.zmdi-view-comfy:before {
  content: '\f31a';
}

.zmdi-view-compact:before {
  content: '\f31b';
}

.zmdi-view-dashboard:before {
  content: '\f31c';
}

.zmdi-view-day:before {
  content: '\f31d';
}

.zmdi-view-headline:before {
  content: '\f31e';
}

.zmdi-view-list-alt:before {
  content: '\f31f';
}

.zmdi-view-list:before {
  content: '\f320';
}

.zmdi-view-module:before {
  content: '\f321';
}

.zmdi-view-quilt:before {
  content: '\f322';
}

.zmdi-view-stream:before {
  content: '\f323';
}

.zmdi-view-subtitles:before {
  content: '\f324';
}

.zmdi-view-toc:before {
  content: '\f325';
}

.zmdi-view-web:before {
  content: '\f326';
}

.zmdi-view-week:before {
  content: '\f327';
}

.zmdi-widgets:before {
  content: '\f328';
}

.zmdi-alarm-check:before {
  content: '\f329';
}

.zmdi-alarm-off:before {
  content: '\f32a';
}

.zmdi-alarm-plus:before {
  content: '\f32b';
}

.zmdi-alarm-snooze:before {
  content: '\f32c';
}

.zmdi-alarm:before {
  content: '\f32d';
}

.zmdi-calendar-alt:before {
  content: '\f32e';
}

.zmdi-calendar-check:before {
  content: '\f32f';
}

.zmdi-calendar-close:before {
  content: '\f330';
}

.zmdi-calendar-note:before {
  content: '\f331';
}

.zmdi-calendar:before {
  content: '\f332';
}

.zmdi-time-countdown:before {
  content: '\f333';
}

.zmdi-time-interval:before {
  content: '\f334';
}

.zmdi-time-restore-setting:before {
  content: '\f335';
}

.zmdi-time-restore:before {
  content: '\f336';
}

.zmdi-time:before {
  content: '\f337';
}

.zmdi-timer-off:before {
  content: '\f338';
}

.zmdi-timer:before {
  content: '\f339';
}

.zmdi-android-alt:before {
  content: '\f33a';
}

.zmdi-android:before {
  content: '\f33b';
}

.zmdi-apple:before {
  content: '\f33c';
}

.zmdi-behance:before {
  content: '\f33d';
}

.zmdi-codepen:before {
  content: '\f33e';
}

.zmdi-dribbble:before {
  content: '\f33f';
}

.zmdi-dropbox:before {
  content: '\f340';
}

.zmdi-evernote:before {
  content: '\f341';
}

.zmdi-facebook-box:before {
  content: '\f342';
}

.zmdi-facebook:before {
  content: '\f343';
}

.zmdi-github-box:before {
  content: '\f344';
}

.zmdi-github:before {
  content: '\f345';
}

.zmdi-google-drive:before {
  content: '\f346';
}

.zmdi-google-earth:before {
  content: '\f347';
}

.zmdi-google-glass:before {
  content: '\f348';
}

.zmdi-google-maps:before {
  content: '\f349';
}

.zmdi-google-pages:before {
  content: '\f34a';
}

.zmdi-google-play:before {
  content: '\f34b';
}

.zmdi-google-plus-box:before {
  content: '\f34c';
}

.zmdi-google-plus:before {
  content: '\f34d';
}

.zmdi-google:before {
  content: '\f34e';
}

.zmdi-instagram:before {
  content: '\f34f';
}

.zmdi-language-css3:before {
  content: '\f350';
}

.zmdi-language-html5:before {
  content: '\f351';
}

.zmdi-language-javascript:before {
  content: '\f352';
}

.zmdi-language-python-alt:before {
  content: '\f353';
}

.zmdi-language-python:before {
  content: '\f354';
}

.zmdi-lastfm:before {
  content: '\f355';
}

.zmdi-linkedin-box:before {
  content: '\f356';
}

.zmdi-paypal:before {
  content: '\f357';
}

.zmdi-pinterest-box:before {
  content: '\f358';
}

.zmdi-pocket:before {
  content: '\f359';
}

.zmdi-polymer:before {
  content: '\f35a';
}

.zmdi-share:before {
  content: '\f35b';
}

.zmdi-stackoverflow:before {
  content: '\f35c';
}

.zmdi-steam-square:before {
  content: '\f35d';
}

.zmdi-steam:before {
  content: '\f35e';
}

.zmdi-twitter-box:before {
  content: '\f35f';
}

.zmdi-twitter:before {
  content: '\f360';
}

.zmdi-vk:before {
  content: '\f361';
}

.zmdi-wikipedia:before {
  content: '\f362';
}

.zmdi-windows:before {
  content: '\f363';
}

.zmdi-aspect-ratio-alt:before {
  content: '\f364';
}

.zmdi-aspect-ratio:before {
  content: '\f365';
}

.zmdi-blur-circular:before {
  content: '\f366';
}

.zmdi-blur-linear:before {
  content: '\f367';
}

.zmdi-blur-off:before {
  content: '\f368';
}

.zmdi-blur:before {
  content: '\f369';
}

.zmdi-brightness-2:before {
  content: '\f36a';
}

.zmdi-brightness-3:before {
  content: '\f36b';
}

.zmdi-brightness-4:before {
  content: '\f36c';
}

.zmdi-brightness-5:before {
  content: '\f36d';
}

.zmdi-brightness-6:before {
  content: '\f36e';
}

.zmdi-brightness-7:before {
  content: '\f36f';
}

.zmdi-brightness-auto:before {
  content: '\f370';
}

.zmdi-brightness-setting:before {
  content: '\f371';
}

.zmdi-broken-image:before {
  content: '\f372';
}

.zmdi-center-focus-strong:before {
  content: '\f373';
}

.zmdi-center-focus-weak:before {
  content: '\f374';
}

.zmdi-compare:before {
  content: '\f375';
}

.zmdi-crop-16-9:before {
  content: '\f376';
}

.zmdi-crop-3-2:before {
  content: '\f377';
}

.zmdi-crop-5-4:before {
  content: '\f378';
}

.zmdi-crop-7-5:before {
  content: '\f379';
}

.zmdi-crop-din:before {
  content: '\f37a';
}

.zmdi-crop-free:before {
  content: '\f37b';
}

.zmdi-crop-landscape:before {
  content: '\f37c';
}

.zmdi-crop-portrait:before {
  content: '\f37d';
}

.zmdi-crop-square:before {
  content: '\f37e';
}

.zmdi-exposure-alt:before {
  content: '\f37f';
}

.zmdi-exposure:before {
  content: '\f380';
}

.zmdi-filter-b-and-w:before {
  content: '\f381';
}

.zmdi-filter-center-focus:before {
  content: '\f382';
}

.zmdi-filter-frames:before {
  content: '\f383';
}

.zmdi-filter-tilt-shift:before {
  content: '\f384';
}

.zmdi-gradient:before {
  content: '\f385';
}

.zmdi-grain:before {
  content: '\f386';
}

.zmdi-graphic-eq:before {
  content: '\f387';
}

.zmdi-hdr-off:before {
  content: '\f388';
}

.zmdi-hdr-strong:before {
  content: '\f389';
}

.zmdi-hdr-weak:before {
  content: '\f38a';
}

.zmdi-hdr:before {
  content: '\f38b';
}

.zmdi-iridescent:before {
  content: '\f38c';
}

.zmdi-leak-off:before {
  content: '\f38d';
}

.zmdi-leak:before {
  content: '\f38e';
}

.zmdi-looks:before {
  content: '\f38f';
}

.zmdi-loupe:before {
  content: '\f390';
}

.zmdi-panorama-horizontal:before {
  content: '\f391';
}

.zmdi-panorama-vertical:before {
  content: '\f392';
}

.zmdi-panorama-wide-angle:before {
  content: '\f393';
}

.zmdi-photo-size-select-large:before {
  content: '\f394';
}

.zmdi-photo-size-select-small:before {
  content: '\f395';
}

.zmdi-picture-in-picture:before {
  content: '\f396';
}

.zmdi-slideshow:before {
  content: '\f397';
}

.zmdi-texture:before {
  content: '\f398';
}

.zmdi-tonality:before {
  content: '\f399';
}

.zmdi-vignette:before {
  content: '\f39a';
}

.zmdi-wb-auto:before {
  content: '\f39b';
}

.zmdi-eject-alt:before {
  content: '\f39c';
}

.zmdi-eject:before {
  content: '\f39d';
}

.zmdi-equalizer:before {
  content: '\f39e';
}

.zmdi-fast-forward:before {
  content: '\f39f';
}

.zmdi-fast-rewind:before {
  content: '\f3a0';
}

.zmdi-forward-10:before {
  content: '\f3a1';
}

.zmdi-forward-30:before {
  content: '\f3a2';
}

.zmdi-forward-5:before {
  content: '\f3a3';
}

.zmdi-hearing:before {
  content: '\f3a4';
}

.zmdi-pause-circle-outline:before {
  content: '\f3a5';
}

.zmdi-pause-circle:before {
  content: '\f3a6';
}

.zmdi-pause:before {
  content: '\f3a7';
}

.zmdi-play-circle-outline:before {
  content: '\f3a8';
}

.zmdi-play-circle:before {
  content: '\f3a9';
}

.zmdi-play:before {
  content: '\f3aa';
}

.zmdi-playlist-audio:before {
  content: '\f3ab';
}

.zmdi-playlist-plus:before {
  content: '\f3ac';
}

.zmdi-repeat-one:before {
  content: '\f3ad';
}

.zmdi-repeat:before {
  content: '\f3ae';
}

.zmdi-replay-10:before {
  content: '\f3af';
}

.zmdi-replay-30:before {
  content: '\f3b0';
}

.zmdi-replay-5:before {
  content: '\f3b1';
}

.zmdi-replay:before {
  content: '\f3b2';
}

.zmdi-shuffle:before {
  content: '\f3b3';
}

.zmdi-skip-next:before {
  content: '\f3b4';
}

.zmdi-skip-previous:before {
  content: '\f3b5';
}

.zmdi-stop:before {
  content: '\f3b6';
}

.zmdi-surround-sound:before {
  content: '\f3b7';
}

.zmdi-tune:before {
  content: '\f3b8';
}

.zmdi-volume-down:before {
  content: '\f3b9';
}

.zmdi-volume-mute:before {
  content: '\f3ba';
}

.zmdi-volume-off:before {
  content: '\f3bb';
}

.zmdi-volume-up:before {
  content: '\f3bc';
}

.zmdi-n-1-square:before {
  content: '\f3bd';
}

.zmdi-n-2-square:before {
  content: '\f3be';
}

.zmdi-n-3-square:before {
  content: '\f3bf';
}

.zmdi-n-4-square:before {
  content: '\f3c0';
}

.zmdi-n-5-square:before {
  content: '\f3c1';
}

.zmdi-n-6-square:before {
  content: '\f3c2';
}

.zmdi-neg-1:before {
  content: '\f3c3';
}

.zmdi-neg-2:before {
  content: '\f3c4';
}

.zmdi-plus-1:before {
  content: '\f3c5';
}

.zmdi-plus-2:before {
  content: '\f3c6';
}

.zmdi-sec-10:before {
  content: '\f3c7';
}

.zmdi-sec-3:before {
  content: '\f3c8';
}

.zmdi-zero:before {
  content: '\f3c9';
}

.zmdi-airline-seat-flat-angled:before {
  content: '\f3ca';
}

.zmdi-airline-seat-flat:before {
  content: '\f3cb';
}

.zmdi-airline-seat-individual-suite:before {
  content: '\f3cc';
}

.zmdi-airline-seat-legroom-extra:before {
  content: '\f3cd';
}

.zmdi-airline-seat-legroom-normal:before {
  content: '\f3ce';
}

.zmdi-airline-seat-legroom-reduced:before {
  content: '\f3cf';
}

.zmdi-airline-seat-recline-extra:before {
  content: '\f3d0';
}

.zmdi-airline-seat-recline-normal:before {
  content: '\f3d1';
}

.zmdi-airplay:before {
  content: '\f3d2';
}

.zmdi-closed-caption:before {
  content: '\f3d3';
}

.zmdi-confirmation-number:before {
  content: '\f3d4';
}

.zmdi-developer-board:before {
  content: '\f3d5';
}

.zmdi-disc-full:before {
  content: '\f3d6';
}

.zmdi-explicit:before {
  content: '\f3d7';
}

.zmdi-flight-land:before {
  content: '\f3d8';
}

.zmdi-flight-takeoff:before {
  content: '\f3d9';
}

.zmdi-flip-to-back:before {
  content: '\f3da';
}

.zmdi-flip-to-front:before {
  content: '\f3db';
}

.zmdi-group-work:before {
  content: '\f3dc';
}

.zmdi-hd:before {
  content: '\f3dd';
}

.zmdi-hq:before {
  content: '\f3de';
}

.zmdi-markunread-mailbox:before {
  content: '\f3df';
}

.zmdi-memory:before {
  content: '\f3e0';
}

.zmdi-nfc:before {
  content: '\f3e1';
}

.zmdi-play-for-work:before {
  content: '\f3e2';
}

.zmdi-power-input:before {
  content: '\f3e3';
}

.zmdi-present-to-all:before {
  content: '\f3e4';
}

.zmdi-satellite:before {
  content: '\f3e5';
}

.zmdi-tap-and-play:before {
  content: '\f3e6';
}

.zmdi-vibration:before {
  content: '\f3e7';
}

.zmdi-voicemail:before {
  content: '\f3e8';
}

.zmdi-group:before {
  content: '\f3e9';
}

.zmdi-rss:before {
  content: '\f3ea';
}

.zmdi-shape:before {
  content: '\f3eb';
}

.zmdi-spinner:before {
  content: '\f3ec';
}

.zmdi-ungroup:before {
  content: '\f3ed';
}

.zmdi-500px:before {
  content: '\f3ee';
}

.zmdi-8tracks:before {
  content: '\f3ef';
}

.zmdi-amazon:before {
  content: '\f3f0';
}

.zmdi-blogger:before {
  content: '\f3f1';
}

.zmdi-delicious:before {
  content: '\f3f2';
}

.zmdi-disqus:before {
  content: '\f3f3';
}

.zmdi-flattr:before {
  content: '\f3f4';
}

.zmdi-flickr:before {
  content: '\f3f5';
}

.zmdi-github-alt:before {
  content: '\f3f6';
}

.zmdi-google-old:before {
  content: '\f3f7';
}

.zmdi-linkedin:before {
  content: '\f3f8';
}

.zmdi-odnoklassniki:before {
  content: '\f3f9';
}

.zmdi-outlook:before {
  content: '\f3fa';
}

.zmdi-paypal-alt:before {
  content: '\f3fb';
}

.zmdi-pinterest:before {
  content: '\f3fc';
}

.zmdi-playstation:before {
  content: '\f3fd';
}

.zmdi-reddit:before {
  content: '\f3fe';
}

.zmdi-skype:before {
  content: '\f3ff';
}

.zmdi-slideshare:before {
  content: '\f400';
}

.zmdi-soundcloud:before {
  content: '\f401';
}

.zmdi-tumblr:before {
  content: '\f402';
}

.zmdi-twitch:before {
  content: '\f403';
}

.zmdi-vimeo:before {
  content: '\f404';
}

.zmdi-whatsapp:before {
  content: '\f405';
}

.zmdi-xbox:before {
  content: '\f406';
}

.zmdi-yahoo:before {
  content: '\f407';
}

.zmdi-youtube-play:before {
  content: '\f408';
}

.zmdi-youtube:before {
  content: '\f409';
}

.zmdi-import-export:before {
  content: '\f30c';
}

.zmdi-swap-vertical-:before {
  content: '\f30c';
}

.zmdi-airplanemode-inactive:before {
  content: '\f102';
}

.zmdi-airplanemode-active:before {
  content: '\f103';
}

.zmdi-rate-review:before {
  content: '\f103';
}

.zmdi-comment-sign:before {
  content: '\f25a';
}

.zmdi-network-warning:before {
  content: '\f2ad';
}

.zmdi-shopping-cart-add:before {
  content: '\f1ca';
}

.zmdi-file-add:before {
  content: '\f221';
}

.zmdi-network-wifi-scan:before {
  content: '\f2e4';
}

.zmdi-collection-add:before {
  content: '\f14e';
}

.zmdi-format-playlist-add:before {
  content: '\f3ac';
}

.zmdi-format-queue-music:before {
  content: '\f3ab';
}

.zmdi-plus-box:before {
  content: '\f277';
}

.zmdi-tag-backspace:before {
  content: '\f1d9';
}

.zmdi-alarm-add:before {
  content: '\f32b';
}

.zmdi-battery-charging:before {
  content: '\f114';
}

.zmdi-daydream-setting:before {
  content: '\f217';
}

.zmdi-more-horiz:before {
  content: '\f19c';
}

.zmdi-book-photo:before {
  content: '\f11b';
}

.zmdi-incandescent:before {
  content: '\f189';
}

.zmdi-wb-iridescent:before {
  content: '\f38c';
}

.zmdi-calendar-remove:before {
  content: '\f330';
}

.zmdi-refresh-sync-disabled:before {
  content: '\f1b7';
}

.zmdi-refresh-sync-problem:before {
  content: '\f1b6';
}

.zmdi-crop-original:before {
  content: '\f17e';
}

.zmdi-power-off:before {
  content: '\f1af';
}

.zmdi-power-off-setting:before {
  content: '\f1ae';
}

.zmdi-leak-remove:before {
  content: '\f38d';
}

.zmdi-star-border:before {
  content: '\f27c';
}

.zmdi-brightness-low:before {
  content: '\f36d';
}

.zmdi-brightness-medium:before {
  content: '\f36e';
}

.zmdi-brightness-high:before {
  content: '\f36f';
}

.zmdi-smartphone-portrait:before {
  content: '\f2d4';
}

.zmdi-live-tv:before {
  content: '\f2d9';
}

.zmdi-format-textdirection-l-to-r:before {
  content: '\f249';
}

.zmdi-format-textdirection-r-to-l:before {
  content: '\f24a';
}

.zmdi-arrow-back:before {
  content: '\f2ea';
}

.zmdi-arrow-forward:before {
  content: '\f2ee';
}

.zmdi-arrow-in:before {
  content: '\f2e9';
}

.zmdi-arrow-out:before {
  content: '\f2ed';
}

.zmdi-rotate-90-degrees-ccw:before {
  content: '\f304';
}

.zmdi-adb:before {
  content: '\f33a';
}

.zmdi-network-wifi:before {
  content: '\f2e8';
}

.zmdi-network-wifi-alt:before {
  content: '\f2e3';
}

.zmdi-network-wifi-lock:before {
  content: '\f2e5';
}

.zmdi-network-wifi-off:before {
  content: '\f2e6';
}

.zmdi-network-wifi-outline:before {
  content: '\f2e7';
}

.zmdi-network-wifi-info:before {
  content: '\f2e4';
}

.zmdi-layers-clear:before {
  content: '\f18b';
}

.zmdi-colorize:before {
  content: '\f15d';
}

.zmdi-format-paint:before {
  content: '\f1ba';
}

.zmdi-format-quote:before {
  content: '\f1b2';
}

.zmdi-camera-monochrome-photos:before {
  content: '\f285';
}

.zmdi-sort-by-alpha:before {
  content: '\f1cf';
}

.zmdi-folder-shared:before {
  content: '\f225';
}

.zmdi-folder-special:before {
  content: '\f226';
}

.zmdi-comment-dots:before {
  content: '\f260';
}

.zmdi-reorder:before {
  content: '\f31e';
}

.zmdi-dehaze:before {
  content: '\f197';
}

.zmdi-sort:before {
  content: '\f1ce';
}

.zmdi-pages:before {
  content: '\f34a';
}

.zmdi-stack-overflow:before {
  content: '\f35c';
}

.zmdi-calendar-account:before {
  content: '\f204';
}

.zmdi-paste:before {
  content: '\f109';
}

.zmdi-cut:before {
  content: '\f1bc';
}

.zmdi-save:before {
  content: '\f297';
}

.zmdi-smartphone-code:before {
  content: '\f139';
}

.zmdi-directions-bike:before {
  content: '\f117';
}

.zmdi-directions-boat:before {
  content: '\f11a';
}

.zmdi-directions-bus:before {
  content: '\f121';
}

.zmdi-directions-car:before {
  content: '\f125';
}

.zmdi-directions-railway:before {
  content: '\f1b3';
}

.zmdi-directions-run:before {
  content: '\f215';
}

.zmdi-directions-subway:before {
  content: '\f1d5';
}

.zmdi-directions-walk:before {
  content: '\f216';
}

.zmdi-local-hotel:before {
  content: '\f178';
}

.zmdi-local-activity:before {
  content: '\f1df';
}

.zmdi-local-play:before {
  content: '\f1df';
}

.zmdi-local-airport:before {
  content: '\f103';
}

.zmdi-local-atm:before {
  content: '\f198';
}

.zmdi-local-bar:before {
  content: '\f137';
}

.zmdi-local-cafe:before {
  content: '\f13b';
}

.zmdi-local-car-wash:before {
  content: '\f124';
}

.zmdi-local-convenience-store:before {
  content: '\f1d3';
}

.zmdi-local-dining:before {
  content: '\f153';
}

.zmdi-local-drink:before {
  content: '\f157';
}

.zmdi-local-florist:before {
  content: '\f168';
}

.zmdi-local-gas-station:before {
  content: '\f16f';
}

.zmdi-local-grocery-store:before {
  content: '\f1cb';
}

.zmdi-local-hospital:before {
  content: '\f177';
}

.zmdi-local-laundry-service:before {
  content: '\f1e9';
}

.zmdi-local-library:before {
  content: '\f18d';
}

.zmdi-local-mall:before {
  content: '\f195';
}

.zmdi-local-movies:before {
  content: '\f19d';
}

.zmdi-local-offer:before {
  content: '\f187';
}

.zmdi-local-parking:before {
  content: '\f1a5';
}

.zmdi-local-parking:before {
  content: '\f1a5';
}

.zmdi-local-pharmacy:before {
  content: '\f176';
}

.zmdi-local-phone:before {
  content: '\f2be';
}

.zmdi-local-pizza:before {
  content: '\f1ac';
}

.zmdi-local-post-office:before {
  content: '\f15a';
}

.zmdi-local-printshop:before {
  content: '\f1b0';
}

.zmdi-local-see:before {
  content: '\f28c';
}

.zmdi-local-shipping:before {
  content: '\f1e6';
}

.zmdi-local-store:before {
  content: '\f1d4';
}

.zmdi-local-taxi:before {
  content: '\f123';
}

.zmdi-local-wc:before {
  content: '\f211';
}

.zmdi-my-location:before {
  content: '\f299';
}

.zmdi-directions:before {
  content: '\f1e7';
}

/* =============
   Timeline
============= */
.timeline {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin-bottom: 50px;
  position: relative;
  table-layout: fixed;
  width: 100%;
}

.timeline .time-show {
  margin-bottom: 30px;
  margin-right: -75px;
  margin-top: 30px;
  position: relative;
}

.timeline .time-show a {
  color: #ffffff;
}

.timeline:before {
  background-color: rgba(129, 138, 145, 0.3);
  bottom: 0px;
  content: "";
  left: 50%;
  position: absolute;
  top: 30px;
  width: 1px;
  z-index: 0;
}

.timeline .timeline-icon {
  -webkit-border-radius: 50%;
  background: #818a91;
  border-radius: 50%;
  color: #ffffff;
  display: block;
  height: 20px;
  left: -54px;
  margin-top: -10px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 20px;
}

.timeline .timeline-icon i {
  color: #f5f5f5;
  font-size: 13px;
  margin-top: 4px;
  position: absolute;
  left: 4px;
}

.timeline .time-icon:before {
  font-size: 16px;
  margin-top: 5px;
}

h3.timeline-title {
  color: #eceeef;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 5px;
  text-transform: uppercase;
}

.timeline-item {
  display: table-row;
}

.timeline-item:before {
  content: "";
  display: block;
  width: 50%;
}

.timeline-item .timeline-desk .arrow {
  border-bottom: 12px solid transparent;
  border-right: 12px solid #ffffff !important;
  border-top: 12px solid transparent;
  display: block;
  height: 0;
  left: -12px;
  margin-top: -12px;
  position: absolute;
  top: 50%;
  width: 0;
}

.timeline-item .timeline-desk .timeline-box {
  padding: 20px;
}

.timeline-item .timeline-date {
  margin-bottom: 10px;
}

.timeline-item.alt:after {
  content: "";
  display: block;
  width: 50%;
}

.timeline-item.alt .timeline-desk .arrow-alt {
  border-bottom: 12px solid transparent;
  border-left: 12px solid #ffffff !important;
  border-top: 12px solid transparent;
  display: block;
  height: 0;
  left: auto;
  margin-top: -12px;
  position: absolute;
  right: -12px;
  top: 50%;
  width: 0;
}

.timeline-item.alt .timeline-desk .album {
  float: right;
  margin-top: 20px;
}

.timeline-item.alt .timeline-desk .album a {
  float: right;
  margin-left: 5px;
}

.timeline-item.alt .timeline-icon {
  left: auto;
  right: -56px;
}

.timeline-item.alt:before {
  display: none;
}

.timeline-item.alt .panel {
  margin-left: 0;
  margin-right: 45px;
}

.timeline-item.alt h4 {
  text-align: right;
}

.timeline-item.alt p {
  text-align: right;
}

.timeline-item.alt .timeline-date {
  text-align: right;
}

.timeline-desk {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}

.timeline-desk h4 {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

.timeline-desk .panel {
  background: #ffffff;
  display: block;
  margin-bottom: 5px;
  margin-left: 45px;
  position: relative;
  text-align: left;
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
}

.timeline-desk h5 span {
  color: #eceeef;
  display: block;
  font-size: 12px;
  margin-bottom: 4px;
}

.timeline-desk p {
  color: #999999;
  font-size: 14px;
  margin-bottom: 0;
}

.timeline-desk .album {
  margin-top: 12px;
}

.timeline-desk .album a {
  float: left;
  margin-right: 5px;
}

.timeline-desk .album img {
  height: 36px;
  width: auto;
  border-radius: 3px;
}

.timeline-desk .notification {
  background: none repeat scroll 0 0 #ffffff;
  margin-top: 20px;
  padding: 8px;
}

/* ===========
   Timeline-2
 =============*/
.timeline-2 {
  border-left: 2px solid #64b0f2;
  position: relative;
}

.timeline-2 .time-item:after {
  background-color: #ffffff;
  border-color: #64b0f2;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: '';
  height: 10px;
  left: 0;
  margin-left: -6px;
  position: absolute;
  top: 5px;
  width: 10px;
}

.time-item {
  border-color: #dee5e7;
  padding-bottom: 10px;
  position: relative;
}

.time-item:before {
  content: " ";
  display: table;
}

.time-item:after {
  background-color: #ffffff;
  border-color: #64b0f2;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: '';
  height: 14px;
  left: 0;
  margin-left: -8px;
  position: absolute;
  top: 5px;
  width: 14px;
}

.time-item-item:after {
  content: " ";
  display: table;
}

.item-info {
  margin-bottom: 15px;
  margin-left: 15px;
}

.item-info p {
  font-size: 13px;
}

.rating-md i {
  font-size: 18px;
}

.rating-lg i {
  font-size: 24px;
}

/* =============
   Pricing
============= */
.pricing-plan {
  padding-bottom: 50px;
}

.price_card {
  -moz-border-radius: 5px;
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  padding-bottom: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.price_card.active {
  margin: 0 -21px 0 -21px;
  padding-bottom: 30px;
  box-shadow: 0 0 54px 0 rgba(0, 0, 0, 0.09), 0 1px 0 0 rgba(0, 0, 0, 0.05);
  z-index: 99;
}

.price_card.active .pricing-header {
  padding: 20px;
}

.price_card .name {
  display: block;
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 30px;
  text-align: center;
  width: 100%;
}

.price_card button {
  margin-top: 20px;
}

.pricing-header {
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #ffffff;
}

.price {
  display: block;
  font-size: 48px;
  font-weight: 300;
  padding: 30px 0 10px;
  text-align: center;
  width: 100%;
}

.price-features {
  color: #8a8a8a;
  list-style: none;
  margin: 0;
  padding-left: 0;
  padding-top: 20px;
  text-align: center;
}

.price-features li {
  padding: 15px;
}

.pricing-item {
  margin: 0 0 30px;
  position: relative;
  text-align: center;
}

.pricing-item-inner {
  -moz-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -ms-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  border-radius: 7px;
  border: 2px solid rgba(151, 160, 175, 0.2);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  vertical-align: middle;
}

.pricing-wrap {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  padding: 30px 20px;
  position: relative;
  text-align: center;
}

.pricing-icon {
  font-size: 32px;
  padding-bottom: 5px;
  padding-top: 10px;
  position: relative;
  z-index: 1;
}

.pricing-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 40px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}

.pr-list {
  color: #97a0af;
  font-size: 12px;
  font-weight: 400;
  margin: 0 -20px 30px;
  padding: 0;
  text-transform: uppercase;
}

.pr-list li {
  list-style: none;
  padding: 12px 20px;
}

.pricing-num {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
}

.pricing-num sup {
  font-size: 18px;
  font-weight: 400;
  left: -3px;
  margin-left: -7px;
  position: relative;
  top: -20px;
}

.pr-per {
  color: #97a0af;
  font-size: 12px;
}

.pr-button {
  margin-top: 30px;
}

/* =============
   Gallery
============= */
.portfolioFilter a {
  transition: all 0.3s ease-out;
  color: #2b3d51;
  font-weight: 600;
  padding: 5px 10px;
  display: inline-block;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
}

.portfolioFilter a:hover {
  color: #1bb99a;
}

.portfolioContainer .col-xs-12 {
  display: inline-block;
}

.portfolioFilter a.current {
  color: #ffffff;
  background-color: #1bb99a;
}

.gallery-box {
  width: 33.33%;
  float: left;
  padding: 0 10px;
}

.thumb {
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
}

.thumb a {
  display: block;
  background-color: #080b0e;
  border-radius: 3px;
}

.thumb:hover .thumb-img {
  overflow: hidden;
  position: relative;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  opacity: 0.7;
}

.thumb .thumb-img {
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  transition: all 0.2s ease-out;
}

.thumb .gal-detail {
  padding: 10px;
  position: relative;
  background-color: #ffffff;
}

.thumb .gal-detail h4 {
  font-weight: 600;
  font-size: 16px;
  padding: 0 5px;
}

.thumb .gal-detail p {
  padding: 0 5px;
  font-size: 13px;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .gallery-box {
    width: 50%;
  }
}

/* =============
   Print css
============= */
@media print {
  .content-page {
    margin-left: 0 !important;
  }
  .page-title-box,
  .topbar-left,
  .hidden-print,
  .breadcrumb,
  .page-title,
  .footer, .navbar-custom, .left.side-menu {
    display: none !important;
    margin: 0px;
    padding: 0px;
  }
  .left,
  .right-bar {
    display: none;
  }
  .content {
    margin-top: 0px !important;
    padding-top: 0px;
  }
}

/* =============
   Maintenance
============= */
.maintenance-icon {
  height: 220px;
  width: 220px;
  margin: 0 auto;
}

.line1 {
  opacity: 0;
  animation: fadeInLeft both 1s 0.4s, coding1 ease 6s 4s infinite;
}

.line2 {
  opacity: 0;
  animation: fadeInLeft both 1s 0.6s, coding2 ease 6s 4s infinite;
}

.line3 {
  opacity: 0;
  animation: fadeInLeft both 1s 0.8s, coding3 ease 6s 4s infinite;
}

.line4 {
  opacity: 0;
  animation: fadeInLeft both 1s 1.0s, coding4 ease 6s 4s infinite;
}

.line5 {
  opacity: 0;
  animation: fadeInLeft both 1s 1.2s, coding5 ease 6s 4s infinite;
}

.line6 {
  opacity: 0;
  animation: fadeInLeft both 1s 1.4s, coding6 ease 6s 4s infinite;
}

.line7 {
  opacity: 0;
  animation: fadeInLeft both 1s 1.6s, coding6 ease 6s 4s infinite;
}

@keyframes coding1 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  14% {
    transform: translate(0, -10px);
    opacity: 0;
  }
  15% {
    transform: translate(0, 45px);
  }
  30% {
    transform: translate(0, 40px);
    opacity: 1;
  }
  45% {
    transform: translate(0, 30px);
  }
  60% {
    transform: translate(0, 20px);
  }
  75% {
    transform: translate(0, 10px);
  }
  90% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes coding2 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  15% {
    transform: translate(0, -5px);
    opacity: 1;
  }
  29% {
    transform: translate(0, -10px);
    opacity: 0;
  }
  30% {
    transform: translate(0, 40px);
  }
  45% {
    transform: translate(0, 30px);
    opacity: 1;
  }
  60% {
    transform: translate(0, 20px);
  }
  75% {
    transform: translate(0, 10px);
  }
  90% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes coding3 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  15% {
    transform: translate(0, -5px);
  }
  30% {
    transform: translate(0, -10px);
    opacity: 1;
  }
  44% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  45% {
    transform: translate(0, 30px);
  }
  60% {
    transform: translate(0, 20px);
    opacity: 1;
  }
  75% {
    transform: translate(0, 10px);
  }
  90% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes coding4 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  15% {
    transform: translate(0, -5px);
  }
  30% {
    transform: translate(0, -10px);
  }
  45% {
    transform: translate(0, -20px);
    opacity: 1;
  }
  59% {
    transform: translate(0, -30px);
    opacity: 0;
  }
  60% {
    transform: translate(0, 20px);
  }
  75% {
    transform: translate(0, 10px);
    opacity: 1;
  }
  90% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes coding5 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  15% {
    transform: translate(0, -5px);
  }
  30% {
    transform: translate(0, -10px);
  }
  45% {
    transform: translate(0, -20px);
  }
  60% {
    transform: translate(0, -30px);
    opacity: 1;
  }
  74% {
    transform: translate(0, -40px);
    opacity: 0;
  }
  75% {
    transform: translate(0, 10px);
  }
  90% {
    transform: translate(0, 5px);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes coding6 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  15% {
    transform: translate(0, -5px);
  }
  30% {
    transform: translate(0, -10px);
  }
  45% {
    transform: translate(0, -20px);
  }
  60% {
    transform: translate(0, -30px);
  }
  75% {
    transform: translate(0, -40px);
    opacity: 1;
  }
  89% {
    transform: translate(0, -50px);
    opacity: 0;
  }
  90% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

/* =============
   Count Down
============= */
.count-down-page {
  max-width: 1170px;
  margin: 50px auto;
}

#count-down {
  margin-top: 50px;
}

#count-down .clock-presenter {
  height: 140px;
  line-height: 30px;
  padding: 0 30px;
  text-align: center;
}

#count-down .clock-presenter .digit {
  margin-top: 20px;
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  position: relative;
  cursor: default;
}

#count-down .clock-presenter .note {
  position: relative;
  bottom: 0;
  padding-top: 5px;
  cursor: default;
  font-size: 16px;
  color: #1bb99a;
  text-transform: uppercase;
}

@media (max-width: 992px) {
  #count-down .clock-presenter .digit {
    font-size: 42px;
  }
}

@media (max-width: 767px) {
  #count-down .clock-presenter {
    width: 50%;
    float: left;
  }
  #count-down .clock-presenter .digit {
    font-size: 36px;
  }
  #count-down .hours_dash {
    border-right: none;
  }
}

.diamond {
  margin: 50px auto;
  height: 90px;
  width: 120px;
}

.diamond:after {
  content: "";
  position: absolute;
  height: 14px;
  width: 44px;
  background: rgba(43, 61, 81, 0.1);
  border-radius: 50%;
  margin-top: 0;
  margin-left: 38px;
  z-index: 11;
}

.diamond .top {
  height: 30px;
  border-left: 27px solid transparent;
  border-right: 27px solid transparent;
  border-bottom: 24px solid #48CFAD;
}

.diamond .top:after {
  content: "";
  position: absolute;
  height: 24px;
  width: 32px;
  margin-top: 6px;
  margin-left: 47px;
  background: #37BC9B;
  -ms-transform: skew(30deg, 20deg);
  -webkit-transform: skew(30deg, 20deg);
  transform: skew(48deg);
}

.diamond .top:before {
  content: "";
  position: absolute;
  height: 24px;
  width: 32px;
  margin-top: 7px;
  margin-left: -13px;
  background: #37BC9B;
  -ms-transform: skew(30deg, 20deg);
  -webkit-transform: skew(30deg, 20deg);
  transform: skew(-48deg);
}

.diamond .bot {
  height: 60px;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-top: 60px solid #48CFAD;
}

.diamond .bot:before {
  content: "";
  position: absolute;
  height: 60px;
  margin-top: -60px;
  margin-left: -27px;
  border-left: 27px solid transparent;
  border-right: 26px solid transparent;
  border-top: 60px solid #37BC9B;
}

.breadcrumb {
  margin-bottom: 0;
}

/* Carousel */
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.label {
  display: inline-block;
  padding: 0.2em 0.6em;
  font-size: 75%;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.label-pill {
  border-radius: 10rem;
}

.label:empty {
  display: none;
}

.label-default {
  background-color: #64b0f2;
}

.label-primary {
  background-color: #039cfd;
}

.label-success {
  background-color: #1bb99a;
}

.label-info {
  background-color: #3db9dc;
}

.label-warning {
  background-color: #f1b53d;
}

.label-danger {
  background-color: #ff5d48;
}

.label-pink {
  background-color: #ff7aa3;
}

.lable-purple {
  background-color: #9261c6;
}

.label-dark {
  background-color: #2b3d51;
}

/* Pagination/ Pager */
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.pagination > li > a,
.pagination > li > span {
  color: #2b3d51;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #e4e7ea;
}

.pagination-split li {
  margin-left: 5px;
  display: inline-block;
  float: left;
}

.pagination-split li:first-child {
  margin-left: 0;
}

.pagination-split li a {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #64b0f2;
  border-color: #64b0f2;
}

.pager li > a, .pager li > span {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #2b3d51;
}

.bg-pink {
  background-color: #ff7aa3;
}

.bg-purple {
  background-color: #9261c6;
}

/* Margin / Padding classes */
.p-0 {
  padding: 0 !important;
}

.p-20 {
  padding: 20px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-t-70 {
  margin-top: 70px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

@media (max-width: 767px) {
  .m-t-sm-40 {
    margin-top: 40px !important;
  }
}

@media (max-width: 480px) {
  .m-t-xs-40 {
    margin-top: 40px !important;
  }
}

/* Width */
.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.display-block {
  display: block;
}

/* Demo only */
.icon-list-demo div {
  cursor: pointer;
  line-height: 45px;
  white-space: nowrap;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-list-demo div p {
  margin-bottom: 0;
  line-height: inherit;
}

.icon-list-demo i {
  text-align: center;
  margin: 0;
  vertical-align: middle;
  font-size: 24px;
  border: 1px solid #eceeef;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 12px;
  color: rgba(43, 61, 81, 0.7);
  border-radius: 3px;
  display: inline-block;
}

.icon-list-demo .col-md-4 {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
  margin-bottom: 10px;
}

.icon-list-demo .col-md-4:hover i {
  color: #64b0f2;
}

.icon-list-pe7 i {
  font-size: 28px;
  color: #2b3d51;
}

/* Switchery demo */
.switchery-demo .switchery {
  margin-bottom: 10px;
  margin-right: 5px;
}

.dropdown-example {
  position: static;
  display: block;
  margin-bottom: .25rem;
  clear: left;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #039cfd;
}

/* =============
   Calendar
============= */
.calendar {
  float: left;
  margin-bottom: 0px;
}

.fc-view {
  margin-top: 30px;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px;
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-day {
  background: #ffffff;
}

.fc-title, .fc-time {
  color: #ffffff;
}

.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active,
.fc-toolbar button:focus, .fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #eceeef;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0px;
  text-transform: uppercase;
}

.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-popover {
  border-color: #eceeef;
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #818a91;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #eceeef;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-state-down, .fc-state-active, .fc-state-disabled {
  background-color: #64b0f2 !important;
  color: #ffffff !important;
  text-shadow: none !important;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #ffffff;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.external-event {
  color: #ffffff;
  cursor: move;
  margin: 10px 0;
  padding: 6px 10px;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
}

.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
}

/* =============
   Checkbox and Radios
============= */
.checkbox label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}

.checkbox label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: transparent;
  border-radius: 3px;
  border: 2px solid rgba(43, 61, 81, 0.5);
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
  margin-top: 2px;
}

.checkbox label::after {
  color: #2b3d51;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 2px;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: 2px;
  width: 16px;
}

.checkbox input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}

.checkbox input[type="checkbox"]:checked + label::after {
  content: '\f26b';
  font-family: 'Material-Design-Iconic-Font';
  font-weight: bold;
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #818a91;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox.checkbox-single label {
  height: 17px;
}

.checkbox-custom input[type="checkbox"]:checked + label::before {
  background-color: #64b0f2;
  border-color: #64b0f2;
}

.checkbox-custom input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #039cfd;
  border-color: #039cfd;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #ff5d48;
  border-color: #ff5d48;
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #3db9dc;
  border-color: #3db9dc;
}

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f1b53d;
  border-color: #f1b53d;
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #1bb99a;
  border-color: #1bb99a;
}

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-purple input[type="checkbox"]:checked + label::before {
  background-color: #9261c6;
  border-color: #9261c6;
}

.checkbox-purple input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-pink input[type="checkbox"]:checked + label::before {
  background-color: #ff7aa3;
  border-color: #ff7aa3;
}

.checkbox-pink input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-inverse input[type="checkbox"]:checked + label::before {
  background-color: #2b3d51;
  border-color: #2b3d51;
}

.checkbox-inverse input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

/* Radios */
.radio label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}

.radio label::before {
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  border-radius: 50%;
  border: 2px solid rgba(43, 61, 81, 0.5);
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  outline: none !important;
  position: absolute;
  transition: border 0.5s ease-in-out;
  width: 17px;
  margin-top: 2px;
}

.radio label::after {
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -webkit-transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  background-color: #818a91;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 11px;
  left: 3px;
  margin-left: -20px;
  position: absolute;
  top: 5px;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  width: 11px;
}

.radio input[type="radio"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio input[type="radio"]:focus + label::before {
  outline-offset: -2px;
  outline: 5px auto -webkit-focus-ring-color;
  outline: thin dotted;
}

.radio input[type="radio"]:checked + label::after {
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

.radio.radio-single label {
  height: 17px;
}

.radio-custom input[type="radio"] + label::after {
  background-color: #64b0f2;
}

.radio-custom input[type="radio"]:checked + label::before {
  border-color: #64b0f2;
}

.radio-custom input[type="radio"]:checked + label::after {
  background-color: #64b0f2;
}

.radio-primary input[type="radio"] + label::after {
  background-color: #039cfd;
}

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #039cfd;
}

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #039cfd;
}

.radio-danger input[type="radio"] + label::after {
  background-color: #ff5d48;
}

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #ff5d48;
}

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #ff5d48;
}

.radio-info input[type="radio"] + label::after {
  background-color: #3db9dc;
}

.radio-info input[type="radio"]:checked + label::before {
  border-color: #3db9dc;
}

.radio-info input[type="radio"]:checked + label::after {
  background-color: #3db9dc;
}

.radio-warning input[type="radio"] + label::after {
  background-color: #f1b53d;
}

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f1b53d;
}

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f1b53d;
}

.radio-success input[type="radio"] + label::after {
  background-color: #1bb99a;
}

.radio-success input[type="radio"]:checked + label::before {
  border-color: #1bb99a;
}

.radio-success input[type="radio"]:checked + label::after {
  background-color: #1bb99a;
}

.radio-purple input[type="radio"] + label::after {
  background-color: #9261c6;
}

.radio-purple input[type="radio"]:checked + label::before {
  border-color: #9261c6;
}

.radio-purple input[type="radio"]:checked + label::after {
  background-color: #9261c6;
}

.radio-pink input[type="radio"] + label::after {
  background-color: #ff7aa3;
}

.radio-pink input[type="radio"]:checked + label::before {
  border-color: #ff7aa3;
}

.radio-pink input[type="radio"]:checked + label::after {
  background-color: #ff7aa3;
}

/* =============
   Notification
============= */
#toast-container > div {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  opacity: 1;
}

#toast-container > :hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  opacity: 1;
}

.toast {
  background-color: #64b0f2;
}

.toast-success {
  background-color: #1bb99a;
  border: 2px solid #1bb99a;
}

.toast-error {
  background-color: #ff5d48;
  border: 2px solid #ff5d48;
}

.toast-info {
  background-color: #3db9dc;
  border: 2px solid #3db9dc;
}

.toast-warning {
  background-color: #f1b53d;
  border: 2px solid #f1b53d;
}

.alerts-demo {
  background-color: #f7f7f9;
  border: 1px solid #eceeef;
  padding: 20px;
}

.progress-lg {
  height: 1.5rem;
}

.progress-sm {
  height: 0.7rem;
}

.progress-xs {
  height: 0.4rem;
}

.alert-success {
  background-color: #c3f6ec;
  border-color: #1bb99a;
  color: #1bb99a;
}

.alert-success .alert-link {
  color: #18a387;
}

.alert-info {
  background-color: #bfe8f3;
  border-color: #3db9dc;
  color: #3db9dc;
}

.alert-info .alert-link {
  color: #27b1d8;
}

.alert-warning {
  background-color: #fbebcc;
  border-color: #f1b53d;
  color: #f1b53d;
}

.alert-warning .alert-link {
  color: #efac25;
}

.alert-danger {
  background-color: mistyrose;
  border-color: #ff5d48;
  color: #ff5d48;
}

.alert-danger .alert-link {
  color: #ff462f;
}

.alert-link {
  font-weight: 500;
}

label {
  font-weight: 500;
}

/* Bootstrap tagsinput */
.bootstrap-tagsinput {
  width: 100%;
  box-shadow: none;
  padding: 3px 7px 6px;
  border: 1px solid #eceeef;
}

.bootstrap-tagsinput .label-info {
  background-color: #64b0f2 !important;
  display: inline-block;
  padding: 5px;
}

.search-input {
  margin-bottom: 10px;
}

.ms-container {
  background: transparent url("../plugins/multiselect/img/multiple-arrow.png") no-repeat 50% 50%;
  width: 100% !important;
  max-width: 360px !important;
}

.ms-container .ms-list {
  box-shadow: none;
  border: 1px solid rgba(43, 61, 81, 0.2);
}

.ms-container .ms-list.ms-focus {
  box-shadow: none;
  border: 1px solid rgba(43, 61, 81, 0.4);
}

.ms-container .ms-selectable {
  box-shadow: none;
  outline: none !important;
}

.ms-container .ms-selectable li.ms-elem-selectable {
  border: none;
  padding: 5px 10px;
}

.ms-container .ms-selectable li.ms-hover {
  background-color: #64b0f2;
}

.ms-container .ms-selection li.ms-elem-selection {
  border: none;
  padding: 5px 10px;
}

.ms-container .ms-selection li.ms-hover {
  background-color: #64b0f2;
}

/* Select 2 */
.select2-container .select2-selection--single {
  height: 34px;
  border: 1px solid rgba(43, 61, 81, 0.3);
  outline: none !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 32px !important;
  padding-left: 12px;
}

.select2-container .select2-selection--single .select2-selection__arrow {
  height: 32px;
  width: 30px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #64b0f2;
  color: white;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid rgba(43, 61, 81, 0.3);
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 12px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid rgba(43, 61, 81, 0.3);
}

.select2-dropdown {
  border: 1px solid rgba(43, 61, 81, 0.3);
  box-shadow: 0 2px 2px rgba(43, 61, 81, 0.15);
}

.select2-search--dropdown {
  padding: 7px;
}

.select2-search--dropdown .select2-search__field {
  border: 1px solid rgba(43, 61, 81, 0.3);
  outline: none !important;
}

/* AUTOCOMPLETE */
.autocomplete-suggestions {
  border: 1px solid #f9f9f9;
  background: #ffffff;
  cursor: default;
  overflow: auto;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}

.autocomplete-suggestion {
  padding: 4px 10px;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-no-suggestion {
  padding: 4px 8px;
}

.autocomplete-selected {
  background: rgba(236, 238, 239, 0.5);
  cursor: pointer;
}

.autocomplete-suggestions strong {
  font-weight: 600;
  color: #2b3d51;
}

.autocomplete-group {
  padding: 2px 5px;
}

.autocomplete-group strong {
  font-weight: bold;
  font-size: 16px;
  color: #2b3d51;
  display: block;
}

/* Form validation */
.parsley-error {
  border-color: #ff5d48 !important;
}

.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}

.parsley-errors-list.filled {
  display: block;
}

.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: #ff5d48;
  margin-top: 5px;
}

.bootstrap-timepicker-widget table td input {
  border: 1px solid rgba(43, 61, 81, 0.3);
  width: 35px;
}

.datepicker-dropdown {
  padding: 10px !important;
}

.datepicker td, .datepicker th {
  width: 30px;
  height: 30px;
}

.datepicker table tr td.active:hover, .datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active, .datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active, .datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled], .datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.selected, .datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover {
  background-color: #64b0f2 !important;
  color: #ffffff !important;
  background-image: none !important;
  text-shadow: none !important;
}

.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
  background-color: #1bb99a !important;
  color: #ffffff !important;
  background-image: none !important;
}

.datepicker-inline {
  border: 2px solid rgba(43, 61, 81, 0.1);
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #64b0f2;
  border-color: #64b0f2;
}

.daterangepicker .input-mini.active {
  border: 1px solid rgba(43, 61, 81, 0.3);
}

.daterangepicker .ranges li {
  border-radius: 2px;
  color: #2b3d51;
  font-weight: 600;
  font-size: 12px;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect,
.daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  border: 1px solid rgba(43, 61, 81, 0.3);
  padding: 2px;
  width: 60px;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background-color: #64b0f2;
  border: 1px solid #64b0f2;
}

/* Wizard */
.wizard > .content {
  background: #ffffff;
  min-height: 240px;
  padding: 20px;
  border: 1px solid #eceeef;
  margin-top: 10px !important;
}

.wizard > .content > .body {
  padding: 0px;
  position: relative;
  width: 100%;
}

.wizard > .content > .body input {
  border: 1px solid #e1e4e6;
}

.wizard > .content > .body ul > li {
  display: block;
  line-height: 30px;
}

.wizard > .content > .body label.error {
  color: #ff5d48;
  margin-left: 0;
  margin-top: 5px;
  font-size: 12px;
}

.wizard > .content > .body label {
  display: inline-block;
  margin-top: 10px;
}

.wizard > .steps a {
  font-size: 16px;
  font-weight: 500;
}

.wizard > .steps .number {
  font-size: inherit;
}

.wizard > .steps .disabled a {
  background: #f7f7f9;
  color: #344a62 !important;
  cursor: default;
  border: 1px solid #e8e8ee;
}

.wizard > .steps .disabled a:hover {
  background: #e8e8ee;
  border: 1px solid #dadae3;
}

.wizard > .steps .disabled a:active {
  background: #e8e8ee;
  border: 1px solid #dadae3;
}

.wizard > .steps .current a {
  background: #64b0f2;
}

.wizard > .steps .current a:hover {
  background: #64b0f2;
}

.wizard > .steps .current a:hover .number {
  color: #ffffff;
}

.wizard > .steps .current a:active {
  background: #64b0f2;
}

.wizard > .steps .current a:active .number {
  color: #ffffff;
}

.wizard > .steps .current a .number {
  color: #ffffff;
}

.wizard > .steps .done a {
  background: #eceeef !important;
  color: #344a62 !important;
}

.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active, .wizard > .content {
  border-radius: 2px;
}

.wizard > .actions {
  margin-top: 15px !important;
}

.wizard > .actions a {
  background: #64b0f2;
  border-radius: 2px !important;
  color: #ffffff;
}

.wizard > .actions a:hover {
  background: #1d8cec;
  color: #ffffff;
}

.wizard > .actions a:active {
  background: #1d8cec;
  color: #ffffff;
}

.wizard > .actions .disabled a {
  background: #ffffff;
  color: #2b3d51;
  cursor: default;
  border: 1px solid #eaeaea;
}

.wizard > .actions .disabled a:hover {
  background: #f7f7f9;
  color: #2b3d51;
  cursor: default;
  border: 1px solid #eaeaea;
}

.wizard > .actions .disabled a:active {
  background: #f7f7f9;
  color: #2b3d51;
  cursor: default;
  border: 1px solid #eaeaea;
}

.wizard.vertical > .content {
  margin-top: 0 !important;
}

@media (max-width: 560px) {
  .wizard.vertical > .steps, .wizard.vertical > .content {
    width: 100%;
  }
}

.jFiler-input-dragDrop {
  width: 100%;
  background-color: #fafafa;
}

.jFiler-theme-default .jFiler-input {
  width: 100%;
}

.jFiler-theme-default .jFiler-input-button {
  background-color: #64b0f2;
  background-image: none !important;
  color: #ffffff;
  border: 1px solid #64b0f2 !important;
}

.jFiler-input-choose-btn {
  color: #ffffff !important;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  display: none;
}

table.dataTable thead th {
  position: relative;
  background-image: none !important;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  position: absolute;
  top: 12px;
  right: 8px;
  display: block;
  font-family: FontAwesome;
}

table.dataTable thead th.sorting:after {
  content: "\f0dc";
  color: #2b3d51;
  font-size: 1em;
  padding-top: 0.12em;
}

table.dataTable thead th.sorting_asc:after {
  content: "\f0de";
}

table.dataTable thead th.sorting_desc:after {
  content: "\f0dd";
}

.table-rep-plugin .pull-right {
  float: right !important;
}

.table-rep-plugin .btn-default {
  background-color: #ffffff;
  border: 1px solid rgba(43, 61, 81, 0.3);
}

.table-rep-plugin .btn-default.btn-primary, .table-rep-plugin .btn-default.btn-main {
  background-color: #64b0f2;
}

.table-rep-plugin table.focus-on tbody tr.focused th, .table-rep-plugin table.focus-on tbody tr.focused td {
  background-color: #64b0f2;
  color: #ffffff;
}

.table-rep-plugin .btn-toolbar {
  display: block;
}

.table-rep-plugin .pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.fixed-solution .sticky-table-header {
  top: 70px !important;
}

.table-wrapper .btn-toolbar {
  display: block;
}

.table-wrapper .dropdown-menu {
  left: auto;
  right: 0;
}

/* Tablesaw */
.tablesaw thead {
  background: #eceeef;
  background-image: none;
  border: none;
}

.tablesaw thead th {
  text-shadow: none;
  letter-spacing: 0.06em;
}

.tablesaw thead tr:first-child th {
  padding-top: 1.1em;
  padding-bottom: 0.9em;
}

.tablesaw thead tr:first-child th {
  font-weight: 600;
  font-family: inherit;
  border: none;
}

.tablesaw td, .tablesaw tbody th {
  font-size: inherit;
  line-height: inherit;
  padding: 10px !important;
}

.tablesaw-stack tbody tr, .tablesaw tbody tr {
  border-bottom: none;
}

.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after, .tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
  font-family: FontAwesome;
  font-size: 10px;
}

.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after {
  content: "\f176";
}

.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
  content: "\f175";
}

.tablesaw-bar .btn-select.btn-small:after, .tablesaw-bar .btn-select.btn-micro:after {
  font-size: 8px;
  padding-right: 10px;
}

.tablesaw-swipe .tablesaw-cell-persist {
  box-shadow: none;
}

.tablesaw-enhanced .tablesaw-bar .btn {
  text-shadow: none;
  background-image: none;
}

.tablesaw-enhanced .tablesaw-bar .btn.btn-select:hover {
  background: #ffffff;
}

.tablesaw-enhanced .tablesaw-bar .btn:hover, .tablesaw-enhanced .tablesaw-bar .btn:focus, .tablesaw-enhanced .tablesaw-bar .btn:active {
  color: #64b0f2 !important;
  background-color: #eceeef;
  outline: none !important;
  box-shadow: none !important;
  background-image: none;
}

.tablesaw-bar .tablesaw-advance a.tablesaw-nav-btn.down:before {
  left: 0.4em;
}

.tablesaw-columntoggle-btnwrap .btn-group {
  display: block;
}

.chart-detail-list li {
  margin: 0 10px;
}

/* Flot chart */
#flotTip {
  padding: 8px 12px;
  background-color: #2b3d51;
  z-index: 100;
  color: #ffffff;
  opacity: 0.9;
  font-size: 13px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.legend tr {
  height: 20px;
}

.legendLabel {
  padding-left: 5px !important;
  line-height: 10px;
  padding-right: 10px;
}

/* Morris chart */
.morris-hover.morris-default-style {
  border-radius: 5px;
  padding: 10px 12px;
}

/* Chartist chart */
.ct-golden-section:before {
  float: none;
}

.ct-chart {
  max-height: 300px;
}

.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 12px;
  line-height: 1;
}

.ct-grid {
  stroke: rgba(43, 61, 81, 0.15);
}

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 16px;
}

.ct-chart .ct-series.ct-series-a .ct-bar, .ct-chart .ct-series.ct-series-a .ct-line, .ct-chart .ct-series.ct-series-a .ct-point, .ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: #039cfd;
}

.ct-chart .ct-series.ct-series-b .ct-bar, .ct-chart .ct-series.ct-series-b .ct-line, .ct-chart .ct-series.ct-series-b .ct-point, .ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #1bb99a;
}

.ct-chart .ct-series.ct-series-c .ct-bar, .ct-chart .ct-series.ct-series-c .ct-line, .ct-chart .ct-series.ct-series-c .ct-point, .ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #f1b53d;
}

.ct-chart .ct-series.ct-series-d .ct-bar, .ct-chart .ct-series.ct-series-d .ct-line, .ct-chart .ct-series.ct-series-d .ct-point, .ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #ff7aa3;
}

.ct-chart .ct-series.ct-series-e .ct-bar, .ct-chart .ct-series.ct-series-e .ct-line, .ct-chart .ct-series.ct-series-e .ct-point, .ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #2b3d51;
}

.ct-chart .ct-series.ct-series-f .ct-bar, .ct-chart .ct-series.ct-series-f .ct-line, .ct-chart .ct-series.ct-series-f .ct-point, .ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #3db9dc;
}

.ct-chart .ct-series.ct-series-g .ct-bar, .ct-chart .ct-series.ct-series-g .ct-line, .ct-chart .ct-series.ct-series-g .ct-point, .ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #ff5d48;
}

.ct-series-a .ct-area, .ct-series-a .ct-slice-pie {
  fill: #039cfd;
}

.ct-series-b .ct-area, .ct-series-b .ct-slice-pie {
  fill: #1bb99a;
}

.ct-series-c .ct-area, .ct-series-c .ct-slice-pie {
  fill: #f1b53d;
}

.ct-series-d .ct-area, .ct-series-d .ct-slice-pie {
  fill: #ff7aa3;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 10px;
  padding: 2px 10px;
  border-radius: 3px;
  background: #2b3d51;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

/* C3 chart */
.c3 svg {
  max-width: 100%;
}

.c3-tooltip td > span {
  background: #2b3d51;
}

.c3-tooltip td {
  border-left: none;
}

.c3-tooltip {
  box-shadow: 0px 0px 8px 0px #777;
  opacity: 1;
}

.c3-chart-arcs-title {
  font-size: 18px;
  font-weight: 600;
}

.c3-tooltip tr {
  border: none !important;
}

.c3-tooltip th {
  background-color: #2b3d51;
}

.c3-tooltip .value {
  font-weight: 600;
}

/* Sparkline chart */
.jqstooltip {
  background-color: #2b3d51 !important;
  padding: 0 !important;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  border-color: #2b3d51 !important;
}

.jqsfield {
  font-size: 12px !important;
  line-height: 18px !important;
}

/* =============
   Tree view page
============= */
.jstree-default .jstree-clicked {
  background: rgba(100, 176, 242, 0.4);
  box-shadow: none;
}

.jstree-default .jstree-hovered {
  background: rgba(100, 176, 242, 0.4);
  box-shadow: none;
}

.jstree-default .jstree-wholerow-clicked {
  background: rgba(100, 176, 242, 0.4);
}

.jstree-default .jstree-wholerow-hovered {
  background: rgba(100, 176, 242, 0.4);
}

.jstree-default .zmdi, .jstree-default .navbar.navbar-menu .navbar-nav > li .dropdown-toggle:after, .navbar.navbar-menu .navbar-nav > li .jstree-default .dropdown-toggle:after, .jstree-default .navbar.navbar-menu .navbar-nav > li > .dropdown-menu li:before, .navbar.navbar-menu .navbar-nav > li > .dropdown-menu .jstree-default li:before {
  font-size: 16px;
  color: rgba(43, 61, 81, 0.5);
}

.jstree-default .jstree-icon {
  color: rgba(43, 61, 81, 0.5);
}

/* =============
   Tour page
============= */
div.hopscotch-bubble {
  border: 3px solid #64b0f2;
  border-radius: 5px;
}

div.hopscotch-bubble .hopscotch-next {
  background-color: #64b0f2 !important;
  background-image: none !important;
  border-color: #64b0f2 !important;
  text-shadow: none !important;
  margin: 0 0 0 5px !important;
}

div.hopscotch-bubble .hopscotch-prev {
  background-color: #64b0f2 !important;
  background-image: none !important;
  border-color: #64b0f2 !important;
  text-shadow: none !important;
  color: #ffffff !important;
}

div.hopscotch-bubble .hopscotch-bubble-number {
  background: #64b0f2;
  padding: 0px;
  border-radius: 50%;
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right: 19px solid #64b0f2;
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-left: 19px solid #64b0f2;
  left: -2px;
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  border: none;
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 0px solid #64b0f2;
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 19px solid #64b0f2;
  top: 0px;
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 0px solid rgba(0, 0, 0, 0.5);
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 19px solid #64b0f2;
  top: -2px;
}

div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 0px solid rgba(0, 0, 0, 0.5);
}

@media (max-width: 419px) {
  div.hopscotch-bubble .hopscotch-bubble-container {
    max-width: 180px;
  }
}

.tilebox-one i {
  font-size: 30px;
}

.tilebox-two i {
  font-size: 48px;
  opacity: 0.2;
  margin-top: 20px;
}

.widget-user {
  position: relative;
}

.widget-user img {
  width: 60px;
  height: 60px;
}

.user-position {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 44px;
  font-size: 16px;
  text-align: center;
  right: 0;
  left: auto;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.user-position span {
  transform: rotate(90deg);
}

.tilebox-three .bg-icon {
  height: 80px;
  width: 80px;
  text-align: center;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  background-clip: padding-box;
  border: 1px dashed #818a91;
  background-color: #f7f7f9;
  margin-right: 20px;
}

.tilebox-three .bg-icon i {
  line-height: 80px;
  font-size: 36px;
  color: #818a91;
}

/* Inbox-widget */
.inbox-widget .inbox-item {
  border-bottom: 1px solid #f7f7f9;
  overflow: hidden;
  padding: 15px 0;
  position: relative;
}

.inbox-widget .inbox-item .inbox-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
}

.inbox-widget .inbox-item img {
  width: 40px;
}

.inbox-widget .inbox-item .inbox-item-author {
  color: #212325;
  display: block;
  margin: 0;
}

.inbox-widget .inbox-item .inbox-item-text {
  color: #818a91;
  display: block;
  font-size: 12px;
  margin: 0;
}

.inbox-widget .inbox-item .inbox-item-date {
  color: #818a91;
  font-size: 11px;
  position: absolute;
  right: 7px;
  top: 2px;
}

/* =============
   Accounts pages
============= */
.account-pages {
  background: #64b0f2;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-size: cover;
}

.wrapper-page {
  margin: 5% auto;
  position: relative;
  max-width: 480px;
}

.wrapper-page .account-bg {
  background-color: #ffffff;
  padding: 7px;
  border-radius: 8px;
  margin: 20px 15px;
}

.wrapper-page .card-box {
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  border: 5px solid #64b0f2;
}

.wrapper-page .logo {
  font-size: 24px;
  color: #1bb99a !important;
}

.user-thumb {
  position: relative;
  z-index: 999;
}

.user-thumb img {
  height: 88px;
  margin: 0px auto;
  width: 88px;
}

.ex-page-content .text-error {
  color: #ffffff;
  font-size: 98px;
  font-weight: 700;
  line-height: 150px;
}

.ex-page-content .text-error i {
  font-size: 78px;
  padding: 0px 10px;
}

.ex-page-content .text-error.shadow {
  text-shadow: rgba(255, 255, 255, 0.3) 5px 1px, rgba(255, 255, 255, 0.2) 12px 3px, rgba(255, 255, 255, 0.1) 6px 4px;
}

/*#6B0081;*/
/*740086*/
/*#18A798;*/
.radio:not(.radio-styled) label:before, .radio:not(.radio-styled) label:after {
  margin-left: -15px;
}

[v-cloak], [ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

html.cloaked [ready\:cloak], html.cloaked [ready-cloak], html.cloaked [data-ready-cloak], html.cloaked .ready-cloak {
  display: none !important;
}

/**
 *   DataTables Bootstrap
 **/
table.dataTable {
  border: 0 !important;
  width: 100% !important;
}

table.dataTable [type="button"] {
  -webkit-appearance: none;
}

table.dataTable .dataTables_empty {
  padding: 16px 0 !important;
}

table.dataTable thead th {
  border: none;
}

table.table-bordered.dataTable td:first-child,
table.table-bordered.dataTable th:first-child {
  border-left-width: 1px;
}

table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable th:last-child {
  border-right-width: 1px;
}

table.table-bordered.dataTable tbody tr:last-child td {
  border-bottom-width: 1px;
}

table.dataTable tbody tr td:last-child {
  padding-top: 8px;
  padding-bottom: 0;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after,
table.dataTable thead th.sorting_asc_disabled:after,
table.dataTable thead th.sorting_desc_disabled:after {
  font-family: 'Font Awesome 5 Pro';
}

table.dataTable thead .sorting:after {
  content: "\f0dc";
  /* sort */
}

table.dataTable thead .sorting_asc:after {
  content: "\f0de";
  /* sort-by-attributes */
}

table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  /* sort-by-attributes-alt */
}

/* Datetimepicer */
.bootstrap-datetimepicker-widget table td.disabled, .bootstrap-datetimepicker-widget table td.disabled:hover {
  color: #777777;
}

/* Summernote */
.note-popover .popover-content .dropdown-menu,
.panel-heading.note-toolbar .dropdown-menu {
  min-width: 123px;
}

.note-editor.note-frame {
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.note-toolbar.panel-heading {
  border-radius: 4px 4px 0 0;
}

.note-editor.note-frame .note-statusbar {
  border-radius: 0 0 4px 4px;
}

.note-editable img, .panel-content img {
  display: block;
  max-width: 100%;
  height: auto;
}

.note-editor .panel-heading {
  color: #333333;
  background-color: #f5f5f5;
  border-color: #dddddd;
}

.panel-body img[style*="float: right"], .panel-body img[style*="float:right"] {
  margin-left: 10px;
}

.panel-body img[style*="float: left"], .panel-body img[style*="float:left"] {
  margin-right: 10px;
}

.link-dialog .modal-header .close {
  top: 21px;
  right: 20px;
  position: absolute;
}

.news-editor .note-options {
  display: none;
}

.fancybox-overlay .fancybox-close {
  background-image: url(../images/fancybox_sprite_purple.png);
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  .fancybox-overlay .fancybox-close {
    background-image: url(../images/fancybox_sprite_purple@2x.png);
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
}

@media (min-width: 767px) {
  .fancybox-overlay .fancybox-close {
    top: 0;
    right: -41px;
  }
  .fancybox-overlay .fancybox-skin {
    border-radius: 0;
    text-shadow: none;
  }
  .fancybox-overlay .fancybox-prev span {
    color: white;
    font-size: 40px;
    line-height: 40px;
    visibility: visible;
    background: transparent;
    font-family: 'Font Awesome 5 Pro';
  }
  .fancybox-overlay .fancybox-prev span:after {
    content: '\f053';
  }
  .fancybox-overlay .fancybox-next span {
    color: white;
    font-size: 40px;
    line-height: 38px;
    visibility: visible;
    background: transparent;
    font-family: 'Font Awesome 5 Pro';
  }
  .fancybox-overlay .fancybox-next span:after {
    content: '\f054';
  }
}

/* drag & drop list */
.dd-item {
  cursor: move;
}

.dd-item .dd-handle {
  position: relative;
}

.dd-item .dd-item-btn-right {
  right: 10px;
  position: absolute;
}

/* Selectize */
.has-error .selectize-control .selectize-input {
  border-color: #a94442;
}

.selectize-control {
  text-align: left;
}

.selectize-control .description {
  display: block;
  font-size: 12px;
}

.selectize-control.selectize_single .selectize-input > div,
.selectize-control.selectize_single .selectize-input > div.active {
  width: 100%;
  color: #333333;
  background: transparent;
}

.selectize-control.selectize_single.plugin-remove_button [data-value] .remove {
  color: #c2c2c2;
  font-size: 31px;
}

.selectize-control.input-lg.multi .selectize-input.has-items,
.selectize-control.input-lg .selectize-input {
  padding: 13px 15px;
}

.selectize-control.single:not(.selectize-hide-dropdown) .selectize-input:after {
  top: auto;
  right: 17px;
  border: none;
  content: "\f078";
  color: #c2c2c2;
  position: absolute;
  padding-right: 8px;
  margin-top: -2px;
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Pro';
}

.selectize-control.single:not(.selectize-hide-dropdown) .selectize-input.dropdown-active:after {
  border: none;
  content: "\f077";
}

.selectize-control.single.selectize-hide-dropdown .selectize-input:after {
  display: none;
}

.selectize-control .selectize-dropdown {
  text-align: left;
}

.selectize-control .selectize-dropdown .selectize-dropdown-content {
  border-radius: 4px;
}

.selectize-control .selectize-input {
  font-weight: 400;
  border-color: #D9D9D9;
  border-radius: 4px;
}

.selectize-control .selectize-input.dropdown-active {
  border-radius: 4px;
}

fieldset[disabled] .form-control.selectize-control:not(.tags-min-height) {
  height: 34px;
}

/* Tooltipster */
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #0d9c90 !important;
}

.tooltipster-sidetip .tooltipster-arrow-border {
  border: none !important;
}

.tooltipster-sidetip .tooltipster-box {
  background: #b4e391 !important;
  background: -moz-linear-gradient(top, #b4e391 0%, #61c419 50%, #b4e391 100%) !important;
  background: -webkit-linear-gradient(top, #b4e391 0%, #61c419 50%, #b4e391 100%) !important;
  background: linear-gradient(#add333, #0d9c90) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b4e391', endColorstr='#b4e391', GradientType=0) !important;
  border: none !important;
  max-width: 350px;
  word-wrap: break-word;
}

sup {
  color: red !important;
}

@media (min-width: 767px) {
  .position-absolute-right {
    right: 0;
    position: absolute;
  }
}

.rounded-main {
  border-radius: 13px;
}

blockquote {
  font-weight: bold;
  color: #acd52a;
  font-size: 23px;
  line-height: 26px;
  margin-bottom: 10px;
  letter-spacing: 0.01em;
}

blockquote p:first-child {
  font-weight: bold;
  color: #acd52a;
  font-size: 23px;
  line-height: 26px;
  margin-bottom: 10px;
  letter-spacing: 0.01em;
}

blockquote p:last-of-type {
  color: #00587b;
  font-size: 16px;
  font-weight: 600;
}

.h-auto {
  height: auto;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.text-facebook {
  color: #3b5998 !important;
}

.text-twitter {
  color: #00aced !important;
}

.text-linkedin {
  color: #007bb6 !important;
}

.text-googleplus {
  color: #dd4b39 !important;
}

.text-instagram {
  color: #517fa4 !important;
}

.text-pinterest {
  color: #cb2027 !important;
}

.navbar-header {
  width: 100%;
}

.btn-xs, .btn-group-xs > .btn {
  font-size: 15px;
  padding: 3px 10px;
}

.btn-group-styled {
  height: 26px;
}

.btn-group-styled .btn {
  color: #333333;
  font-size: 11px;
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: none !important;
  padding: 3px 8px !important;
}

.btn-group-styled .btn:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.btn-group-styled .btn:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.btn-group-styled .btn.active {
  color: #ffffff;
  background: #434343;
}

.input-group-addon {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 400;
  line-height: 23px;
  padding: 6px 12px;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid #d9d9d9;
  border-right: 0;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.block {
  display: block;
}

.absolute {
  position: absolute;
}

.inline {
  display: inline;
}

.no-radius {
  border-radius: 0;
}

.dl-spaced dd {
  margin-bottom: 20px;
}

.dl-horizontal-inline dt {
  float: left;
  width: 160px;
  clear: left;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.testimonial {
  color: #ffffff;
}

.testimonial .testimonial-content {
  width: 86%;
  float: right;
  margin-top: -58px;
  font-style: italic;
  font-weight: normal;
  border-radius: 4px;
  padding: 31px 20px 15px 41px;
  background: #ff9b4a;
}

.testimonial .testimonial-content p {
  margin-bottom: 0;
}

.testimonial .testimonial-content .testimonial-user-name {
  font-style: normal;
  font-weight: bold;
}

@media (max-width: 766px) {
  .testimonial .testimonial-content {
    margin-top: -42px;
    margin-bottom: -3px;
  }
}

.testimonial .testimonial-user-image {
  width: 81px;
  height: 81px;
  z-index: 100;
  position: relative;
  border-radius: 50%;
}

@media (max-width: 766px) {
  .testimonial .testimonial-user-image {
    width: 60px;
    height: 60px;
    min-height: 60px;
  }
}

.testimonial.testimonial-width .testimonial-content {
  width: 94%;
}

.testimonial.testimonial-width .testimonial-content .stars-col {
  font-style: normal;
  padding-bottom: 12px;
  border-left: 1px solid #ffffff;
}

.testimonial.testimonial-width .testimonial-content .stars-col .stars-review {
  font-size: 24px;
}

@media (max-width: 766px) {
  .testimonial.testimonial-width .testimonial-content {
    margin-top: -46px;
  }
  .testimonial.testimonial-width .testimonial-content .stars-col {
    border: none;
    margin-top: 20px;
  }
}

.stars > .fa-star {
  color: #FFFFFF;
  background: #DCDCDC;
  border-radius: 50%;
  font-size: 11px;
  line-height: 1;
  margin: 0;
  padding: 3px 3px 4px 3px;
}

.stars[data-score="1"] > .fa-star:nth-child(-n+1) {
  color: #ffffff;
  background: #b2d233;
}

.stars[data-score="2"] > .fa-star:nth-child(-n+2) {
  color: #ffffff;
  background: #b2d233;
}

.stars[data-score="3"] > .fa-star:nth-child(-n+3) {
  color: #ffffff;
  background: #b2d233;
}

.stars[data-score="4"] > .fa-star:nth-child(-n+4) {
  color: #ffffff;
  background: #b2d233;
}

.stars[data-score="5"] > .fa-star:nth-child(-n+5) {
  color: #ffffff;
  background: #b2d233;
}

.modal-popup {
  right: 0;
  bottom: 0;
  width: 400px;
  display: block;
  z-index: 100000;
  position: fixed;
  margin-bottom: 0;
  margin-right: 20px;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.modal-popup:not(.in) {
  opacity: 0;
}

.modal-popup .modal-title {
  font-size: 28px;
  font-weight: 700 !important;
  line-height: 34px;
}

.modal-popup .modal-content {
  outline: 0;
  background-color: #fff;
  border: 1px solid rgba(228, 228, 228, 0.6);
  border-radius: .3rem .3rem 0 0;
  box-shadow: 0 0px 33px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 8px 1px rgba(76, 184, 73, 0.3);
  border-bottom: 0;
}

.modal-popup .modal-footer {
  text-align: center;
  display: inline-block;
}

.modal-popup .modal-footer .btn {
  font-weight: 600;
}

.modal-popup .close {
  z-index: 1;
  color: green;
  color: #ffffff;
  background: #4cb849;
  border-radius: 50%;
  line-height: 41px;
  font-weight: 500;
  opacity: 1;
  font-size: 38px;
  width: 36px;
  position: absolute;
  right: 15px;
  padding: 0;
  top: -16px;
  margin: 0;
}

.modal-popup .close > span {
  display: block;
  margin-top: -5px;
}

@media (min-width: 2000px) {
  .modal-popup {
    width: 500px;
  }
}

@media (max-width: 450px) {
  .modal-popup {
    width: calc(100% - 30px) !important;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  .modal-popup .modal-title {
    font-size: 22px;
  }
  .modal-popup .close {
    width: 32px;
    font-size: 32px;
    line-height: 37px;
  }
  .modal-popup .modal-footer {
    padding: 10px;
  }
  .modal-popup .modal-footer .btn {
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
  }
}

.input-group-no-border .input-group {
  border: 0;
}

.input-group-no-border .input-group .form-control, .input-group-no-border .input-group .input-group-addon {
  border: 0;
}

.input-group-no-radius .input-group {
  border-radius: 0;
}

.input-group-no-radius .input-group .form-control, .input-group-no-radius .input-group .input-group-addon {
  border-radius: 0;
}

.input-group-addon {
  border-radius: 2px;
}

.input-group-addon > i.fa {
  color: #8a8a8a;
}

.input-group-transparent .input-group.input-daterange .form-group:first-of-type .form-control, .input-group-transparent .input-group.input-timerange .form-group:first-of-type .form-control {
  border-right: none;
  box-shadow: none !important;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.input-group-transparent .input-group.input-daterange .form-group:last-of-type .form-control, .input-group-transparent .input-group.input-timerange .form-group:last-of-type .form-control {
  border-left: none;
  box-shadow: none !important;
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 4px;
}

.input-group-transparent .input-group:not(.input-daterange):not(.input-timerange) .form-control {
  border-right: none;
  box-shadow: none !important;
}

.input-group-transparent .input-group .input-group-addon {
  background-color: #ffffff;
}

.bg-image, .page-container {
  background: transparent center / cover no-repeat;
}

.bg-image-hide-img {
  overflow: hidden;
  background: transparent center / cover no-repeat;
}

.bg-image-hide-img img {
  opacity: 0;
}

.box-shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.box-shadow-light {
  box-shadow: 0 0 1px 0.5px rgba(0, 0, 0, 0.14);
}

.box-shadow-dark {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.nav:not(.nav-menu) li a, .nav:not(.nav-menu) li a:hover, .nav:not(.nav-menu) li a:focus {
  border: none;
  color: #ffffff;
  font-weight: 700;
  background-color: #00587b;
  border-radius: 4px;
}

.nav:not(.nav-menu) li.active a, .nav:not(.nav-menu) li.active a:hover, .nav:not(.nav-menu) li.active a:focus {
  color: #000001;
  background-color: #00587b;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

/** Forms **/
.form-group .form-control + .help-block {
  display: block;
  padding-top: 3px;
}

.form-control {
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
}

.form-control.no-radius {
  border-radius: 0;
}

.form-control::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #B0B0B0;
  font-weight: normal;
}

.form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #B0B0B0;
  opacity: 1;
  font-weight: normal;
}

.form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #B0B0B0;
  opacity: 1;
  font-weight: normal;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #B0B0B0;
  font-weight: normal;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 6px 0 0;
}

.select-styled:before {
  right: 0;
  background: #ffffff;
  content: "\f078";
  color: #c2c2c2;
  position: absolute;
  display: inline-block;
  text-rendering: auto;
  padding: 0 10px;
  -webkit-transform: translate(0, 50%);
  -moz-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  -o-transform: translate(0, 50%);
  transform: translate(0, 50%);
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
}

.select-styled select::-ms-expand {
  display: none;
}

.input-group-addon {
  color: #8a8a8a;
}

.input-group-addon:first-child {
  border-radius: 0;
}

.label-small label {
  font-size: 15px;
  font-weight: normal;
}

label {
  color: #000001;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.lh-1 {
  line-height: 1;
}

.tags-min-height .selectize-input {
  min-height: 100px;
}

/** Forms **/
.btns-form {
  margin-top: 5px;
  padding-top: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

[data-radio-show]:not(.show-all) .form-group > .radio {
  display: none;
}

[data-radio-show]:not(.show-all) .form-group > .radio:nth-child(-n+4) {
  display: block;
}

[data-radio-show].show-all .show-more {
  display: none;
}

[data-radio-show] .show-more {
  padding: 0 !important;
  color: #DFDFDF;
  margin-left: 24px;
  text-decoration: none;
}

.text-light {
  font-weight: 400;
}

.text-normal {
  font-weight: normal;
}

.text-thicker {
  font-weight: 500;
}

.text-thick,
.bold {
  font-weight: bold !important;
}

.list-line {
  list-style-type: none;
}

.list-line li:before {
  content: "-";
  margin-left: -46px;
  margin-right: 3px;
}

.carousel-control .icon-next, .carousel-control .icon-prev {
  font-family: "FontAwesome";
}

.carousel-control .fa-chevron-left:before {
  content: "\f053" !important;
}

.carousel-control .fa-chevron-right:before {
  content: "\f054" !important;
}

.item-default {
  padding: 10px;
  border-radius: 4px;
}

.item-default h5 {
  color: #00587b;
  margin-top: 5px;
  font-weight: bold;
}

.item-default + .item-default {
  margin-top: 25px;
}

.nav.nav-tabs li:first-of-type a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.nav.nav-tabs li:last-of-type a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.nav.nav-tabs li a {
  color: #000001;
  border: none;
  border-radius: 0;
  background: #ffffff;
}

.nav.nav-tabs li.active a, .nav.nav-tabs li.active a:focus {
  color: #ffffff;
  border: none !important;
}

.navbar {
  margin-bottom: 0;
}

.navbar.navbar-menu {
  border: 0;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  /**
    TODO: Checken!
     */
}

@media (max-width: 767px) {
  .navbar.navbar-menu li.dropdown.open a, .navbar.navbar-menu li.dropdown:hover a {
    border-radius: 0;
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

.navbar.navbar-menu .navbar-brand {
  padding: 0;
}

.navbar.navbar-menu .navbar-brand > h2 {
  margin: 0;
  font-size: 24px;
}

@media (max-width: 767px) {
  .navbar.navbar-menu .navbar-brand > h2 {
    margin: 0;
  }
}

.navbar.navbar-menu .navbar-brand img {
  max-width: 228px;
  margin-right: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 767px) {
  .navbar.navbar-menu .navbar-brand {
    margin-top: 0;
  }
  .navbar.navbar-menu .navbar-brand > h2 {
    font-size: 16px;
  }
  .navbar.navbar-menu .navbar-brand img {
    width: 156px;
    margin-top: 1px !important;
  }
}

.navbar.navbar-menu .navbar-nav {
  margin-top: 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

@media (min-width: 768px) {
  .navbar.navbar-menu .navbar-nav {
    float: right;
  }
}

.navbar.navbar-menu .navbar-nav > li {
  line-height: 2.4;
}

.navbar.navbar-menu .navbar-nav > li.active > a:not(.btn) {
  color: #00587b;
}

.navbar.navbar-menu .navbar-nav > li > a {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 16px;
  line-height: 3;
  font-weight: 600;
  padding: 0 30px;
}

.navbar.navbar-menu .navbar-nav > li > a:not(.btn) {
  color: #000001;
}

@media (max-width: 767px) {
  .navbar.navbar-menu .navbar-nav > li > a {
    line-height: 1;
    padding: 11px 8px 5px;
  }
}

.navbar.navbar-menu .navbar-nav > li > a:hover:not(.btn), .navbar.navbar-menu .navbar-nav > li > a:focus {
  color: #00587b;
  background-color: transparent;
}

.navbar.navbar-menu .navbar-nav > li .dropdown-toggle:after {
  border: none;
  margin-left: 8px;
  vertical-align: baseline;
  color: #00587b;
  content: '\f2f9';
  font-weight: bold;
}

.navbar.navbar-menu .navbar-nav > li > .dropdown-menu {
  border: 0;
  padding: 20px;
  min-width: 200px;
  border-radius: 7px;
}

.navbar.navbar-menu .navbar-nav > li > .dropdown-menu li:before {
  color: #00587b;
  content: '\f2fb';
  font-weight: bold;
}

.navbar.navbar-menu .navbar-nav > li > .dropdown-menu li a {
  color: #333333;
  margin-left: 4px;
}

@media (max-width: 767px) {
  .navbar.navbar-menu .navbar-collapse {
    background: #ffffff;
  }
}

.navbar.navbar-menu.smaller .navbar-brand {
  margin-top: 12px;
}

.navbar.navbar-menu.smaller .navbar-brand img {
  max-width: 200px;
}

.navbar.navbar-menu.smaller .navbar-nav {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar.navbar-menu.smaller .navbar-nav > li {
  line-height: 2;
}

.navbar-toggle {
  border: 1px solid #888888;
}

.navbar-toggle .icon-bar {
  background-color: #888888;
}

/**
 * Buttons
 */
.btn:not(.btn-sm):not(.btn-xs):not(.btn-lg) {
  padding: 6px 16px;
}

[data-toggle="collapse"]:not(.collapsed) .more {
  display: none;
}

[data-toggle="collapse"].collapsed .less {
  display: none;
}

.btn:hover {
  text-decoration: none;
}

.btn-link:active, .btn-link:focus, .btn-link-clean:active, .btn-link-clean:focus {
  outline: none !important;
}

.btn-white {
  color: #00587b;
  border-color: #ffffff;
  background-color: #ffffff;
}

.btn-white:hover, .btn-white:active, .btn-white:focus, .btn-white:active:focus {
  color: #ffffff;
  border-color: #0062A7;
  background-color: #0062A7;
}

.btn-main {
  color: #ffffff;
  border-color: #0062A7;
  background-color: #0062A7;
}

.btn-main:hover, .btn-main:active, .btn-main:focus, .btn-main:active:focus {
  color: #0062A7;
  border-color: #0062A7;
  background-color: #EFEFEF;
}

.btn-main-3 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: #00587b;
  border-color: #ffffff;
  background-color: #ffffff;
  border: none;
  border-radius: 2px;
  display: inline-block;
  line-height: 36px;
  outline: 0;
  padding: 13px 55px;
  font-weight: bold;
  font-size: 20px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.btn-main-3:hover, .btn-main-3:active, .btn-main-3:focus, .btn-main-3:active:focus {
  color: #0062A7;
  border-color: #0062A7;
  background-color: #EFEFEF;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.btn-purple, .btn-feedback {
  color: #ffffff;
  border-color: #741385;
  background-color: #761487;
}

.btn-purple:hover, .btn-purple:active, .btn-purple:focus, .btn-purple:active:focus, .btn-feedback:hover, .btn-feedback:active, .btn-feedback:focus, .btn-feedback:active:focus {
  color: #771386;
  border-color: #741385;
  background-color: transparent;
}

.btn-success {
  border-color: #25a398;
  background-color: #25a398;
}

.btn-success:hover, .btn-success:active, .btn-success:focus, .btn-success:active:focus {
  border-color: #25a398;
  background-color: #25a398;
}

.btn-styled {
  background-color: transparent;
  -webkit-transition: 0.5s;
  /* Safari */
  transition: 0.5s;
  border-width: 2px;
}

.btn-styled.btn-main {
  color: #00587b;
  border-color: #0062A7;
}

.btn-styled.btn-main:hover, .btn-styled.btn-main:active, .btn-styled.btn-main:focus, .btn-styled.btn-main:active:focus {
  color: #ffffff;
  background-color: #0062A7;
}

.btn-styled.btn-info {
  color: #5bc0de;
  border-color: #46b8da;
}

.btn-styled.btn-info:hover, .btn-styled.btn-info:active, .btn-styled.btn-info:focus, .btn-styled.btn-info:active:focus {
  color: #ffffff;
  background-color: #5bc0de;
}

.btn-styled.btn-default {
  color: #ffffff;
  border-color: #ffffff;
}

.btn-styled.btn-default:hover, .btn-styled.btn-default:active, .btn-styled.btn-default:focus, .btn-styled.btn-default:active:focus {
  color: #000001;
  background-color: #ffffff;
}

.btn-styled.btn-danger {
  color: #d9534f;
  border-color: #d43f3a;
}

.btn-styled.btn-danger:hover, .btn-styled.btn-danger:active, .btn-styled.btn-danger:focus, .btn-styled.btn-danger:active:focus {
  color: #ffffff;
  background-color: #d9534f;
}

.btn-styled.btn-success {
  color: #25a398;
  border-color: #25a398;
}

.btn-styled.btn-success:hover, .btn-styled.btn-success:active, .btn-styled.btn-success:focus, .btn-styled.btn-success:active:focus {
  color: #ffffff;
  background-color: #25a398;
}

.btn-styled.btn-white {
  color: #ffffff;
  border-color: #ffffff;
}

.btn-styled.btn-white:hover, .btn-styled.btn-white:active, .btn-styled.btn-white:focus, .btn-styled.btn-white:active:focus {
  color: #25a398;
  background-color: #ffffff;
}

.title-bar {
  padding-top: 8px;
  padding-bottom: 16px;
  background-color: #EFEFEF;
}

.title-bar h1 {
  color: #ffffff;
  font-size: 26px;
  font-weight: 800;
  font-style: italic;
}

.title-bar .btn-styled {
  font-size: 12px;
}

@media (min-width: 768px) {
  .title-bar .btn-styled {
    font-size: 16px;
    margin-top: 9px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: -14px;
    border: 3px solid;
  }
}

.panel {
  border: 0;
  border-radius: 4px;
}

.panel.panel-card {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

.panel.panel-card.panel-padderd {
  padding: 10px;
}

.panel.panel-card .panel-title {
  color: #00587b;
}

.panel.panel-card .panel-body > :first-child {
  margin-top: 0;
}

.panel.panel-card .panel-body .panel-help {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #edeff0;
}

.panel.panel-card.panel-author .panel-body {
  padding: 5px;
  padding-bottom: 15px;
}

.panel.panel-card.panel-author .panel-body .col-img {
  padding: 0 20px 0 20px;
}

.panel.panel-card.panel-author .panel-body h6 {
  font-size: 21px;
  margin-top: 17px;
  margin-bottom: 5px;
}

.panel.panel-card.panel-author .panel-body .author-text {
  margin: 0;
  color: #728f95;
  margin-top: 14px;
}

.panel.panel-card.panel-author .panel-body .arrow-small-purple {
  margin-left: -32px;
  position: absolute;
  transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
}

.panel.panel-clean {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #DFDFDF;
}

.panel.panel-clean .panel-heading {
  padding: 20px 15px 0;
}

.panel.panel-clean .panel-heading .panel-title {
  color: #000001;
  font-size: 18px;
  font-weight: 600;
}

.panel.panel-clean .panel-body {
  font-size: 15px;
  padding-bottom: 12px;
}

.panel.panel-clean .panel-body p {
  margin: 0;
}

.nav.nav-pills.nav-justified li > a {
  color: #ffffff;
  margin: 0 5px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.9px;
  background-color: #0062A7;
  border-radius: 4px;
}

.nav.nav-pills.nav-justified li > a:hover {
  background-color: transparent;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.nav.nav-pills.nav-justified li.active > a {
  color: #000001;
  background-color: #fcde34;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.nav.nav-pills.nav-justified li:first-of-type > a {
  margin-left: 0;
}

.nav.nav-pills.nav-justified li:last-of-type > a {
  margin-right: 0;
}

@media (max-width: 767px) {
  .nav.nav-pills.nav-justified li + li {
    margin-left: 0;
  }
}

.info-block {
  display: block;
  font-weight: 600;
  min-height: 65px;
  padding: 9px 10px;
  margin-bottom: 20px;
  border: 1px solid #DFDFDF;
}

.info-block i {
  float: left;
  font-size: 35px;
  margin-top: 6px;
  margin-left: 6px;
  margin-right: 16px;
}

.info-block p {
  margin: 0;
}

.avatar {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background: transparent center / cover no-repeat;
}

.partners img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  padding: 0 10px;
  opacity: 0.4;
}

.file-button {
  display: block;
  border-radius: 6px;
  border: 1px solid #DFDFDF;
}

.file-button > .row {
  padding: 9px 5px;
}

.file-button .fa[class*="fa-file-"] {
  font-size: 40px;
  margin-top: 5px;
}

.file-button .label {
  z-index: 100;
  position: relative;
  margin-top: -20px;
}

.file-button .file-name {
  line-height: 2;
}

.file-button .file-link:hover .file-info {
  display: none;
  transition: all 0.2s ease-in-out 0s;
}

.file-button .file-link:not(:hover) .file-meta {
  display: none;
  transition: all 0.2s ease-in-out 0s;
}

.color-file.docx {
  color: #2c4098;
}

.color-file.pdf {
  color: #db4437;
}

form[data-prepost] .prepost-loader {
  display: none;
}

form[data-prepost].prepost-loading .prepost-form {
  display: none;
}

form[data-prepost].prepost-loading .prepost-loader {
  display: block;
}

form[data-prepost].prepost-loading .prepost-loader .fa {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

form[data-prepost].prepost-loading .prepost-loading-disable {
  filter: alpha(opacity=65);
  opacity: .65;
  box-shadow: none;
  cursor: not-allowed;
  pointer-events: none;
}

@media (min-width: 992px) {
  .carousel-multi {
    /* show 3 items */
    /* left or forward direction */
    /* farthest right hidden item must be abso position for animations */
    /* right or prev direction */
  }
  .carousel-multi .carousel-inner .active,
  .carousel-multi .carousel-inner .active + .carousel-item,
  .carousel-multi .carousel-inner .active + .carousel-item + .carousel-item {
    display: block;
  }
  .carousel-multi .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .carousel-multi .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item,
  .carousel-multi .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
    transition: none;
  }
  .carousel-multi .carousel-inner .carousel-item-next,
  .carousel-multi .carousel-inner .carousel-item-prev {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  .carousel-multi .carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -33%;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  .carousel-multi .active.carousel-item-left + .carousel-item-next.carousel-item-left,
  .carousel-multi .carousel-item-next.carousel-item-left + .carousel-item,
  .carousel-multi .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
  .carousel-multi .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  .carousel-multi .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: -33%;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  .carousel-multi .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
  .carousel-multi .carousel-item-prev.carousel-item-right + .carousel-item,
  .carousel-multi .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
  .carousel-multi .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .carousel-multi {
    /* show 2 items */
    /* left or forward direction */
    /* farthest right hidden item must be abso position for animations */
    /* right or prev direction */
  }
  .carousel-multi .carousel-inner .active,
  .carousel-multi .carousel-inner .active + .carousel-item {
    display: block;
  }
  .carousel-multi .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .carousel-multi .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
    transition: none;
  }
  .carousel-multi .carousel-inner .carousel-item-next,
  .carousel-multi .carousel-inner .carousel-item-prev {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  .carousel-multi .carousel-inner .active.carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -50%;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  .carousel-multi .active.carousel-item-left + .carousel-item-next.carousel-item-left,
  .carousel-multi .carousel-item-next.carousel-item-left + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  .carousel-multi .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  .carousel-multi .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
  .carousel-multi .carousel-item-prev.carousel-item-right + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}

@media (max-width: 767px) {
  .carousel-multi {
    /* show 2 items */
    /* left or forward direction */
    /* farthest right hidden item must be abso position for animations */
    /* right or prev direction */
  }
  .carousel-multi .carousel-inner .active {
    display: block;
  }
  .carousel-multi .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) {
    transition: none;
  }
  .carousel-multi .carousel-inner .carousel-item-next,
  .carousel-multi .carousel-inner .carousel-item-prev {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  .carousel-multi .carousel-inner .active.carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -100%;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  .carousel-multi .active.carousel-item-left + .carousel-item-next.carousel-item-left,
  .carousel-multi .carousel-item-next.carousel-item-left + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  .carousel-multi .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  .carousel-multi .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
  .carousel-multi .carousel-item-prev.carousel-item-right + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
  }
}

.carousel-multi a[data-slide] {
  color: gray;
  font-weight: bold;
}

.carousel-multi .carousel {
  margin-top: 37px;
  margin-bottom: 67px;
}

.carousel-multi .carousel .carousel-control-next,
.carousel-multi .carousel .carousel-control-prev {
  opacity: 1;
}

.carousel-multi .carousel .carousel-control-next > i,
.carousel-multi .carousel .carousel-control-prev > i {
  font-size: 46px;
  color: #312f2f !important;
}

@media (max-width: 1392px) {
  .carousel-multi .carousel-control-next,
  .carousel-multi .carousel-control-prev {
    width: 10%;
  }
}

@media (max-width: 1280px) {
  .carousel-multi .carousel-control-next,
  .carousel-multi .carousel-control-prev {
    width: 6%;
  }
}

@media (max-width: 1199px) {
  .carousel-multi .carousel-control-next,
  .carousel-multi .carousel-control-prev {
    width: 11%;
  }
}

@media (max-width: 1113px) {
  .carousel-multi .carousel-control-next,
  .carousel-multi .carousel-control-prev {
    width: 6%;
  }
}

@media (max-width: 1047px) {
  .carousel-multi .carousel-control-next,
  .carousel-multi .carousel-control-prev {
    width: 3%;
  }
}

@media (max-width: 989px) {
  .carousel-multi .carousel-control-next,
  .carousel-multi .carousel-control-prev {
    width: 15%;
  }
}

@media (max-width: 886px) {
  .carousel-multi .carousel-control-next,
  .carousel-multi .carousel-control-prev {
    width: 10%;
  }
}

@media (max-width: 804px) {
  .carousel-multi .carousel-control-next,
  .carousel-multi .carousel-control-prev {
    width: 6%;
  }
}

@media (max-width: 500px) {
  .carousel-multi .carousel-item {
    width: 100%;
  }
}

#CipeMotionMediaPickerModal .modal-body {
  padding: 0 !important;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}

#CipeMotionMediaPickerModal.modal .modal-lg {
  width: 80%;
  max-width: 1300px;
}

#CipeMotionMediaPickerModal.modal .nav-tabs .active {
  color: #ffffff;
  background-color: #005882;
}

#CipeMotionMediaPickerModal.modal .tab-content-wrapper {
  overflow: auto;
}

#CipeMotionMediaPickerModal.modal .tab-content {
  overflow: hidden;
}

#CipeMotionMediaPickerModal.modal .media-info, #CipeMotionMediaPickerModal.modal .tab-content {
  min-height: 400px;
}

#CipeMotionMediaPickerModal.modal .media-info {
  overflow: scroll;
  padding-top: 10px;
}

#CipeMotionMediaPickerModal.modal .dropzone {
  border: 2px dashed #d1d7db;
  border-radius: 3px;
}

#CipeMotionMediaPickerModal.modal .dropzone .dz-message {
  width: 100%;
  text-align: center;
}

#CipeMotionMediaPickerModal.modal .mediapicker-search-position {
  margin-top: 7px;
  margin-right: 7px;
}

#CipeMotionMediaPickerModal.modal .mediaLibrary-images .medialibrary-image {
  width: 100%;
  height: 125px;
  display: block;
  border: 1px solid #d3d3d3;
  background: #fff center / cover no-repeat;
  border-radius: 0;
}

#CipeMotionMediaPickerModal.modal .mediaLibrary-images .medialibrary-image .fa {
  font-size: 6em;
  margin-top: 22px;
}

#CipeMotionMediaPickerModal.modal .mediaLibrary-images .medialibrary-title {
  background-color: #fff;
  font-size: 10px;
  padding: 5px 5px;
  height: 40px;
  border: 1px solid #d3d3d3;
  border-bottom-color: #c4c4c4;
  border-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
}

#CipeMotionMediaPickerModal.modal .mediaLibrary-images [data-medialibrary] {
  cursor: pointer;
  margin-bottom: 10px;
  box-shadow: 3px 3px 8px -2px rgba(0, 0, 0, 0.04);
}

#CipeMotionMediaPickerModal.modal .mediaLibrary-images [data-medialibrary]:hover .medialibrary-image, #CipeMotionMediaPickerModal.modal .mediaLibrary-images [data-medialibrary].img-selected .medialibrary-image {
  padding: 0;
  transition: none;
  border-color: rgba(0, 116, 217, 0.3);
  border-bottom-color: #d3d3d3;
  box-shadow: 0 0 9px 11px rgba(0, 116, 217, 0.2) inset;
}

#CipeMotionMediaPickerModal.modal .mediaLibrary-images [data-medialibrary].img-selected:after {
  content: "\f00c";
  color: #fff;
  background-color: lightblue;
  position: absolute;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-size-adjust: none;
  font-stretch: normal;
  font-feature-settings: normal;
  font-language-override: normal;
  font-kerning: auto;
  font-synthesis: weight style;
  font-variant: normal;
  font-size: inherit;
  text-rendering: auto;
  -moz-osx-font-smoothing: grayscale;
  left: 15px;
  top: 15px;
  font-size: 18px;
  padding: 2px 3px;
  border: 1px solid white;
  left: auto;
  right: 20px;
  top: 6px;
}

@media (max-width: 767px) {
  #CipeMotionMediaPickerModal.modal .mediaLibrary-images [data-medialibrary].img-selected:after {
    left: auto;
    right: 20px;
    top: 6px;
  }
}

.page-container {
  margin-top: 80px;
  margin-bottom: 30px;
}

.menu-info-tab {
  margin-top: 4px;
}

.menu-info-tab:not(.btn):hover {
  text-decoration: none !important;
}

.menu-info-tab .menu-info-tab-icon {
  color: #00587b;
  font-size: 21px;
  margin-right: 10px;
  vertical-align: top;
}

.menu-info-tab .menu-info-tab-title {
  color: #00587b;
  margin: 0 0 2px;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 2px;
}

@media (max-width: 371px) {
  .menu-info-tab .menu-info-tab-title {
    font-size: 13px;
    letter-spacing: 0;
  }
}

@media (max-width: 446px) {
  .menu-info-tab .menu-info-tab-title {
    font-size: 13px;
    letter-spacing: 0;
  }
}

.menu-info-tab .menu-small-show {
  display: none;
}

.menu-info-tab p {
  color: #afafaf;
  font-weight: 500;
  margin-bottom: 0;
}

@media (max-width: 371px) {
  .menu-info-tab p {
    font-size: 12px;
  }
}

@media (max-width: 446px) {
  .menu-info-tab p {
    font-size: 13px;
  }
}

.menu-info-tab .menu-info-tab-small {
  color: #00587b;
  font-size: 13px;
}

@media (max-width: 575px) {
  .menu-info-tab {
    text-align: left;
  }
}

.header {
  padding-top: 7px;
  padding-bottom: 8px;
  -webkit-transition: all .9s;
  -moz-transition: all .9s;
  -ms-transition: all .9s;
  -o-transition: all .9s;
}

@media (min-width: 576px) {
  body.menu-small {
    padding-top: 163px;
  }
  body.menu-small .navbar-brand > * {
    font-size: 0;
  }
  body.menu-small .navbar-brand img {
    height: 20px;
  }
  body.menu-small .menu-small-hide {
    display: none !important;
  }
  body.menu-small .menu-small-show {
    display: block;
  }
  body.menu-small .header {
    padding-bottom: 0;
    margin-top: 0 !important;
    padding-top: 5px !important;
    box-shadow: 0 14px 9px -11px rgba(0, 0, 0, 0.08);
  }
  body.menu-small .menu-info-tabs {
    padding-bottom: 6px;
  }
  body.menu-small .menu-info-tab {
    margin-top: 4px;
  }
  body.menu-small .menu-info-tab .menu-info-tab-icon {
    font-size: 21px;
    margin-top: 6px;
  }
  body.menu-small .menu-info-tab p {
    margin-bottom: -6px;
  }
  body.menu-small .menu-info-tab p:not(.menu-info-tab-small) {
    font-size: 15px;
    color: #00587b;
  }
  body.menu-small .menu-info-tab .menu-info-tab-title {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  body.menu-small .menu-info-tab p:not(.menu-info-tab-small) {
    font-size: 13px;
  }
}

.navbar.navbar-menu {
  padding-top: 0;
  padding-bottom: 0;
  -webkit-transition: all .9s;
  -moz-transition: all .9s;
  -ms-transition: all .9s;
  -o-transition: all .9s;
}

.navbar.navbar-menu a:not(.btn):hover {
  text-decoration: underline !important;
}

.navbar.navbar-menu .nav-menu {
  margin-top: 0;
}

.navbar.navbar-menu .nav-menu > li:first-child > a {
  padding-left: 0;
}

.navbar.navbar-menu .nav-menu > li > a {
  font-size: 17px;
  font-weight: 700;
  padding: 4px 28px 4px 0;
}

.navbar.navbar-menu .nav-menu > li > a:not(.btn) {
  color: #00587b;
}

@media (max-width: 991px) {
  .navbar.navbar-menu .nav-menu > li > a {
    font-size: 15px;
    padding-right: 14px;
  }
}

.navbar.navbar-menu .nav-menu > li .btn-menu-action {
  right: 0;
  padding: 0 14px;
  border-radius: 0;
  line-height: 49px;
  position: absolute;
}

.navbar.navbar-menu .nav-menu > li .dropdown-menu {
  min-width: 245px;
  margin-top: -1px;
  box-shadow: none;
  border-radius: 0;
  padding: 3px 16px 8px;
}

.navbar.navbar-menu .nav-menu > li .dropdown-menu > li:before {
  color: #00587b;
}

.navbar.navbar-menu .nav-menu > li .dropdown-menu > li > a {
  color: #00587b;
  margin-left: 4px;
  font-weight: 500;
}

.navbar.navbar-menu .nav-menu > li.active > a {
  text-decoration: underline !important;
}

.navbar.navbar-menu .nav-menu-top li {
  line-height: 1.5;
}

.navbar.navbar-menu .nav-menu-top li a {
  color: #a7a7a7;
  line-height: 1;
  padding: 0 18px 0 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 0;
  background-color: transparent;
}

.navbar.navbar-menu .nav-menu-top li.active a {
  font-weight: bold;
  color: #00587b !important;
  text-decoration: none !important;
}

.navbar.navbar-menu.navbar-top {
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #f8f8f8;
}

.navbar.navbar-menu.navbar-top a:not(:hover) {
  color: #a7a7a7 !important;
}

@media (max-width: 767px) {
  .navbar.navbar-menu.navbar-top {
    display: none;
  }
}

.nav-searchbar .searchbutton {
  border: 0;
  width: 25px;
  height: 25px;
  padding: 0 2px 2px;
  background: linear-gradient(90deg, #00559f 0%, #00a5e5 100%);
  border-radius: 50%;
  margin-left: -17px;
  color: white;
}

.nav-searchbar .searchbutton > i {
  font-size: 13px;
}

.nav-searchbar .searchbox-input {
  top: 0;
  right: 0;
  border: 1px solid #d2d2d2;
  outline: 0;
  background: white;
  width: 240px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  height: 25px;
  margin: 0;
  padding-left: 10px;
  font-size: 13px;
  color: gray;
}

@media (max-width: 767px) {
  .nav-searchbar .searchbox-input {
    width: calc(100% - 13px);
  }
}

.navbar-brand img {
  max-height: 52px;
  margin-top: 12px;
}

@media (max-width: 767px) {
  .navbar-brand img {
    margin-top: 5px;
  }
}

.navbar-menu:not(.bg-menu) {
  width: 100%;
  z-index: 100;
  margin-top: 0;
  box-shadow: none;
}

.navbar-menu:not(.bg-menu) > * {
  z-index: 1;
}

.navbar-menu:not(.bg-menu) .navbar-brand img {
  -webkit-transition: all .9s;
  -moz-transition: all .9s;
  -ms-transition: all .9s;
  -o-transition: all .9s;
}

.navbar-menu:not(.bg-menu) .navbar-toggler {
  right: 0;
  top: -44px;
  z-index: 1;
  position: absolute;
  vertical-align: text-bottom;
}

.navbar-menu:not(.bg-menu) .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(0, 98, 167, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.cover {
  position: relative;
}

.cover .cover-body {
  min-height: 139px;
  text-shadow: 1px 0 8px rgba(0, 0, 0, 0.3);
  background-image: url("/assets/images/cover.jpg");
}

.cover .cover-body .cover-content {
  top: 50%;
  width: 100%;
  color: #ffffff;
  position: absolute;
  margin-left: -15px;
  margin-right: -15px;
  padding-right: 15px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cover .cover-body .cover-content .cover-title {
  font-size: 40px;
}

.cover .cover-body .cover-content .cover-subtitle {
  font-size: 31px;
  line-height: 48px;
}

@media (max-width: 991px) {
  .cover .cover-body .cover-content .cover-title {
    font-size: 29px;
    margin-bottom: 0;
    line-height: 33px;
  }
  .cover .cover-body .cover-content .cover-subtitle {
    font-size: 24px;
    line-height: 35px;
  }
}

.cover .cover-footer {
  color: #444444;
  background: #F5F5F5;
  position: relative;
}

.cover .cover-footer .cover-footer-title {
  font-size: 23px;
  font-weight: 300;
  margin-bottom: 0;
  padding: 26px 20px 21px;
}

.cover .cover-footer.cover-footer-title {
  padding-left: 0;
}

.cover .cover-footer.cover-footer-title:after {
  right: 274px;
  transform: translate(50%, 0);
}

@media (max-width: 1199px) {
  .cover:after {
    top: auto;
    bottom: 0;
    width: 82px;
    height: 246px;
  }
}

@media (max-width: 1199px) {
  .cover .cover-footer {
    z-index: 100;
  }
  .cover .cover-footer:after {
    right: 91px;
  }
}

.title-starter {
  font-size: 18px;
  font-weight: bold;
  color: #59B948;
}

.title-focus {
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  margin-top: 10px;
  margin-bottom: 10px;
}

body:not(.manager) .text-focus {
  font-size: 16px;
  line-height: 27px;
}

body:not(.manager) .card h3 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: bold;
  line-height: 20px;
  padding-bottom: 3px;
}

body:not(.manager) .content {
  color: #00587b;
}

body:not(.manager) .content h3 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: bold;
  line-height: 20px;
  padding-bottom: 3px;
}

body:not(.manager) .content img {
  display: block;
  max-width: 100%;
  height: auto;
}

body:not(.manager) .content a {
  color: #039be5;
}

body:not(.manager) .content .card-body .fluid-width-video-wrapper {
  margin-left: -20px;
  width: calc(100% + 40px);
  margin-bottom: 30px;
}

body:not(.manager) .content .card-body > p > .fluid-width-video-wrapper {
  overflow: hidden;
  margin-top: -20px;
  border-radius: 3px 3px 0 0;
}

.footer-cover-slogan {
  background: linear-gradient(to right, #8ec63f 44%, #5cbb47 7%);
}

.footer-cover-slogan p {
  margin-top: -45px;
  margin-bottom: 15px;
}

.footer-cover-slogan .btn {
  white-space: normal;
}

@media (max-width: 767px) {
  .footer-cover-slogan p {
    margin-top: -30px;
    margin-bottom: 5px;
  }
}

@media (max-width: 400px) {
  .footer-cover-slogan p {
    margin-top: -4px;
    margin-bottom: 5px;
  }
}

.footer-slogan {
  margin-bottom: 15px;
}

.footer-slogan img {
  max-width: 222px;
  padding-top: 20px;
}

.footer-slogan #footer-slogan {
  padding-top: 43px;
}

@media (max-width: 576px) {
  .footer-slogan [class*="col-"] {
    text-align: center !important;
  }
  .footer-slogan [class*="col-"] img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .footer-slogan [class*="col-"] #footer-slogan {
    padding-top: 18px;
  }
}

.footer {
  color: #ffffff;
  background-color: #046c9f;
}

.footer .footer-main {
  padding: 44px 15px 20px;
  background: url(/assets/images/footer.jpg) no-repeat;
  background-color: #046c9f;
  background-size: 100%;
}

@media (max-width: 767px) {
  .footer .footer-main {
    background-size: cover;
    background-position: right;
  }
}

.footer a {
  color: #FFFFFF;
}

.footer .list-title {
  font-weight: bold;
  margin-bottom: 16px;
}

.footer .footer-first-text-col {
  padding-right: 1.8rem !important;
}

.footer p {
  font-size: 14px;
  line-height: 24px;
}

.footer ul {
  margin-bottom: 10px;
}

.footer ul li {
  font-size: 14px;
  margin-top: 6px;
  line-height: 24px;
}

.footer ul li:before {
  margin-left: -40px;
}

.footer .footer-bar {
  color: #8c8c8d;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 500;
  background-color: #ebebeb;
}

.footer .footer-bar a {
  color: #8c8c8d;
  font-size: 14px;
  font-weight: 500;
}

.footer .marks .mark-image {
  margin-bottom: 15px;
}

body.homepage .footer {
  margin-top: 0;
}

.searchpage .panel-heading {
  border-radius: 0;
  background-color: #0062A7;
  border-bottom: 2px solid white;
}

.searchpage .panel-heading .panel-title {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.searchpage .panel-heading .panel-title a {
  color: #fff;
  display: block;
  font-weight: 700;
  padding: 10px 15px;
  text-decoration: none !important;
}

.searchpage .panel-heading .panel-title a.collapsed i:before {
  content: "\f054";
}

.searchpage [data-toggle="collapse"] {
  font-size: 16px;
}

.searchpage button[type="submit"] {
  font-size: 17px;
  font-weight: 700;
}

.searchpage label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2px;
}

.searchpage .card-body {
  padding-left: 13px;
  padding-right: 13px;
}

.searchpage .card-body .form-group .checkbox {
  margin-left: 13px;
}

.searchpage .checkbox input[type=checkbox] {
  display: none;
}

.searchpage .checkbox label {
  display: block;
}

.searchpage > .collapse:not(.show) {
  display: block;
  height: auto !important;
}

@media (max-width: 991px) {
  .searchpage > .collapse:not(.show) {
    display: none;
  }
}

.home-category .title {
  font-size: 25px;
}

.home-category .category-title {
  font-size: 15px;
}

.home-category .btn {
  font-size: 15px;
}

.product-category .bg-image, .product-category .page-container {
  height: 147px;
  overflow: hidden;
  border: 2px solid #e9e9e9;
}

.product-category .bg-image img, .product-category .page-container img {
  opacity: 0;
}

.product-category:hover .bg-image, .product-category:hover .page-container {
  outline: 2px solid #e9e9e9;
  border-width: 2px;
}

.product-category:hover .title {
  text-decoration: underline !important;
}

.product-category .title {
  padding: 0;
  color: #00587b;
  font-size: 14px;
  margin-top: 6px;
  font-weight: 500;
  margin-bottom: 0;
  letter-spacing: 0.01em;
}

.product-category .more-info {
  color: #999999;
  font-size: 14px;
  padding-top: 5px;
  font-weight: bold;
  padding-left: 15px;
  letter-spacing: 0.02em;
}

@media (max-width: 991px) {
  .product-category .bg-image, .product-category .page-container {
    height: 86px;
  }
}

.product-category.home-category .bg-image, .product-category.home-category .page-container {
  height: 248px;
}

.product-category.home-category .title {
  color: #00587b;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
  padding-top: 10px;
  padding-left: 15px;
  letter-spacing: 0.01em;
}

.btn-main {
  color: white !important;
}

.btn-main:hover, .btn-main:active, .btn-main:focus {
  border-color: #59B946;
  background-color: #59B946 !important;
}

.btn-main-2 {
  border-color: #E1AD2B !important;
  background-color: #E1AD2B !important;
}

.btn-sm, .btn-group-sm > .btn {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

a.btn:focus {
  color: inherit !important;
}

body.manager .btn {
  font-weight: 700;
  letter-spacing: 0.5px;
}

body.manager a:not(.btn) {
  color: #00587b;
}

body.manager a:hover, body.manager a:not(.btn):hover {
  text-decoration: none !important;
}

body.manager .page-title {
  line-height: 1.6;
}

body.manager .profile-dropdown {
  width: 188px;
}

body.manager .navbar-custom {
  background-color: #046C9F;
}

body.manager #sidebar-menu > ul > li > a.active {
  background-color: #59B946;
}

body.manager #sidebar-menu > ul > li li.active a {
  color: #00587b;
  font-weight: bold;
}

body.manager .footer {
  color: #ffffff;
  background-color: #00587C;
}

.container-element ul li {
  border: none;
}

.container-element .column {
  min-height: 100px;
  border: 2px dashed #d1d7db;
}

.container-element .row-preset {
  width: 40px;
  height: 40px;
  padding: 4px;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 3px;
  display: inline-block;
  vertical-align: bottom;
  background-color: #d4d4d4a6;
}

.container-element .row-preset > div {
  height: 100%;
  border-radius: 1px;
  display: inline-block;
  background-color: #3b7694;
  border-left: 1px solid #d4d4d4a6;
  border-right: 1px solid #d4d4d4a6;
}

.container-element .row-preset > div:first-of-type {
  border-left: none;
}

.container-element .row-preset .row-preset-12 {
  width: 100%;
}

.container-element .row-preset .row-preset-9 {
  width: 75%;
}

.container-element .row-preset .row-preset-6 {
  width: 50%;
}

.container-element .row-preset .row-preset-4 {
  width: 33.3333333%;
}

.container-element .row-preset .row-preset-3 {
  width: 25%;
}

.container-element .row-preset .row-preset-2 {
  width: 16.6666667%;
}

.page-builder-drag-item {
  padding: 5px;
  cursor: grab;
  display: block;
  margin-bottom: 3px;
}

.page-builder-drag-item:hover {
  background-color: #f3f3f3;
}

.row-items-list {
  z-index: 2;
  padding-bottom: 61px;
}

.row-items-list .card-box {
  padding: 0.75rem;
}

.row-items-list .row-item:not(:last-of-type) {
  margin-bottom: 20px;
}

.row-items-list + .no-items-placeholder {
  margin-top: -46px;
}

.no-items-placeholder {
  z-index: 1;
  width: 100%;
  padding: 10px;
  display: block;
  border-radius: 3px;
  position: absolute;
  border: 2px dashed #d1d7db;
}

.widget-item .error {
  color: red;
}

.widget-item .card .card-heading {
  cursor: pointer;
}

.widget-item .card .card-heading .item-title {
  text-overflow: ellipsis;
  vertical-align: middle;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
}

.widget-item .card .btn-remove-item {
  top: 4px;
  right: 13px;
  position: absolute;
}

.widget-item .card .card-collapse.collapse.in {
  border-bottom: 1px solid grey;
  border-radius: 2px;
}

body ul[dnd-list] {
  list-style: none;
  position: relative;
  min-height: 66px;
  padding-left: 0;
}

body ul[dnd-list].widget-dropzone-list > li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 0;
  display: block;
}

body ul[dnd-list].widget-dropzone-list .dndDraggingSource {
  display: none;
}

body ul[dnd-list].widget-dropzone-list .dndPlaceholder {
  background-color: #dddddd;
  position: relative;
  min-height: 42px;
  display: block;
}

body ul[dnd-list].widget-dropzone-list .dndDragging {
  opacity: 0.7;
}

.widget-drag-item {
  border: 1px solid #dddddd;
  margin-bottom: 2px;
  padding: 5px;
  cursor: move;
}

.widget-drag-item > span > .text-hover {
  display: none;
}

.widget-drag-item:hover {
  background-color: #f2f4f8;
}

.widget-drag-item:hover > span > .text-hover {
  display: inline-block;
}

.widget-drag-item:hover > span > .text {
  opacity: 0;
}

ul[dnd-list] {
  padding-left: 0;
  position: relative;
}

ul[dnd-list].widget-dropzone-list > li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 0;
  display: block;
}

ul[dnd-list].widget-dropzone-list .dndDraggingSource {
  display: none;
}

ul[dnd-list].widget-dropzone-list .dndPlaceholder {
  background-color: #dddddd;
  position: relative;
  min-height: 42px;
  display: block;
}

ul[dnd-list].widget-dropzone-list .dndDragging {
  opacity: 0.7;
}

ul[dnd-list].widget-dropzone-list .dndDragging .drag-hide {
  opacity: 0;
}

ul[dnd-list].assignment-deliverables-dropzone-list:not(.dnd-list-empty), ul[dnd-list].assignment-submit-deliverables-dropzone-list:not(.dnd-list-empty) {
  min-height: 66px;
}

ul[dnd-list].assignment-submit-deliverables-dropzone-list {
  z-index: 10;
}

ul[dnd-list].assignment-submit-deliverables-dropzone-list.dndDragover {
  z-index: 12;
}

ul[dnd-list].assignment-submit-deliverables-dropzone-list .dndPlaceholder {
  min-height: 60px;
}

ul[dnd-list].assignment-submit-deliverables-dropzone-list > li {
  margin-bottom: 0;
  background: #fff;
}

.widget-drag-item {
  border: 1px solid #dddddd;
  margin-bottom: 2px;
  padding: 5px;
  cursor: move;
}

.widget-drag-item > span > .text-hover {
  display: none;
}

.widget-drag-item:hover {
  background-color: #f2f4f8;
}

.widget-drag-item:hover > span > .text-hover {
  display: inline-block;
}

.widget-drag-item:hover > span > .text {
  opacity: 0;
}

.card.card-focus {
  border: 0;
  height: 100%;
  border-radius: 13px;
  box-shadow: 0 4px 20px 1px rgba(0, 88, 123, 0.16);
}

.card.card-focus i {
  color: #00587b;
  margin-top: 20px;
}

.card.card-focus .card-title {
  color: #00587b;
  font-size: 20px;
  margin-top: 12px;
  font-weight: bold;
}

.card.card-focus.card-style-dark.bg-white {
  color: #333;
}

.card .card-absolute-footer {
  right: 0;
  bottom: 0;
  position: absolute;
}

.card .card-footer-absolute {
  margin-top: 32px;
}

.card .card-footer-absolute .btn {
  right: 25px;
  bottom: 25px;
  position: absolute;
}

.card .card-footer {
  border-top: 0;
  border-radius: 0 0 13px 13px;
  background-color: #eaeaea;
}

.card .card-body .card-footer {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.03);
}

.card.card-newsitem {
  margin-bottom: 25px;
}

.card.card-newsitem .bg-image, .card.card-newsitem .page-container {
  height: 200px;
  border-radius: 17px;
}

.card.card-newsitem .card-title {
  min-height: 96px;
  font-weight: bold;
}

.card.card-productitem {
  margin-bottom: 25px;
}

.card.card-productitem .bg-image, .card.card-productitem .page-container {
  height: 200px;
  border-radius: 17px;
  background: center/contain no-repeat;
}

.card.card-productitem .card-content {
  height: 87px;
}

.card.card-productitem .card-title {
  font-weight: bold;
}

.card.card-style-image {
  width: 100%;
  margin-bottom: 30px;
}

.card .filters {
  color: #00587b;
}

.card .filters dt {
  font-size: 15px;
  line-height: 22px;
  padding-left: 9px;
  width: calc(100% - 100px);
}

.card .filters dd {
  width: 100px;
}

.card .filters .stars > .fa-star {
  font-size: 9px;
}

@media (min-width: 576px) and (max-width: 767px) {
  .card .filters dt {
    font-size: 13px;
    line-height: 26px;
  }
}

.card[class*="card-style-"] {
  border: none;
  border-radius: 13px;
}

.card[class*="card-style-"] .card-title {
  font-size: 20px;
  margin-top: 12px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 22px;
}

.card[class*="card-style-"] .card-text {
  font-size: 14px;
  margin-top: 0;
  line-height: 26px;
  font-weight: 500;
}

.card[class*="card-style-"] .card-text + .card-title {
  margin-top: 14px;
}

.card[class*="card-style-"] .card-text p {
  margin-bottom: 0;
}

.card[class*="card-style-"] .card-text img {
  max-width: 100%;
}

.card[class*="card-style-"] .card-text h1 + p,
.card[class*="card-style-"] .card-text h2 + p,
.card[class*="card-style-"] .card-text h3 + p,
.card[class*="card-style-"] .card-text h4 + p,
.card[class*="card-style-"] .card-text h5 + p,
.card[class*="card-style-"] .card-text h6 + p,
.card[class*="card-style-"] .card-text p + p {
  margin-top: 1rem;
}

.card[class*="card-style-"].card-style-image {
  border-radius: 13px;
  background-color: transparent;
}

.card[class*="card-style-"].card-style-image img {
  border-radius: 13px;
}

.card[class*="card-style-"] .card-body {
  padding: 24px;
}

.card[class*="card-style-"] .btn {
  font-size: 13px;
  line-height: 26px;
  border-radius: 10px;
}

.card[class*="card-style-"] .btn i {
  margin-left: 13px;
}

.card[class*="card-style-"].card-style-default .card-title {
  font-size: 21px;
  margin-top: 3px;
  font-weight: 500;
  line-height: 1.4;
}

.card[class*="card-style-"].card-style-dark {
  color: #ffffff;
  background: #434343;
}

.card[class*="card-style-"].card-style-footer {
  color: #3B3B3B;
  margin-top: -93px;
  background: transparent;
}

.card[class*="card-style-"].card-style-footer .card-title {
  margin-top: 0;
}

.card[class*="card-style-"].card-style-footer .card-body {
  position: relative;
  border-radius: 13px;
  background-color: #F4F4F4;
}

.card[class*="card-style-"].card-style-footer .card-body > * {
  z-index: 2;
  position: relative;
}

.card[class*="card-style-"].card-style-footer .card-body:after {
  top: -6px;
  left: -6px;
  z-index: 1;
  content: "";
  position: absolute;
  border-radius: 19px;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border: 6px solid rgba(244, 244, 244, 0.33);
}

.card + .card {
  margin-top: 20px;
}

/* Special */
.card-footer-bg {
  height: 335px;
}

[data-slide-to] {
  cursor: pointer;
}

.carousel-cover .carousel-item {
  height: 840px;
}

.carousel-cover .carousel-caption {
  bottom: 53px;
  color: #333;
  border-radius: 13px;
  background: rgba(255, 255, 255, 0.701961) !important;
  padding: 20px 30px;
}

.carousel-cover .carousel-caption p:last-of-type {
  margin-bottom: 0;
}

.carousel-cover .carousel-caption .btn {
  font-weight: 600;
}

@media (max-width: 1665px) {
  .carousel-cover .carousel-item {
    height: 650px;
  }
}

@media (max-height: 850px) and (min-width: 1155px) {
  .carousel-cover .carousel-item {
    height: 450px;
  }
}

@media (max-width: 1155px) {
  .carousel-cover .carousel-item {
    height: 450px;
  }
  .carousel-cover .carouse-title {
    font-size: 31px;
  }
}

@media (max-width: 1035px) and (min-width: 376px) {
  .carousel-cover .carouse-title {
    font-size: 26px;
  }
  .carousel-cover .btn {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
  }
}

@media (max-width: 375px) {
  .carousel-cover .carouse-title {
    font-size: 20px;
  }
  .carousel-cover .carousel-caption {
    padding: 9px 12px;
  }
  .carousel-cover .btn-lg, .carousel-cover .btn-group-lg > .btn {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
  }
}

.hidden {
  display: none !important;
}

.checkbox label::after {
  top: 0;
  padding-top: 0;
}

.radio label {
  padding-left: 9px;
  margin-bottom: 3px;
}

.radio label > input[type="radio"] {
  display: none;
}

.radio:not([class*="radio-"]) label {
  margin-left: 14px;
}

.has-error .help-block {
  color: #f05050;
}

.bg-grey {
  background: #eeeeee !important;
}

.alert.alert-info {
  color: #138cad;
}

.full-width {
  width: 100%;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

[data-select] {
  cursor: pointer;
}

.nav-clean > li.nav-item {
  padding: .5rem 1rem;
}

.nav-clean > li.nav-item > a.nav-link {
  color: #00587b;
  border-radius: 0;
  padding: 0 0 3px;
  background-color: transparent;
}

.nav-clean > li.nav-item > a.nav-link.active {
  text-decoration: underline !important;
}

.image-block {
  color: #ffffff;
  display: block;
  font-size: 28px;
  min-width: 250px;
  min-height: 140px;
  font-weight: 700;
  text-align: center;
  position: relative;
}

.image-block:after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  content: '';
  overflow: hidden;
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  transition: background .25s ease-in-out;
  -moz-transition: background .25s ease-in-out;
  -webkit-transition: background .25s ease-in-out;
}

.image-block:hover {
  color: #ffffff;
}

.image-block:hover:after {
  background: rgba(0, 0, 0, 0.6);
}

.image-block > * {
  top: 50%;
  width: 100%;
  z-index: 10;
  position: absolute;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.specialistback {
  display: flex;
  align-items: center;
  border-radius: 500px;
  background: white;
  padding: 10px 10px 7px 10px;
}

.specialistback img {
  width: 100%;
  margin-top: 0;
  margin-left: 9px;
  border-radius: 50%;
  background-size: cover;
}

.specialistback h1, .specialistback h2, .specialistback h3, .specialistback h4, .specialistback h5 {
  font-size: 21px;
  line-height: 1.4;
}

.specialistback p {
  color: #00587b;
}

.specialistback .specialist-content-col {
  padding-left: 22px;
}

.specialistback .specialist-name {
  font-size: 18px;
  padding-top: 18px;
}

.specialistback .specialist-p-btn {
  font-size: 14px;
  padding-top: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}

.specialist .specspan {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  padding: 30% 13%;
  word-wrap: break-word;
  color: #ffffff;
  font-family: Raleway-bold !important;
  font-size: 18px !important;
  letter-spacing: 0.01em;
  line-height: 1.5;
}

.specialist .specialist-image {
  transform: scale(0.8);
}

.specialist .specialist-image img {
  width: 100%;
  border-radius: 50%;
  background-size: cover;
}

.specialist:hover .specspan {
  display: block;
  border-radius: 50%;
  background: rgba(171, 213, 42, 0.8) !important;
}

.specialist h5 {
  margin-top: 0;
  font-weight: 400;
}

.specialist .calling {
  font-weight: 700;
}

@media (max-width: 1280px) {
  .specialist .name {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .specialist .name {
    font-size: 21px;
  }
  .specialist .calling {
    font-size: 16px;
  }
}

@media (max-width: 620px) {
  .specialist .specspan {
    padding: 23% 11%;
    font-size: 15px !important;
  }
}

.fix-center-problem-map {
  padding-right: 48px;
}

.testimonial {
  background: url(/assets/images/testimonials-bg.jpg);
  background-size: cover;
  margin: 0;
  /* height: 174px; */
  max-height: 100%;
  padding-top: 27px;
  padding-bottom: 10px;
}

.testimonial [data-slide] {
  width: auto;
}

.testimonial .testimonial-qoute {
  color: #ffffff;
  font-size: 23px;
  font-weight: 600;
  line-height: 125%;
  margin: 3px 0 0.656rem;
}

.testimonial .testimonial-name {
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .testimonial .testimonial-qoute {
    font-size: 15px;
    padding: 0 25px;
  }
}

#cookie-bar {
  font-size: 13px;
  color: #ffffff;
  font-weight: 300;
}

#cookie-bar * {
  line-height: 1.5;
}

#cookie-bar p {
  float: left;
  width: 100%;
  margin: 0;
  padding: 4px 0 0 20px;
  color: #ffffff;
  font-family: sans-serif;
}

#cookie-bar a {
  color: #31a8f0;
  text-decoration: none;
}

#cookie-bar a:hover {
  color: #31a8f0;
  text-decoration: underline;
}

#cookie-bar #cookie-bar-button {
  border-bottom: 1px solid #222222;
  border-radius: 5px;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-block;
  float: right;
  font-weight: bold;
  line-height: 1;
  margin-right: 1px;
  margin-top: -1px;
  padding: 5px 8px 4px;
  position: relative;
  text-decoration: none;
  text-shadow: 0 -1px 1px #222222;
}

@media only screen and (max-device-width: 800px) {
  #cookie-bar {
    font-size: 12px;
  }
}

#cookie-bar {
  background: #45484d;
  background: -moz-linear-gradient(top, rgba(30, 30, 30, 0.95) 0, rgba(0, 0, 0, 0.95) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(30, 30, 30, 0.95)), color-stop(100%, rgba(0, 0, 0, 0.95)));
  background: -webkit-linear-gradient(top, rgba(30, 30, 30, 0.95) 0, rgba(0, 0, 0, 0.95) 100%);
  background: -o-linear-gradient(top, rgba(30, 30, 30, 0.95) 0, rgba(0, 0, 0, 0.95) 100%);
  background: -ms-linear-gradient(top, rgba(30, 30, 30, 0.95) 0, rgba(0, 0, 0, 0.95) 100%);
  background: linear-gradient(to bottom, rgba(30, 30, 30, 0.95) 0, rgba(0, 0, 0, 0.95) 100%);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10pt;
  left: 0;
  line-height: 1.5;
  margin: 0;
  padding: 3px;
  position: fixed;
  width: 100%;
  z-index: 9999;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc45484d', endColorstr='#cc000000', GradientType=0);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.01em;
  font-family: "Raleway", sans-serif;
}

body:not(.manager) {
  padding-bottom: 0;
  background-color: #f8f8f8;
}

body:not(.manager) .card-box {
  border-radius: 0;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

body p {
  font-weight: 500;
}

body a {
  color: #00587b;
}

body a:not(.btn):hover {
  text-decoration: none !important;
}

body h2 {
  font-size: 21px;
  line-height: 1.4;
}

body h5 {
  font-size: 1.90rem;
  line-height: 125%;
  margin: 1rem 0 0.656rem 0;
}

body h6 {
  font-size: 1.19rem;
  line-height: 125%;
  margin: 0.5rem 0 0.4rem 0;
  letter-spacing: 0.01em;
}

body .h1, body .h2, body .h3, body .h4, body .h5, body .h6, body h1, body h2, body h3, body h4, body h5, body h6 {
  color: #00587b;
}

body .fs-18 {
  font-size: 18px;
}

body .fs-24 {
  font-size: 24px;
}

body .fs-28 {
  font-size: 28px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .row > * > .card {
    display: block;
  }
  #wrapper .navbar-brand {
    display: block;
  }
  .nav-searchbar .searchbox-input {
    line-height: 0.4;
  }
}
