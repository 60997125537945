.hidden {
  display: none !important;
}

.checkbox label::after {
  top: 0;
  padding-top: 0;
}

.radio {
  label {
    padding-left: 9px;
    margin-bottom: 3px;

    > input[type="radio"] {
      display: none;
    }
  }

  &:not([class*="radio-"]) label {
    margin-left: 14px;
  }
}

.has-error {
  .help-block {
    color: $danger;
  }
}

.bg-grey {
  background: #eeeeee !important;
}

.alert {
  &.alert-info {
    color: #138cad;
  }
}

.full-width {
  width: 100%;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

[data-select] {
  cursor: pointer;
}

.nav-clean {
  > li.nav-item {
    padding: .5rem 1rem;

    > a.nav-link {
      color: #00587b;
      border-radius: 0;
      padding: 0 0 3px;
      background-color: transparent;

      &.active {
        text-decoration: underline !important;
      }
    }
  }
}

.image-block {
  color: #ffffff;
  display: block;
  font-size: 28px;
  min-width: 250px;
  //min-height: 200px;
  min-height: 140px;
  font-weight: 700;
  text-align: center;
  position: relative;

  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    content: '';
    overflow: hidden;
    position: absolute;
    background: rgba(0, 0, 0, .25);

    transition: background .25s ease-in-out;
    -moz-transition: background .25s ease-in-out;
    -webkit-transition: background .25s ease-in-out;
  }

  &:hover {
    color: #ffffff;

    &:after {
      background: rgba(0, 0, 0, .6);
    }
  }

  > * {
    top: 50%;
    width: 100%;
    z-index: 10;
    //font-weight: 800;
    position: absolute;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
}

.specialistback {
  display: flex;
  align-items: center;
  border-radius: 500px;

  background: white;
  padding: 10px 10px 7px 10px;

  img {
    width: 100%;
    margin-top: 0;
    margin-left: 9px;
    border-radius: 50%;
    background-size: cover;
  }

  h1, h2, h3, h4, h5 {
    font-size: 21px;
    line-height: 1.4;
  }

  p {
    color: #00587b;
  }

  .specialist-content-col {
    padding-left: 22px;
  }

  .specialist-name {
    font-size: 18px;
    padding-top: 18px;
  }

  .specialist-p-btn {
    font-size: 14px;
    padding-top: 20px;
    font-weight: bold;
    padding-bottom: 20px;
  }
}

.specialist {
  .specspan {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    padding: 30% 13%;
    word-wrap: break-word;
    color: #ffffff;
    font-family: Raleway-bold !important;
    font-size: 18px !important;
    letter-spacing: 0.01em;
    line-height: 1.5;
  }

  .specialist-image {
    transform: scale(0.8);

    img {
      width: 100%;
      border-radius: 50%;
      background-size: cover;
    }
  }

  &:hover {
    .specspan {
      display: block;
      border-radius: 50%;
      background: rgba(171, 213, 42, 0.8) !important;
    }
  }

  h5 {
    margin-top: 0;
    font-weight: 400;
  }

  .calling {
    font-weight: 700;
  }

  @media (max-width: 1280px) {
    .name {
      font-size: 24px;
    }
  }

  @media (max-width: 991px) {
    .name {
      font-size: 21px;
    }

    .calling {
      font-size: 16px;
    }
  }

  @media (max-width: 620px) {
    .specspan {
      padding: 23% 11%;
      font-size: 15px !important;
    }
  }
}

.fix-center-problem-map {
  padding-right: 48px;
}

.testimonial {
  background: url(/assets/images/testimonials-bg.jpg);
  background-size: cover;
  margin: 0;
  /* height: 174px; */
  max-height: 100%;

  padding-top: 27px;
  padding-bottom: 10px;

  [data-slide] {
    width: auto;
  }

  .testimonial-qoute {
    color: #ffffff;
    font-size: 23px;
    font-weight: 600;
    line-height: 125%;
    margin: 3px 0 0.656rem;
  }

  .testimonial-name {
    font-size: 14px;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    .testimonial-qoute {
      font-size: 15px;
      padding: 0 25px;
    }
  }
}
