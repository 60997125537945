
.progress-lg {
  height: 1.5rem;
}

.progress-sm {
  height: 0.7rem;
}

.progress-xs {
  height: 0.4rem;
}
