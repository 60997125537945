.btn-main {
  @extend .btn-primary;

  color: white !important;

  &:hover, &:active, &:focus {
    border-color: $main2;
    background-color: $main2 !important;
  }
}

.btn-main-2 {
  @extend .btn-dark;

  border-color: #E1AD2B !important;
  background-color: #E1AD2B !important;
}

.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}


a.btn {
  &:focus {
    color: inherit !important;
  }
}
