#cookie-bar {
  font-size: 13px;
  color: #ffffff;
  font-weight: 300;

  * {
    line-height: 1.5;
  }

  p {
    float: left;
    width: 100%;
    //margin: 4px 0 0 20px;
    margin: 0;
    padding: 4px 0 0 20px;
    color: #ffffff;
    font-family: sans-serif
  }

  a {
    color: #31a8f0;
    text-decoration: none;

    &:hover {
      color: #31a8f0;
      text-decoration: underline
    }
  }

  #cookie-bar-button {
    //background-color: #5a5a5a;
    border-bottom: 1px solid #222222;
    border-radius: 5px;
    color: #ffffff !important;
    cursor: pointer;
    display: inline-block;
    float: right;
    font-weight: bold;
    line-height: 1;
    margin-right: 1px;
    margin-top: -1px;
    padding: 5px 8px 4px;
    position: relative;
    text-decoration: none;
    text-shadow: 0 -1px 1px #222222
  }
}

@media only screen and (max-device-width: 800px) {
  #cookie-bar {
    font-size: 12px
  }
}

#cookie-bar {
  background: #45484d;
  background: -moz-linear-gradient(top, rgba(30, 30, 30, 0.95) 0, rgba(0, 0, 0, 0.95) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(30, 30, 30, 0.95)), color-stop(100%, rgba(0, 0, 0, 0.95)));
  background: -webkit-linear-gradient(top, rgba(30, 30, 30, 0.95) 0, rgba(0, 0, 0, 0.95) 100%);
  background: -o-linear-gradient(top, rgba(30, 30, 30, 0.95) 0, rgba(0, 0, 0, 0.95) 100%);
  background: -ms-linear-gradient(top, rgba(30, 30, 30, 0.95) 0, rgba(0, 0, 0, 0.95) 100%);
  background: linear-gradient(to bottom, rgba(30, 30, 30, 0.95) 0, rgba(0, 0, 0, 0.95) 100%);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10pt;
  left: 0;
  line-height: 1.5;
  margin: 0;
  padding: 3px;
  position: fixed;
  width: 100%;
  z-index: 9999;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc45484d', endColorstr='#cc000000', GradientType=0)
}
