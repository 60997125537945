.page-container {
    margin-top: 80px;
    margin-bottom: 30px;

    @extend .bg-image;
}

.menu-info-tab {
    margin-top: 4px;

    &:not(.btn):hover {
        text-decoration: none !important;
    }

    .menu-info-tab-icon {
        color: #00587b;
        font-size: 21px;
        margin-right: 10px;
        vertical-align: top;
    }

    .menu-info-tab-title {
        color: #00587b;
        margin: 0 0 2px;
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: 2px;//0.1em;

        @media (max-width: 371px) {
            font-size: 13px;
            letter-spacing: 0;
        }

        @media (max-width: 446px) {
            font-size: 13px;
            letter-spacing: 0;
        }
    }

    .menu-small-show {
        display: none;
    }

    p {
        color: #afafaf;
        font-weight: 500;
        margin-bottom: 0;

        @media (max-width: 371px) {
            font-size: 12px;
        }

        @media (max-width: 446px) {
            font-size: 13px;
        }
    }

    .menu-info-tab-small {
        color: #00587b;
        font-size: 13px;
    }

    @media (max-width: 575px) {
        text-align: left;
    }
}

.header {
    padding-top: 7px;
    padding-bottom: 8px;

    -webkit-transition: all .9s;
    -moz-transition: all .9s;
    -ms-transition: all .9s;
    -o-transition: all .9s;



    //
    //transition-property: all;
    //transition-duration: .5s;





    //overflow-y: hidden;


    //max-height: 500px;
    //transition-timing-function: ease-in-out;
}


//@media (max-width: 575px) {
//    body.menu-small {
//        .menu-info-tabs {
//            display: none;
//        }
//
//        .navbar.navbar-menu {
//            margin-top: 3px !important;
//        }
//    }
//}

@media (min-width: 576px) {
    body.menu-small {
        padding-top: 163px;//51px;

        .navbar-brand {
            > * {
                font-size: 0;
            }

            img {
                height: 20px;
            }
        }

        .menu-small-hide {
            display: none !important;
        }

        .menu-small-show {
            display: block;
        }

        .header {
            padding-bottom: 0;
            margin-top: 0 !important;
            padding-top: 5px !important;

            //margin-top: -97px;

            //max-height: 0;

            box-shadow: 0 14px 9px -11px rgba(0, 0, 0, .08);
        }

        .menu-info-tabs {
            padding-bottom: 6px;
        }

        .menu-info-tab {
            margin-top: 4px;

            .menu-info-tab-icon {
                font-size: 21px;
                margin-top: 6px;
            }

            p {
                margin-bottom: -6px;

                &:not(.menu-info-tab-small) {
                    font-size: 15px;
                    color: #00587b;
                }
            }

            .menu-info-tab-title {
                display: none;
            }
        }

        //.navbar.navbar-menu {
        //    //margin-top: 45px !important;
        //    box-shadow: 0 14px 9px -11px rgba(0, 0, 0, .08);
        //}

        @media (max-width: 991px) {
            .menu-info-tab {
                p {
                    &:not(.menu-info-tab-small) {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.navbar.navbar-menu {
    padding-top: 0;
    padding-bottom: 0;

    a:not(.btn):hover {
        text-decoration: underline !important;
    }

    -webkit-transition: all .9s;
    -moz-transition: all .9s;
    -ms-transition: all .9s;
    -o-transition: all .9s;

    .nav-menu {
        margin-top: 0;

        > li {
            &:first-child {
                > a {
                    padding-left: 0;
                }
            }

            > a {
                font-size: 17px;
                font-weight: 700;
                padding: 4px 28px 4px 0;

                &:not(.btn) {
                    color: #00587b;
                }

                @media (max-width: 991px) {
                    font-size: 15px;
                    padding-right: 14px;
                }
            }

            .btn-menu-action {
                right: 0;
                padding: 0 14px;
                border-radius: 0;
                line-height: 49px;
                position: absolute;
            }

            .dropdown-menu {
                min-width: 245px;
                margin-top: -1px;
                box-shadow: none;
                border-radius: 0;
                padding: 3px 16px 8px;

                > li {
                    &:before {
                        color: #00587b;
                    }

                    > a {
                        color: #00587b;
                        margin-left: 4px;
                        font-weight: 500;
                    }
                }
            }

            &.active {
                > a {
                    text-decoration: underline !important;
                }
            }
        }
    }

    .nav-menu-top {
        li {
            line-height: 1.5;

            a {
                color: #a7a7a7;
                line-height: 1;
                padding: 0 18px 0 0;
                font-size: 15px;
                font-weight: 500;
                border-radius: 0;
                background-color: transparent;
            }

            &.active {
                a {
                    font-weight: bold;
                    color: #00587b !important;
                    text-decoration: none !important;
                }
            }
        }
    }
}

.navbar.navbar-menu.navbar-top {
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #f8f8f8;

    a:not(:hover) {
        color: #a7a7a7 !important;
    }

    @media (max-width: 767px) {
        display: none;
    }
}

.nav-searchbar {
    .searchbutton {
        border: 0;
        width: 25px;
        height: 25px;
        padding: 0 2px 2px;
        background: linear-gradient(90deg, rgb(0, 85, 159) 0%, rgb(0, 165, 229) 100%);
        //background: -webkit-linear-gradient( 90deg, rgb(0,85,159) 0%, rgb(0,165,229) 100%);
        //background: -ms-linear-gradient( 90deg, rgb(0,85,159) 0%, rgb(0,165,229) 100%);
        border-radius: 50%;
        margin-left: -17px;
        color: white;

        > i {
            font-size: 13px;
        }
    }

    .searchbox-input {
        top: 0;
        right: 0;
        border: 1px solid #d2d2d2;
        outline: 0;
        background: white;
        width: 240px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        height: 25px;
        margin: 0;
        padding-left: 10px;
        font-size: 13px;
        color: gray;

        @media (max-width: 767px) {
            width: calc(100% - 13px);
        }
    }
}

.navbar-brand {
    img {
        max-height: 52px;
        margin-top: 12px;

        @media (max-width: 767px) {
            margin-top: 5px;
        }
    }
}

.navbar-menu:not(.bg-menu) {
    width: 100%;
    z-index: 100;
    margin-top: 0;
    box-shadow: none;
    //position: absolute;
    //background: transparent;

    //&:before {
    //    top: 0;
    //    left: 0;
    //    content: "";
    //    width: 100%;
    //    height: 110px;
    //    position: absolute;
    //    background: rgba(0,0,0,0.27);
    //    background: -moz-linear-gradient(top, rgba(0,0,0,0.27) 1%, rgba(0,0,0,0.14) 50%, rgba(0,0,0,0.13) 51%, rgba(0,0,0,0.08) 71%, rgba(0,0,0,0) 100%);
    //    background: -webkit-gradient(left top, left bottom, color-stop(1%, rgba(0,0,0,0.27)), color-stop(50%, rgba(0,0,0,0.14)), color-stop(51%, rgba(0,0,0,0.13)), color-stop(71%, rgba(0,0,0,0.08)), color-stop(100%, rgba(0,0,0,0)));
    //    background: -webkit-linear-gradient(top, rgba(0,0,0,0.27) 1%, rgba(0,0,0,0.14) 50%, rgba(0,0,0,0.13) 51%, rgba(0,0,0,0.08) 71%, rgba(0,0,0,0) 100%);
    //    background: -o-linear-gradient(top, rgba(0,0,0,0.27) 1%, rgba(0,0,0,0.14) 50%, rgba(0,0,0,0.13) 51%, rgba(0,0,0,0.08) 71%, rgba(0,0,0,0) 100%);
    //    background: -ms-linear-gradient(top, rgba(0,0,0,0.27) 1%, rgba(0,0,0,0.14) 50%, rgba(0,0,0,0.13) 51%, rgba(0,0,0,0.08) 71%, rgba(0,0,0,0) 100%);
    //    background: linear-gradient(to bottom, rgba(0,0,0,0.27) 1%, rgba(0,0,0,0.14) 50%, rgba(0,0,0,0.13) 51%, rgba(0,0,0,0.08) 71%, rgba(0,0,0,0) 100%);
    //    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
    //}

    > * {
        z-index: 1;
    }


    .navbar-brand {
        img {
            -webkit-transition: all .9s;
            -moz-transition: all .9s;
            -ms-transition: all .9s;
            -o-transition: all .9s;
        }
    }

    //.nav-collapse {
    //    margin-top: -11px;
    //}
    //
    //.logo-white {
    //    display: block;
    //}
    //
    //.logo-dark {
    //    display: none;
    //}
    //
    //.navbar-toggle .icon-bar {
    //    background-color: #00587b;
    //}
    //
    //.nav-menu-main {
    //    margin-top: 30px;
    //}
    //
    //.div-nav-menu-top {
    //    top: -6px;
    //    width: 100%;
    //    right: 188px;
    //    display: block;
    //    position: absolute;
    //
    //    .nav-menu-top {
    //        >li {
    //            > a {
    //                font-size: 14px;
    //                font-weight: 500;
    //            }
    //        }
    //    }
    //}
    //
    //.navbar-nav {
    //    display: flex;
    //    display: -moz-box;
    //    display: -webkit-box;
    //}
    //.nav {
    //    > li {
    //        > a {
    //            font-size: 17px;
    //            font-weight: 700;
    //            padding: 4px 8px;
    //            margin-top: -18px;
    //            //padding: 0 14px;
    //
    //            //@media (min-width: 768px) {
    //                &:not(.btn), &:hover:not(.btn)  {
    //                    color: #00587b;
    //                }
    //            //}
    //
    //            @media (max-width: 767px) {
    //                padding-left: 0;
    //            }
    //
    //            &.btn-menu-action {
    //                border-radius: 0;
    //                padding: 4px 14px;
    //                margin-left: -0.5px;
    //            }
    //        }
    //    }
    //}
    //
    //.nav-menu-right {
    //    margin-top: 8px;
    //
    //    .btn-group-styled {
    //        margin-top: -4px;
    //    }
    //}
    //
    //&.no-cover {
    //    position: relative;
    //    margin-bottom: 70px;
    //}
    //

    .navbar-toggler {
        right: 0;
        top: -44px;
        z-index: 1;
        position: absolute;
        vertical-align: text-bottom;
    }
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(0, 98, 167, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
    }
    //
    //@media (max-width: 767px) {
    //    .navbar-collapse {
    //        left: 0;
    //        top: 56px;
    //        width: 100%;
    //        padding: 0 14px;
    //        position: absolute !important;
    //    }
    //}
}

.cover {
    //overflow: hidden;
    position: relative;

    .cover-body {
        //padding-top: 105px;
        min-height: 139px;
        text-shadow: 1px 0 8px rgba(0,0,0,.3);
        background-image: url('/assets/images/cover.jpg');

        .cover-content {
            top: 50%;
            width: 100%;
            color: #ffffff;
            position: absolute;
            margin-left: -15px;
            margin-right: -15px;
            padding-right: 15px;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);

            .cover-title {
                font-size: 40px;
            }

            .cover-subtitle {
                font-size: 31px;
                line-height: 48px;
            }

            @media (max-width: 991px) {
                .cover-title {
                    font-size: 29px;
                    margin-bottom: 0;
                    line-height: 33px;
                }

                .cover-subtitle {
                    font-size: 24px;
                    line-height: 35px;
                }
            }
        }
    }

    .cover-footer {
        //bottom: 0;
        color: #444444;
        background: #F5F5F5;
        position: relative;

        .cover-footer-title {
            font-size: 23px;
            font-weight: 300;
            margin-bottom: 0;
            padding: 26px 20px 21px;
        }

        &.cover-footer-title {
            padding-left: 0;

            &:after {
                right: 274px;
                transform: translate(50%, 0);
            }
        }
    }

    &:after {
        @media (max-width: 1199px) {
            top: auto;
            bottom: 0;
            width: 82px;
            height: 246px;
        }
    }

    @media (max-width: 1199px) {
        .cover-footer {
            z-index: 100;

            &:after {
                right: 91px;
            }
        }
    }
}

.title-starter {
    font-size: 18px;
    font-weight: bold;
    color: #59B948;
}

.title-focus {
    font-size: 28px;
    font-weight: bold;
    line-height: 34px;
    margin-top: 10px;
    margin-bottom: 10px;
}

body:not(.manager) {
    .text-focus {
        font-size: 16px;
        line-height: 27px;
    }

    .card {
        h3 {
            font-size: 16px;
            margin-bottom: 0;
            font-weight: bold;
            line-height: 20px;
            padding-bottom: 3px;
        }
    }

    .content {
        color: $main;

        h3 {
            font-size: 16px;
            margin-bottom: 0;
            font-weight: bold;
            line-height: 20px;
            padding-bottom: 3px;
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
        }

        a {
            color: #039be5;
        }

        .card-body {
            .fluid-width-video-wrapper {
                margin-left: -20px;
                width: calc(100% + 40px);

                margin-bottom: 30px;
            }

            > p > .fluid-width-video-wrapper {
                overflow: hidden;
                margin-top: -20px;
                border-radius: 3px 3px 0 0;
            }
        }
    }
}

.footer-cover-slogan {
    background: linear-gradient(to right, #8ec63f 44% , #5cbb47 7%);

    p {
        margin-top: -45px;
        margin-bottom: 15px;
    }

    .btn {
        //margin-top: -23px;
        white-space: normal;
    }


    @media (max-width: 767px) {
        p {
            margin-top: -30px;
            margin-bottom: 5px;
        }
    }

    @media (max-width: 400px) {
        p {
            margin-top: -4px;
            margin-bottom: 5px;
        }
    }
}

.footer-slogan {
    margin-bottom: 15px;

    img {
        max-width: 222px;
        padding-top: 20px;
    }

    #footer-slogan {
        padding-top: 43px;
    }

    @media (max-width: 576px) {
        [class*="col-"] {
            text-align: center !important;

            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }

            #footer-slogan {
                padding-top: 18px;
            }
        }
    }
}

.footer {
    color: #ffffff;
    //padding-top: 46px;
    background-color: #046c9f;

    .footer-main {
        padding: 44px 15px 20px;
        background: url(/assets/images/footer.jpg) no-repeat;
        background-color: #046c9f;
        background-size: 100%;

        @media (max-width: 767px) {
            background-size: cover;
            background-position: right;
        }
    }

    a {
        color: #FFFFFF;
    }

    .list-title {
        font-weight: bold;
        margin-bottom: 16px;
    }

    .footer-first-text-col {
        padding-right: 1.8rem !important;
    }
    p {
        font-size: 14px;
        line-height: 24px;
    }

    ul {
        //padding-left: 0;
        margin-bottom: 10px;

        li {
            font-size: 14px;
            margin-top: 6px;
            line-height: 24px;

            &:before {
                margin-left: -40px;
            }
        }
    }

    .footer-bar {
        color: #8c8c8d;
        padding: 8px 0;
        font-size: 14px;
        font-weight: 500;
        background-color: #ebebeb;

        a {
            color: #8c8c8d;
            font-size: 14px;
            font-weight: 500;
        }
    }

    .marks {
        .mark-image {
            margin-bottom: 15px;
        }
    }
}

body.homepage {
    .footer {
        margin-top: 0;
    }
}


.searchpage {
    .panel-heading {
        border-radius: 0;
        //padding: 10px 15px;
        background-color: #0062A7;
        border-bottom: 2px solid white;

        .panel-title {
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 0;
            font-size: 16px;

            a {
                color: #fff;
                display: block;
                font-weight: 700;
                padding: 10px 15px;
                text-decoration: none !important;

                &.collapsed {
                    i {
                        &:before {
                            content: "\f054";
                        }
                    }
                }
            }
        }
    }

    [data-toggle="collapse"] {
        font-size: 16px;
    }

    button[type="submit"] {
        font-size: 17px;
        font-weight: 700;
    }

    label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2px;
    }

    .card-body {
        padding-left: 13px;
        padding-right: 13px;

        .form-group .checkbox {
            margin-left: 13px;
        }
    }

    .checkbox {
        input[type=checkbox] {
            display: none;
        }

        label {
            display: block;
        }
    }

     > .collapse:not(.show) {
        display: block;
        height: auto !important;
    }

    @media (max-width: 991px) {
        > .collapse:not(.show) {
            display: none;
        }
    }
}











//
//.panel-author {
//    .author-image {
//        width: 80px;
//        height: 80px;
//    }
//
//    @media (max-width: 766px) {
//        .author-image {
//            width: 80px;
//            min-height: 80px;
//        }
//    }
//}

//.page-cover {
//    position: relative;
//    background: center/cover no-repeat;
//
//    > div {
//        z-index: 10;
//        position: relative;
//
//        h1, h2, h3, h4, a {
//            color: #fff;
//        }
//    }
//
//    &:after {
//        top: 0;
//        left: 0;
//        right: 0;
//        bottom: 0;
//        z-index: 5;
//        content: '';
//        width: 100%;
//        height: 100%;
//        overflow: hidden;
//        position: absolute;
//        background: rgba(0, 0, 0, .10);
//    }
//}
//
//.logo-white {
//    display: none;
//}

//.containter-search-bar {
//    h1 {
//        font-size: 31px;
//        margin-top: 57px;
//        margin-bottom: 35px;
//    }
//
//    .search-bar {
//        > div {
//            margin-bottom: 60px;
//
//            text-transform: uppercase;
//
//            .form-control {
//                text-transform: uppercase;
//                &:focus {
//                    border: 1px solid #dadbdc;
//                    border-right: 0;
//
//                }
//
//                &::-webkit-input-placeholder,
//                &:-moz-placeholder,
//                &::-moz-placeholder,
//                &:-ms-input-placeholder{ /* WebKit, Blink, Edge */
//                    text-transform: uppercase;
//                }
//            }
//        }
//    }
//}

//.searchpage {
//    .panel.panel-card h4.panel-title {
//        font-weight: normal;
//    }
//
//    .checkbox, .checksmall {
//        color: @grey;
//        font-size: 14px;
//
//        label {
//            color: @grey;
//            font-size: 14px;
//        }
//    }
//
//    .checkleft {
//        float: left;
//        margin-right: 5px;
//    }
//}

.home-category {
    .title {
        font-size: 25px;
    }

    .category-title {
        font-size: 15px;
    }

    .btn {
        font-size: 15px;
    }
}

.product-category {
    .bg-image {
        height: 147px;
        overflow: hidden;
        border: 2px solid #e9e9e9;

        img {
            opacity: 0;
        }
    }

    &:hover {
        .bg-image {
            outline: 2px solid #e9e9e9;
            border-width: 2px;
        }

        .title {
            text-decoration: underline !important;
        }
    }

    .title {
        padding: 0;
        color: #00587b;
        font-size: 14px;
        margin-top: 6px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: 0.01em;
    }

    .more-info {
        color: #999999;
        font-size: 14px;
        padding-top: 5px;
        font-weight: bold;
        padding-left: 15px;
        letter-spacing: 0.02em;
    }

    @media (max-width: 991px) {
        .bg-image {
            height: 86px;
        }
    }

    &.home-category {
        .bg-image {
            height: 248px;
        }

        .title {
            color: #00587b;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 0;
            padding-top: 10px;
            padding-left: 15px;
            letter-spacing: 0.01em;
        }
    }
}
