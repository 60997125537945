@import "vendor/bootstrap.scss";
@import "custom/style.scss";

@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700');

@import "xcelsior/variables.scss";
@import "xcelsior/fix-plugins.scss";
@import "xcelsior/misc.scss";
@import "xcelsior/mediapicker.scss";
@import "xcelsior/pages.scss";
@import "xcelsior/buttons.scss";
@import "xcelsior/manager.scss";
@import "xcelsior/pagebuilder.scss";
@import "xcelsior/card.scss";
@import "xcelsior/carousel.scss";
@import "xcelsior/custom.scss";

@import "xcelsior/cookiebar.scss";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  //color: @text;
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.01em;
  font-family: "Raleway", sans-serif;

  &:not(.manager) {
    padding-bottom: 0;
    background-color: #f8f8f8;
    //background-color: #FFFFFF;

    .card-box {
      border-radius: 0;
      box-shadow: 0 0 11px 0 rgba(0,0,0,.06), 0 1px 0 0 rgba(0,0,0,.02);
    }
  }

  p {
    font-weight: 500;
  }

  a {
    color: #00587b;
  }

  a:not(.btn):hover {
    text-decoration: none !important;
  }

  h2 {
    font-size: 21px;
    line-height: 1.4;
  }

  h5 {
    font-size: 1.90rem;
    line-height: 125%;
    margin: 1rem 0 0.656rem 0;
  }

  h6 {
    font-size: 1.19rem;
    line-height: 125%;
    margin: 0.5rem 0 0.4rem 0;
    letter-spacing: 0.01em;
  }


  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: #00587b;
  }

  .fs-18 {
    font-size: 18px;
  }

  .fs-24 {
    font-size: 24px;
  }

  .fs-28 {
    font-size: 28px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .row > * > .card {
    display: block;
  }

  #wrapper {
    .navbar-brand {
      display: block;
    }
  }

  .nav-searchbar .searchbox-input {
    line-height: 0.4;
  }
}
